import React from 'react'
// import alert4 from '../../assets/img/management/alert4.png'



const BannerCases = ({img}) => {
  return (
    <>
      <div className="cases__paralax my-5" style={{backgroundImage: `url(${img})`}}></div>
    </>
  )
}

export default BannerCases
