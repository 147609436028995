const Fraud = [
    {
        overview: "There is no shortage of ingenuity when it comes to financial crime and fraud. We see this daily as fraudsters continuously adapt their approach and methods in an effort to stay one step ahead of the law and the latest detection technologies. The velocity, complexity, and expanded opportunity for financial crime require financial services organizations to rethink and retool their approach to fraud management. Real - time and enterprise - wide define the gold standard toward which today's firms aspire and this can be accomplished with VALOORES in' Fraud Management",
        benefits: [
            "Rapidly detect and prevent complex fraud schemes to minimize losses, maximize customer trust and reduce reputational risk",
            "Intelligently focus investigators on the most urgent and actionable fraud alerts to prevent and reduce losses from high exposure activities",
            "Combat fraud with a comprehensive set of prebuilt scenarios from an industry proven financial crime library used in over 70 countries"
        ],
        features: [
            'Single, integrated fraud management platform',
            'Event identification and correlation across the enterprise',
            'Intelligent alert correlation from point fraud solutions',
            'Real-time monitoring and interdiction capabilities',
            'Advanced risk scoring for prioritization',
            'Prebuilt scenarios and detection techniques',
            'Alert/data trending reports and interactive analyses',
            'Comprehensive case management including workflow, documentation, loss and recovery data, and audit trail',
        ],
        transaction : [
            {
                title: "Transaction Monitoring",
                desc: "With Big Data technologies, much more sophisticated capabilities will be available to model development teams to construct Fraud transaction monitoring scenarios. Using big data technology for transaction monitoring provides several advantages over traditional methods.",
                list:
                [
                    "Creating more sophisticated models without limitations of legacy technology- complete freedom to develop complex detection models without having to worry about your transaction monitoring tool's ability to handle the code.",
                    "Leveraging additional transaction data and meta data- No longer will you restricted to using only a subset of fields from your transaction source data. Big Data technologies will allow you to leverage structured, semi-structured, and unstructured data in the development of your models. This becomes particularly helpful when processing wire transfer data which may follow inconsistent formats and contain semi-structured data.",
                    "Applying Machine Learning techniques to self-tune models over time- Loop investigation disposition data back into the models to provide supervised training and self-tuning of detection models. Leverage unsupervised machine learning techniques which will help discover new attributes which may be strongly correlated to Fraud risk.",
                    "Using behavioral modeling analytics to support anomaly based alert generation- Create models which alert based on anomalous behavior. Create natural clusters of peer groups and detect activity which deviates from expected behavior.",
                    "Testing and iterating on new models leveraging the power of distributed computing- Run and test models across the data with much reduced processing time.",
                ]
            }
        ],
        real : [
            {
                title: "Real-time scoring",
                desc: 
                (
                    <>
                        We believe at Valoores that managing Risk & Compliance is the supreme discipline in banking. Assessing the Financial Crime risk is another dimension, as it is a “consequential” risk. Financial Crime risks may only become evident when a customer begins transacting through the account.
                            <br /><br />
                        Transactions monitoring is a fundamental component of the Risk-Based Approach (RBA).
                            <br /><br />
                        Financial Crime risks reflect a Financial Institution's external and internal environments. Financial Crime Risk Assessment best practices include quantitative and qualitative methodologies. Many national and international legislations put the RBA at the center of Fraud Management.
                            <br /><br />
                        Fraud risks are generally understood to include forgery & identity theft, alongside other serious offences and types of Financial Crime.
                            <br /><br />
                        Valoores pulls in data, such as the Customer's Profile to calibrate the risk scoring.
                            <br /><br />
                        The Valoores Dynamic RBA is in line with the regulatory guidelines on sound risk assessment and RBA, alongside best practices accumulated from its proliferating implementations around the globe.
                            <br /><br />
                        It is of outmost value that Valoores RBA is seamlessly integrated within its KYC, CDD, & EDD, all the way through its in'FC-Fraud's embedded watch list & PEP screening, Profiling, Monitoring, and Suspicious Activity Prevention life cycle. Risk categories though, are mapped to a Risk Matrix, based on a Dynamic and Flexible definition engine, pulling together a myriad of risk attributes (factors). The Valoores RBA is equipped with a progressive Configuration Panel, an Exception Management framework, a Bulk Upload capacity to sustain the bank's best practices, alongside an Analytics based Customer Risk Calculation Engine.
                    </>
                ),
                list:
                ['']
            }
        ],
        management : [
            {
                title: "Case Management",
                desc: "The next generation of case management solutions, based on big data technologies, will unlock automation and efficiency in Fraud investigations. These next-generation systems will take advantage of Big Data and other contemporary technologies to:",
                list:
                [
                    "Link/Network Analysis- Another value proposition of Big Data Case Management tools is the capability of leveraging Big Data graph capabilities for Link/Network analysis. Many tools exist in the Big Data ecosystem which can enable mapping of nth degree network connections and providing an interface to the analysts which allows them to shuffle through these network graphs to find risky connections.",
                    "Aggregate investigation data- Rather than logging into a dozen different applications outside of the case management tool hunting down the transaction, alert, and customer data that an analyst needs for an investigation, next generation systems will present all of the relevant data for a case in a single interface for the analyst. This aggregation is possible due to the Big Data environment's strength in handling Variety in data formats. Using traditional relational databases, it can be very expensive and difficult to aggregate data because it requires transforming the data into a singular canonical schema. This can be near impossible when the source data systems are drastically different such as in the case of Wire Transfer transaction data vs. checking account transactions. This problem is more easily address in the Big Data environment as you are not required to execute these transformations ahead of time.",
                    "Entity Resolution / Single View of the Customer- One of the most persistent challenges faced by Fraud programs is drilling down to a single view of their customers. The complexity of institutions who have retail, wealth management, capital market, and other siloed lines of business make it very difficult for Fraud programs to understand the comprehensive relationship of a single customer with the institution. This is of course even more difficult for institutions with a global footprint. Beyond just bringing the data together, banks must have a way of merging customers together who are probably the same individual. Can you imagine how many “John Smiths” there may be at a financial institution with tens/hundreds of millions of customers? The Big Data environment provides an ecosystem and analytic technologies which allow data scientists to construct more advanced matching algorithms. Using a larger set of matching attributes allows us to snap together to similar entities with greater confidence. By incorporating these matching algorithms into a case management tool, institutions can merge probable matches and provide an interface in which analysts can review the probably matches and affirm or deny the match.",
                ]
            }
        ],
        single : [
            {
                title: "Single Fraud Platform",
                desc: 
                (
                    <>  
                        In order to detect, interdict, and prevent, many institutions draw a distinction between fraud and financial crime. Boundaries are blurring, especially since the increase in cyber threats, which show the extent to which criminal activities have become more sophisticated and interrelated. What's more, the distinction is not based on law, and regulators sometimes view it as the result of organizational silos. However, financial crime has generally been associated with money laundering and a few other criminal transgressions, including bribery and tax evasion, involving the use of financial services in support of criminal enterprises. It is most often treated as a compliance issue, as when financial institutions avoid fines with anti-money laundering activities. Fraud, on the other hand, generally includes a host of crimes, such as forgery, credit scams, and insider threats, involving deception of financial employees or services to commit theft. Financial institutions have generally approached fraud as a loss problem, lately applying advanced analytics for detection and even real-time interdiction. As the distinction between these three categories of crime have become less possible, financial institutions need to use a single integrated fraud prevention tool to protect assets against all of them.
                        <br/><br/>
                        The combination of customer information that comes from the closer collaboration of the teams addressing financial crime, fraud, and cybersecurity will heighten the power of the institution's analytic and detection capabilities using the integrated fraud management platform Valoores in'Fraud. For instance, real-time risk scoring and transaction monitoring to detect transaction fraud can accordingly be used to greater effect.
                    </>
                ),
                list:[''],
            }
        ],
        case : [
            {
                title: "Case Studies",
                desc: "The great potential of advanced analytics to improve fraud management is best illustrated through cases.",
                list:
                [
                    (
                        <>
                            Case Study 1: <br/>
                            While shifting to a model without branches, a large retail bank discovered that half its account applications were being rejected, and many more prospects were pushed out of the application process. In fact, only 16 percent of prospects got as far as opening new accounts. Seeking to solve the problem, the bank invested in an integrated fraud management platform that draws on data from a number of external sources to perform live customer-risk assessments. These assessments dynamically trigger questions that customers answer as part of their online application. In its first three months of use, the new system increased acceptances by 60 percent and reduced manual verification by 10 percent, while incurring no increase in fraud losses.
                        </>
                    ),
                    (
                        <>
                            Case Study 2:<br/>
                            In another example, a leading bank from the UK was able to recover 95 percent of estimated value loss from fraud after introducing machine-learning platforms with the support of an advanced analytics specialized vendor. Having partnered with a top payments-infrastructure provider to access all payments associated with the bank's customers, the analytics specialized vendor combined this data with data on fraudulent transactions previously identified by the bank, and analyzed the results using special algorithms. This allowed it to create a fraud-risk score for each transaction. Fraud cases identified by the model represented 86 percent of incidences and 95 percent of fraud-related losses. The bank now has a live tool to identify and prioritize cases for further investigation.
                        </>
                    ),
                ],
            }
        ],
        
        experience : [
            {
                title: "Customer Experience",
                desc: "In a world where customers contact bank staff less frequently but rather interact almost entirely through digital channels, “digital trust” has fast become a significant differentiator of customer experience. Banks that offer a seamless, secure, and speedy digital interface will see a positive impact on revenue, while those that don't will erode value and potentially lose business. Modern banking requires faster risk decisions (such as real-time payments) so banks must find the right balance between managing fraud and handling authorized transactions instantly. The growing cost of financial crime and fraud risk has also overshot expectations, pushed up by several drivers. As banks focus closely on reducing liabilities and efficiency costs, losses in areas such as customer experience, revenue, brand image, and even regulatory compliance are being missed. Many surveys have affirmed that banks are highly regarded by their customers for performing well on fraud.",
                list:[''],
            }
        ]
    }
]
export default Fraud