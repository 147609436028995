import React from 'react'
import Axios from '../../Components/indexTrial/database/banking_axios';
import ReactHtmlParser from 'react-html-parser';

const Choice = () => {
    const fetchedSections = Axios({ path: '/banking_sections' });
  return (
    <>
     {fetchedSections.map((slide) => (
            <>
            {slide.type === "choice" &&
                 <div className={slide.class}>
                    <div className="row">
                        <div className={slide.text_container}>
                            {ReactHtmlParser(slide.title)}
                            {ReactHtmlParser(slide.description)}                       
                        </div>
                        <div className={slide.img_container}>
                            <img src={require(`../../img/${slide.image_path.split('/').pop()}`)} alt="" style={{width: '100%'}} />
                        </div>
                    </div>
                </div>
            }
            </>
        ))}
    </>
  )
}

export default Choice