import React from 'react'
import PressReleaseImg from '../../img/PressRelease.png'
import './PressRelease.css'

const PressRelease = () => {
  return (
    <>
     <div className='position-relative pressRelease_container d-flex flex-row align-items-end pb-5 justify-content-center' style={{height: '650px', backgroundImage: `url(${PressReleaseImg})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
        <div className="pressRelease_overlay"></div>
        <div className='pressRelease_content text-center px-5'>
          <h1 className='text-white fw-bold '>Pioneering Financial Integrity:<br /> VALOORES' Role in Strengthening Risk,<br/> and Compliance across the Middle East.</h1>
        </div>
     </div>
     <div className="d-flex align-items-center justify-content-center my-5">
     
        <h1 className='text-center' style={{fontSize: '3.5rem'}}>Pioneering Financial Integrity:<br/> VALOORES' Role in Strengthening Risk,<br/> and Compliance across the Middle East</h1>
     </div>
    {/* <div className='position-absolute' style={{top: '75%', right: '5%'}}>
        <h5>View in <span className='text-decoration-underline text-primary' style={{cursor: 'pointer'}}>Arabic</span></h5>
    </div> */}
     <div className='container pressRelease_container-cotent'>
        <div>
            <h5 className='fw-normal'>
                <span className='fw-bold'>Sharm El-Sheikh, Egypt - October 11, 2024</span> - VALOORES, a leading global provider of financial and compliance integrity solutions, proudly announces its participation in the Annual Forum for Risk and Compliance Officers in Egypt. The Forum held under the auspices of His Excellency, the Governor of the Central Bank of Egypt, marks a pivotal moment in the region's fight against financial crime.
                With over 35 years of experience serving more than 30 countries, VALOORES is uniquely positioned to empower Central  Banks, Financial Intelligence Units, retail and commercial banks, investment banks, insurance companies, and other financial institutions in navigating risk and compliance solutions.
            </h5>
        </div>
        <div className='my-5'>
            <h3 className='mb-3'>Addressing the Evolving Challenges of Financial Crime</h3>
            <h5 className='fw-normal'>
            The forum, organized and led  by the Union of Arab Banks, focused on preparing the soil for upcoming guidance and requirements  from global standard setters  such as the FATF, The US Federal Reserve, Basel Committee, etc.. Key topics treated included mitigating risks associated with anti-money laundering (AML), combating the financing of terrorism (CFT), and addressing the growing concerns around  cryptocurrency activities.
            </h5>
            <h5 className='fw-normal my-3'>
                VALOORES' V-FDS brand offers a comprehensive suite of solutions designed to meet compliance  challenges head-on. By leveraging cutting-edge technology and deep regulatory expertise, V-FDS enables financial institutions to:
                <ul style={{listStyle: 'outside'}} className='mt-3'>
                    <li className='mb-2 line-2' style={{lineHeight: '2rem'}}><span className='fw-bold'>Enhance due diligence practices:</span> Streamline customer onboarding and ongoing monitoring processes for effective identification and mitigation of risks leveraging technology.</li>
                    <li className='mb-2 line-2' style={{lineHeight: '2rem'}}><span className='fw-bold'>Strengthen transaction monitoring and fraud prevention:</span> Optimize suspicious activity detection in real-time to  proactively prevent financial crime.
                    </li>
                    <li className='mb-2 line-2' style={{lineHeight: '2rem'}}><span className='fw-bold'>Solidify risk management and governance:</span>  Implement robust risk assessment frameworks.</li>
                    <li><span className='fw-bold'>Improve regulatory reporting:</span>  Automate reporting processes and ensure adherence to evolving regulatory requirements.
                    </li>
                </ul>

            </h5>
        </div>
        <div className='my-5'>
            <h3 className='mb-3'>An Invitation for Regional Collaboration</h3>
            <h5 className='fw-normal my-4'>
                VALOORES calls upon all stakeholders, from  regulators, to bankers, and industry experts, to embrace technology  in combating financial crime. By partnering with VALOORES, financial institutions can leverage the company's advanced solutions and expertise to stay ahead of the curve and ensure the integrity of the financial system.
            </h5>
            <h5 className='fw-normal my-4'>
                <span className='fw-bold'>Nehmeh Taouk, CEO of VALOORES,</span> expressed his vision for the future, saying: <span className='fst-italic'>"At VALOORES, we have always been committed to driving financial integrity and embracing advanced technology to serve financial institutions globally. Our partnership with UAB opens new horizons for innovation and collaboration, empowering the region to address evolving financial crimes with unmatched precision and resilience."</span>
            </h5>
            <h5 className='fw-normal my-4'>
                <span className='fw-bold'>Dr. Wissam Fattouh, Main Secretary General of UAB,</span> added to the sentiment, stating: <span className='fst-italic'>"This partnership represents the cornerstone for the development of financial security in the Middle East. VALOORES’ advanced solutions in risk, and compliance perfectly align with UAB's mission to foster strong, compliant, and innovative financial institutions across the Arab world. Together, we are paving the way for a future defined by trust, stability, and sustainable growth."</span>
                "We are committed to supporting the Middle East in its fight against financial crimes," said Nehmeh Taouk. "Our V-FDS solution provides the expertise needed to navigate the complexities of the financial ecosystem.”
            </h5>
        </div>
        <div className='my-5'>
            <h3 className='mb-3'>About Union of Arab Banks (UAB)</h3>
            <h5 className='fw-normal my-4'>
                The Union of Arab Banks (UAB) is a non-profit, pan-Arab organization established in 1974 to foster cooperation and development within the Arab banking and financial sector. UAB plays a crucial role in promoting regional economic integration, supporting the growth of member institutions, and advocating for the interests of the Arab banking community on a global stage. Through training programs, conferences, and research initiatives, UAB facilitates knowledge sharing and best practice adoption, contributing to the advancement and stability of the Arab financial system. UAB actively collaborates with international organizations and regulatory bodies to ensure alignment with global standards and address emerging challenges in the financial landscape.
            </h5>
        </div>
        <div className='my-5'>
            <h3 className='mb-3'>About VALOORES Financial and Digital Services (V-FDS)</h3>
            <h5 className='fw-normal my-4'>
                VALOORES Financial and Digital Services (V-FDS) is a specialized division of VALOORES, dedicated to providing cutting-edge compliance solutions. V-FDS leverages advanced technologies, including artificial intelligence, analytics and  big data, to empower financial institutions in mitigating risks associated with money laundering, terrorist financing and fraud. The V-FDS  suite encompasses KYC, screening, risk assessment, transaction monitoring and regulatory reporting systems, enabling financial institutions to navigate the evolving regulatory landscape with confidence. With a focus on innovation and customer-centricity, V-FDS  unlocks operational efficiency and strengthens the integrity of financial systems.
            </h5>
        </div>
     </div>
    </>
  )
}

export default PressRelease