import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import bg_img from '../img/img-01.jpg';
import logoLogin from '../img/full_logoC.png';

const RegisterPage = () => {
  const [userValue, setUserValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [passValue, setPassValue] = useState('');
  const [CpassValue, setCPassValue] = useState('');
  const [fnameValue, setfnameValue] = useState('');
  const [companyValue, setcompanyValue] = useState('');
  const [isRegisterError, setIsRegisterError] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (userValue !== '' || emailValue !== '' || passValue !== '' || CpassValue !== '' || fnameValue !== '' || companyValue !== '') {
      setIsRegisterError(true);
    }
  };

  return (
    <div className="auth" style={{backgroundImage: `url(${bg_img})`}}>
      <form className="reg_form d-flex align-items-center" onSubmit={handleSubmit}>
      <img src={logoLogin} alt="" className='w-75 d-flex' style={{alignSelf: 'top'}}/>
        <input
          className='form-control'
          required
          type="text"
          placeholder="UserName"
          name="username"
          value={userValue}
          onChange={(event) => setUserValue(event.target.value)}
        />
        <input
          className='form-control'
          required
          type="email"
          placeholder="E-mail"
          name="email"
          value={emailValue}
          onChange={(event) => setEmailValue(event.target.value)}
        />
        <input          
          className='form-control'
          required
          type="password"
          placeholder="Password"
          name="password"
          value={passValue}
          onChange={(event) => setPassValue(event.target.value)}
        />
        <input          
          className='form-control'
          required
          type="password"
          placeholder="Confirm Password"
          name="passwordConfirmation"
          value={CpassValue}
          onChange={(event) => setCPassValue(event.target.value)}
        />
        <input          
          className='form-control'
          required
          type="text"
          placeholder="Full Name"
          name="fullname"
          value={fnameValue}
          onChange={(event) => setfnameValue(event.target.value)}
        />
        <input          
          className='form-control'
          required
          type="text"
          placeholder="Company"
          name="company"
          value={companyValue}
          onChange={(event) => setcompanyValue(event.target.value)}
        />
        <button className='btn btn-secondary w-100' type="submit">Register</button>
        {isRegisterError && <span className="error_msg">Please Fill All Fields</span>}
        <span>
          Already have an account?
          <Link target="_blank"  to="/admin/Login"> Login</Link>
        </span>
      </form>
    </div>
  );
};

export default RegisterPage;