const AmlIntro = [
    {
      title: 'Communications:',
      subTitle: [
        {
          title: [
            'Too many companies wait until they are faced with regulatory enforcement actions before updating their AML communication and systems.',
            'Firms need to enhance their communication with strong messages from leadership and a consistent “tone at the top” to help cascade risk culture across the organization.',
            // {
            //   title: '',
            //   subTitle: '',
            // },
          ],
        },
      ],
    },  
    {
      title: 'Technology:',
      subTitle: [
        {
          title: [
            'Financial institutions should consider AML and sanctions solutions and screening software that can support regulatory requirements while minimizing time, resources and operational risk.',
            {
              title: 'The platforms in scope should include:',
              subTitle: [
                'Visual analytics tools or real-time dashboards for identifying patterns, anomalies and trends.',
                'Data warehouse and data feeds to access the right data. An advanced screening solution using efficient name matching algorithms to monitor and detect alerts.',
                'Case management to handle robust workflow and generate reports.',
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Data and monitoring:',  
      conclusion: "VALOORES Financial Crime and Compliance suite of applications helps financial institutions tackle today's tactical risk and compliance problems while future-proofing compliance spent across regulatory mandates.",
      subTitle: [
        {
          title: [
            'Improve the effectiveness of AML monitoring systems by installing the periodic review of AML source data',
            'Data quality and data integrity should be focused on data sourcing analysis and data quality analysis, assessing completeness, conformity, consistency, accuracy and duplication.',
            'Know Your Customer (KYC) and sanctions screening information should be integrated to capture the right information up front and should be included in sanctions data feeds.',
            // {
            //   title: '',
            //   subTitle: '',
            // },
          ],
        },
      ],
    },
  ];
  
  export default AmlIntro;
  