import React from 'react'
import { useLocation } from 'react-router-dom';
import inViaArray from '../AnalyticsPages/content/InVia'

const AnalyticsDisplay = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const receivedVariable = searchParams.get('content');
    let BannerImg, BannerDesc, FeatureArray, cardTitle, cardSection;

    switch (receivedVariable) {
        case 'inVia':
            BannerDesc = 'hiiiii'  ;
            FeatureArray = {inViaArray}
            break;
    }

    console.log(BannerDesc);

  return (
    <div style={{height: '25vh'}}>
        {FeatureArray.map((feat, index) => (
            <div key={index}>
                <h2>{feat.title}</h2>
            </div>
        ))}
    </div>
  )
}

export default AnalyticsDisplay