import React from 'react'
import Banner from '../Banner'
import ClientBanner from '../../img/client_banner.jpg'
import ClientArr from './ClientArr'

const ClientPage = () => {
  return (
    <>
        <Banner
            image = {ClientBanner}
            pos = '20%'
            title = 'Clients List'
            desc = 'Clients list'
        />
        <div className="container my-5">
            <div className="row">
                {ClientArr.map((client, index) => (
                    <div className="col-md-4" key={index}>
                        <div className="card">
                            <div className="card-header">
                                <img src={client.img} alt="" className='w-100'/>
                            </div>
                            <div className="card-body">
                                <h1>{client.title}</h1>
                                <h5>{client.desc}</h5>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </>
  )
}

export default ClientPage