import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import Modal from 'react-modal';

// Set the app element for accessibility
Modal.setAppElement('#root');

function Carousel() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [editedFile, setEditedFile] = useState(null);
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [input4, setInput4] = useState('');
  const [input5, setInput5] = useState('');
  const [fetchedData, setFetchedData] = useState([]);
  const [editValue, setEditValue] = useState('');
  const [editValue2, setEditValue2] = useState('');
  const [editValue3, setEditValue3] = useState('');
  const [editValue4, setEditValue4] = useState('');
  const [editValue5, setEditValue5] = useState('');
  const [editValue6, setEditValue6] = useState('');
  const [editValue7, setEditValue7] = useState('');
  const [editId, setEditId] = useState('');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileEdit = (event) => {
    setEditedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const uploadResponse = await axios.post('http://localhost:8800/carousel/upload', formData);
        const imagePath = uploadResponse.data.imagePath;

        const res = await axios.post('http://localhost:8800/carousel/database', {
          input1: input1,
          input2: input2,
          input3: input3,
          input4: input4,
          input5: input5,
          imagePath: imagePath,
        });

        console.log(res.data.input1);
        console.log(res.data.input2);
        console.log(res.data.input3);
        console.log(res.data.input4);
        console.log(res.data.input5);
      } else {
        console.log('Please select a file.');
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:8800/carousel/show');
        setFetchedData(response.data);
        setIsDataFetched(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleClick = (value, value2, value3, value4, value5, value6, value7, id) => {
    console.log(id);
    setEditValue(value);
    setEditValue2(value2);
    setEditValue3(value3);
    setEditValue4(value4);
    setEditValue5(value5);
    setEditValue6(value6);
    setEditValue7(value7);
    setEditId(id);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (delete_id, delete_rel_id, delete_path, delete_orders) => {
    console.log(delete_rel_id);
    console.log(delete_id);
    console.log(delete_path);

    try {
      const response = await axios.delete('http://localhost:8800/carousel/deleted', {
        data: {
          delete_ids: delete_id,
          delete_rel_ids: delete_rel_id,
          delete_paths: delete_path,
          delete_orders: delete_orders,
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setEditValue2(e.target.value);
  };

  const handleInputChange3 = (e) => {
    setEditValue3(e.target.value);
  };

  const handleInputChange4 = (e) => {
    setEditValue4(e.target.value);
  };

  const handleInputChange5 = (e) => {
    setEditValue5(e.target.value);
  };

  const handleInputChange6 = (e) => {
    setEditValue6(e.target.value);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editedFile) {
        const formData = new FormData();
        formData.append('file', editedFile);
        formData.append('original_path', editValue7);
        formData.append('id', editId);

        const uploadEditResponse = await axios.post('http://localhost:8800/carousel/editImage', formData);
        const imagePath = uploadEditResponse.data.imagePath;

        const res = await axios.put('http://localhost:8800/carousel/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
          value3: editValue3,
          value4: editValue4,
          value5: editValue5,
          value6: editValue6,
          imagePath: imagePath,
        });
        console.log('Value edited:', res.data);
      } else {
        const res = await axios.put('http://localhost:8800/carousel/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
          value3: editValue3,
          value4: editValue4,
          value5: editValue5,
          value6: editValue6,
        });
        console.log('Value edited:', res.data);
      }
    } catch (error) {
    }
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };
  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  return (
    <>
      <div className= "d-flex flex-row justify-content-between align-items-center container mb-4">
        <h1>Carousel</h1>
        <button className="btn btn-primary" onClick={handleAddModalOpen}>Add Carousel</button>
      </div>
      {isDataFetched && (
        <div className="tableStyle">
          <table className="table">
            <thead className="sticky-header">
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Description</th>
                <th>Link</th>
                <th>Position</th>
                <th>Class</th>
                <th>Order</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <>
              {fetchedData.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td className="truncate">{item.title}</td>
                  <td className="truncate">{item.description}</td>
                  <td className="truncate">{item.link}</td>
                  <td>{item.position}</td>
                  <td>{item.class}</td>
                  <td>{item.orders}</td>
                  <td>
                    <button className="btn btn-secondary" to="#" onClick={() => handleClick(item.title, item.description, item.link, item.position, item.class, item.orders, item.image_path, item.id)}>
                      Edit
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-danger" type="button" onClick={() => handleDelete(item.id, item.related_id, item.image_path, item.orders)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
              </>
            </tbody>
          </table>
        </div>
      )}
      {/* EDIT CAROUSEL MODAL */}
      <Modal
      isOpen={isEditModalOpen}
      onRequestClose={handleEditModalClose}
      contentLabel="Edit Modal"
      >
          <div className="">
              <div className="">
                  <div className="">
                      <form className='edit_form'>
                          <div>
                              <h5 className="pb-3 px-3">Edit Carousel</h5>
                          </div>

                          <div className=" px-4">
                              <div className="row mb-2">
                                  <div className="col-md-6">
                                      <label htmlFor="CarId" >Carousel ID</label>
                                      <input
                                          id="CarId"
                                          type="text"
                                          disabled
                                          readOnly
                                          value={editId}
                                          placeholder="Carousel Edit ID"
                                          className='form-control my-3'
                                      />
                                  </div>
                                  <div className="col-md-6">
                                      <label htmlFor="CarTitle">Carousel Title</label>
                                      <input
                                          id="CarTitle"
                                          type="text"
                                          value={editValue}
                                          onChange={handleInputChange}
                                          placeholder="Carousel Edit Title"
                                          className='form-control my-3'
                                      />
                                  </div>
                              </div>

                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="CarDesc">Carousel Description</label>
                                          <input
                                              id="CarDesc"
                                              type="text"
                                              value={editValue2}
                                              onChange={handleInputChange2}
                                              placeholder="Carousel Edit Description"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="CarLink">Carousel Link</label>
                                          <input
                                              id="CarLink"
                                              type="text"
                                              value={editValue3}
                                              onChange={handleInputChange3}
                                              placeholder="carousel Edit Link"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="CarPos">Carousel Position</label>
                                          <input
                                              id="CarPos"
                                              type="text"
                                              value={editValue4}
                                              onChange={handleInputChange4}
                                              placeholder="Carousel Edit Position"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="CarClass">Carousel Class</label>
                                          <input
                                              id="CarClass"
                                              type="text"
                                              value={editValue5}
                                              onChange={handleInputChange5}
                                              placeholder="Carousel Edit Class"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="CarOrder">Carousel Order</label>
                                          <input
                                              id="CarOrder"
                                              type="text"
                                              value={editValue6}
                                              onChange={handleInputChange6}
                                              placeholder="Carousel Edit Order"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="CarImg">Image File</label>
                                          <input id="CarImg" type="file" onChange={handleFileEdit} 
                                          className='form-control my-3'/>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="px-4 d-flex justify-content-end gap-3">
                              <button className='btn btn-secondary' onClick={handleEditSubmit}>Submit Edit</button>
                              <button className='btn btn-danger' onClick={() => setIsEditModalOpen(false)}>Cancel</button>
                          </div> 
                      </form>
                  </div>
              </div>
          </div>
      </Modal>
      {/* ADD SECTION MODAL */}
      <Modal
        isOpen={isAddModalOpen}
        onRequestClose={handleAddModalClose}
        contentLabel="Add Modal"
      >
        
        <div className="">
          <div className="">
            <div className="">
              <form className='add_form'>
                <div>
                  <h5 className="pb-3 px-3">Add carousel</h5>
                </div>

                <div className=" px-4">
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label htmlFor="addCarTitle">Carousel Title</label>
                      <input
                          id="addCarTitle"
                          type="text"
                          value={input1}
                          onChange={(e) => setInput1(e.target.value)}
                          placeholder="Carousel Title"
                          className='form-control my-3'
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="addCarDesc">Carousel Description</label>
                      <input
                        id="addCarDesc"
                        type="text"
                        value={input2}
                        onChange={(e) => setInput2(e.target.value)}
                        placeholder="Carousel Description"
                        className='form-control my-3'
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addCarLink">Carousel Link</label>
                        <input
                              id="addCarLink"
                              type="text"
                              value={input3}
                              onChange={(e) => setInput3(e.target.value)}
                              placeholder="Carousel Link"
                              className='form-control my-3'
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                      <label htmlFor="addCarPos">Carousel Position</label>
                        <input
                              id="addCarPos"
                              type="text"
                              value={input4}
                              onChange={(e) => setInput4(e.target.value)}
                              placeholder="Carousel Position"
                              className='form-control my-3'
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addCarClass">Carousel Class</label>
                        <input
                            id="addCarClass"
                            type="text"
                            value={input5}
                            onChange={(e) => setInput5(e.target.value)}
                            placeholder="Carousel Class"
                            className='form-control my-3'
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addCarImg">Image File</label>
                        <input id="addCarImg" type="file" onChange={handleFileChange} className='form-control my-3'/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 d-flex justify-content-end gap-3">
                  <button className='btn btn-secondary' onClick={handleSubmit}>Submit</button>
                  <button className='btn btn-danger' onClick={() => setIsAddModalOpen(false)}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Carousel;
