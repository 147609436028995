import React from 'react'
import RetailImg from '../../img/ai/retailImg.jpg'
import Fade from 'react-reveal/Fade';

const first_list = [
    {
        year: '1975',
        title: 'Early Life and the Lebanese Civil War',
        desc: 'I was born in Bsharri, Lebanon, in a town famous for Khalil Gibran. Despite being born into a poor family, they were rich in faith and love. In 1975, when I turned 15, the Lebanese Civil War erupted, forcing me and many other young people to leave behind their homes, schools, friends, and dreams to defend their nation.',
        bg_color: '#41516C',
        marginTop: '5rem',
        odd: 'yes',
        afterColor: 'item1'
        
    },
    {
        year: '1982',
        title: 'Leaving for a Better Life',
        desc: "Seven years later, with a temporary pause in the war, I decided to leave Lebanon in an attempt to restore a normal life and take care of my parents and other family members. In 1987, due to the dire economic collapse in Lebanon, I left in search of opportunities to ensure my parents' safety and dignity.",
        bg_color: '#FBCA3E',
        marginTop: '18rem',
        odd: 'no',
        afterColor: 'item2'
    },
    {
        year: '1987',
        title: "Heartfelt Farewell and Mother's Wisdom",
        desc: <>
                As I was preparing to leave, facing the emotional and challenging task of saying goodbye to my mother, she offered wise words in her strong voice:
                <br />
                <span className='text-end' style={{display: 'block', fontSize: '1.3rem'}}>
                ،يا مايمة <br />
                ،إذا منك عارف حالك لوین رایح <br />
                ...ما تنسى من وین جایه
                </span>
                <br/>
                which means: &nbsp;
                <span className='fst-italic'>My son, if you don't know where you are heading, at least
                never forget from where you came...</span>
            </>,
        bg_color: '#E24A68',
        marginTop: '0rem',
        odd: 'yes',
        afterColor: 'item3'
    },
    {
        year: 'And the journey continues...',
        title: 'Lessons from Humble Beginnings',
        desc: 'Later on, I discovered the gift of being born in a poor family. It made me understand, feel and everything in my life, every drop of water, and how much it is sacred to share and give to those who are in need',
        bg_color: '#1B5F8C',
        marginTop: '15rem',
        odd: 'no',
        afterColor: 'item4'
    },
]
const Childhood = () => {
  return (
    <>
        <h1 className='text-center mb-5'>My little and personal story</h1>
        <ul className='timeline_ul' style={{position: 'relative'}}>
            {first_list.map((item ,index) => (
                <div key={index} style={{marginTop: item.marginTop}}>
                    <li className={item.odd === 'yes' ? 'timeline__list odd' : 'timeline__list even w-85'}>
                        <div style={{background: item.bg_color}} className={item.afterColor + ' date'}>{item.year}</div>
                        <div className="title">{item.title}</div>
                        <div className="descr">{item.desc}</div>
                    </li>
                </div>
            ))}
        </ul>
    </>
  )
}

export default Childhood