import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import analyticsSlides from './analyticsSlides';
import YouTube from 'react-youtube';
import ReactModal from 'react-modal';

const VideoPlayer = ({ videoId }) => {
  const opts = {
    height: '700px',
    width: '1200px',
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
      mute: 1,
      rel: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};

const SectionCarousel = ({ setActiveIndex }) => {
  const [activeIndex, setActiveIndexState] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const sliderRef = useRef(null);
  const videoId = '5Je5GN4NSG8';

  useEffect(() => {
    sliderRef.current.slickGoTo(activeIndex);
    setActiveIndex(activeIndex);
  }, [activeIndex, setActiveIndex]);


  const settings = {
    // autoplay: true,
    // autoplaySpeed: 3000,
    focus: false,
    easing: 'linear',
    draggable: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveIndexState(next);
    },
  };

  const makeActive = (index) => {
    setActiveIndexState(index);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className='geo_container mt-5' style={{ position: 'relative' }}>
        <Slider {...settings} ref={sliderRef}>
          {analyticsSlides.map((analytics, index) => (
            <div className='geo_content' style={{ position: 'relative' }} key={index}>
              <div className="img_geo-content row" style={{ backgroundImage: `url(${analytics.image})` }}>
                <div className="overlay_bg"></div>
                <div className="text_geo-content col-md-4" style={{ top: '20%', left: '2rem' }}>
                  <h1 className='w-75'>{analytics.title}<br /></h1>
                  <p>{analytics.description}</p>
                  {analytics.button === 'yes' ? 
                    <Link onClick={openModal} className='w-25 btn btn-primary btn-show'>Watch Demo</Link>
                    : null
                  }
                  <ReactModal
                    className="d-flex align-items-center justify-content-center flex-column-reverse"
                    isOpen={isOpen}
                    onRequestClose={() => closeModal(index)}
                    contentLabel="Example Modal"
                    appElement={document.getElementById('root')}
                  >
                    <VideoPlayer videoId={videoId} />
                    <button onClick={() => closeModal(index)}>X</button>
                </ReactModal>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className='geo_thumb d-flex justify-content-center text-white gap-1 align-items-center'>
          <div className="container flex-row d-flex w-75 align-items-center justify-content-center">
            {analyticsSlides.map((analytics, index) => {
              const icon = analytics.icon; // Remove file extension
              const iconWhite = analytics.icon_white; // Remove file extension
              return (
                <div
                  className={index === activeIndex ? 'geo_thumb-box active' : 'geo_thumb-box'}
                  onClick={() => makeActive(index)}
                  key={index}
                >
                  <div className="overlay_bg"></div>
                  <img alt="icon"
                    style={{ width: '36px', height: '36px' }}
                    src={index === activeIndex ? icon : iconWhite} // Use the updated icon variable
                  />
                  {analytics.header}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionCarousel;
