import React, { useState } from 'react';
import Users from './pages/users';
import Dashboard from './pages/dashboard';
import Sections from './pages/sections';
import Carousel from './pages/carousel';
import Clients from './pages/clients';
import Solutions from './pages/solutions';
import Services from './pages/services';
import Counter from './pages/counter';
import Documents from './pages/documents';
import Videos from './pages/videos';
import BankingCarousel from './pages/banking/carousel';
import BankingSections from './pages/banking/sections';
import BankingSlider from './pages/banking/slider';
import BankingApplication from './pages/banking/application';
import { PiUserListFill } from 'react-icons/pi';
import { FaDoorOpen } from 'react-icons/fa';
import { TbLogout } from 'react-icons/tb';
// import Avatar from '../img/logo.png';
import { Link, useNavigate } from 'react-router-dom';

const MainContent = ({ selected }) => {
  const navigate = useNavigate();
  const useToggle = (initialState) => {
    const [toggleValue, setToggleValue] = useState(initialState);

    const toggler = () => {
      setToggleValue(!toggleValue);
    };
    return [toggleValue, toggler];
  };

  const [toggle, setToggle] = useToggle();

  const handleSignout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('expirationTime');
    navigate('/admin/login');
    // window.location.reload(false);
  };

  const Menu = () => {
    return (
      <>
        <ul style={{borderRadius: "10px"}}>
          <li>
            <FaDoorOpen />
            <Link target="_blank" to="../../">
              Home
            </Link>
          </li>
          <hr />
          <li onClick={handleSignout}>
            <TbLogout />
            Sign Out</li>
        </ul>
      </>
    );
  };

  let content;

  if (selected === 'dashboard') {
    content = <Dashboard />;
  } else if (selected === 'users') {
    content = <Users />;
  }else if (selected === 'carousel') {
    content = <Carousel />;
  }else if (selected === 'sections') {
    content = <Sections />;
  }else if (selected === 'clients') {
    content = <Clients />;
  }else if (selected === 'counter') {
    content = <Counter />;
  }else if (selected === 'solutions') {
    content = <Solutions />;
  }else if (selected === 'services') {
    content = <Services />;
  }else if (selected === 'documents') {
    content = <Documents />;
  }else if (selected === 'videos') {
    content = <Videos />;
  }else if (selected === 'banking_carousel') {
    content = <BankingCarousel />;
  }else if (selected === 'banking_sections') {
    content = <BankingSections />;
  }else if (selected === 'banking_slider') {
    content = <BankingSlider />;
  }else if (selected === 'banking_application') {
    content = <BankingApplication />;
  }

  return (
    <>
      <div className="main-content">
        <div className="logout_bar px-5 d-flex justify-content-end align-items-center gap-3">
          <span>Welcome <br /> <strong>Admin</strong></span>
          <div
            className="user_container"
            onClick={setToggle}
          >
              <PiUserListFill className="menuLog"/>
          </div>
          {toggle && <Menu />}
        </div>
        <div className="p-4">{content}</div>
      </div>
    </>
  );
};

export default MainContent;
