import React from 'react'
import Banner from '../components/General/Banner/Banner'
import OverBanner from '../assets/img/overviewBanner.png'
import '../components/Overview/Overview.css'
import Sections from '../components/Overview/Sections/Sections'
import Intro from '../components/General/Intro/Intro'

const Overview = () => {
  return (
    <div>
        <Banner img={OverBanner} title='Leveraging Over 35 Years of Experience to Enhance Big Data 
        Analysis' classType='overviewClass'/>
        <Intro desc="VALOORES specializes in developing innovative geospatial artificial intelligence systems for sectors like retail, finance, government, and law enforcement. Committed to ethical practices, the company aims to optimize users' productivity and is excited about the future potential of geospatial intelligence." />
        <Sections />
    </div>
  )
}

export default Overview