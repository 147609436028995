import React from 'react'
import SectionsArray from './sectionsArray'
import './Sections.css'
import { Link } from 'react-router-dom'

const Sections = () => {
  return (
    <div className="solution_sections d-flex flex-column flex-lg-row flex-md-column align-items-center justify-content-center gap-4">
        {SectionsArray.map((section, index) => (
          <Link to={section.link} target="_blank">
            <div key={index} className='d-flex flex-column align-items-center justify-content-center gap-5 w-md-100 w-lg-30 position-relative' style={{height: '20rem',backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${section.bgImg})`}}>
              <div className="overlay"></div>
                {/* {section.logo} */}
              <img src={section.img} className='w-50 position-relative' style={{zIndex: '2'}} alt="" />
            </div>
          </Link>
        ))}
    </div>
  )
}

export default Sections