
import React from 'react'
import Banner from '../Banner'
import BannerImg from '../../img/management/modern_banner.jpg';
import videoSource from '../../img/management/modern.mp4';
import Modern1Img from '../../img/management/modern1.png';
import RiskAndThreatManagementArray from './RiskAndThreatManagementArray';



const CaseRiskAndThreatManagement = () => {
  return (
    <>
      <Banner
        img={BannerImg}
        title={
          <div className='text-center'>
           "Modern Crimes Require <br /> Modern Approach"
          </div>
        }
      />
      <div className="my-5 d-flex flex-column px-5 align-items-center" >
          
                    <h1 >What Makes VCIS What it is Today?</h1>
            <div className="my-5 d-flex flex-row gap-3 justify-content-between align-items-center">
                <div className='w-50'>
                <img src={Modern1Img} className='w-85' alt="" />
                </div>
                <div className='w-50 d-flex flex-column justify-content-center align-items-center gap-4'>
                    <p style={{lineHeight: '1.5rem', columnCount:'2'}}>
                    VCIS stands for Valoores Crowd Intelligence System. It is an investigation system that contains all intelligence and information related to the crowd. Its purpose is to control crowd activity, perform behavioral analysis, and predict location activities accordingly. The system is wide and can be used for multiple lines of businesses such as marketing, advertising, research, supply chain, healthcare, disease control, and more. VCIS is not just a platform, but a combination of 35 years of expertise in the market. Valoores empowers decision-making and helps people and businesses worldwide to reach their targets.
                    Our products' roadmap is one of the most flexible in the industry. We constantly absorb new market demands and reduce their time to market. Our added value is the simplicity and elegance of our approach. We recognize that every investigation case is different. That's why our VCIS platform is a "one solution that fits all." We provide a large number of configurable options at every level of our platform to meet law enforcement expectations. If needed, we can adapt, tailor, or change any part of our solution to their needs while adhering to our strict design standards and customization rules.
                    </p>
                </div>
            </div>
        </div>
        <div className='d-flex justify-content-center flex-wrap-wrap flex-column align-items-center'>
          <h1>VCIS Mind Mapping</h1>
        <div className='d-flex justify-content-center w-50 align-items-center '>
            <video autoPlay muted loop className='w-85'>
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            </div>
            </div>
        <div className="d-flex flex-row align-items-center flex-wrap justify-content-between gap-3">
        {RiskAndThreatManagementArray.map((CaseRiskAndThreatManagementArray, index) => (
          <div key={index} className="d-flex flex-column justify-content-center align-items-center px-5 my-5">
            {index % 2 === 0 ?
              <div>
                <div className='mb-3 text-center'>
                  <h2>{CaseRiskAndThreatManagementArray.title}</h2>
                </div>
                <div className=" d-flex flex-row justify-content-between gap-3 align-items-center">
                  <div className='w-50 px-3'>
                    <p className='column_2'>{CaseRiskAndThreatManagementArray.desc}</p>
                  </div>
                  <div className='w-50 d-flex flex-row align-items-center justify-content-center'>
                    <img src={CaseRiskAndThreatManagementArray.img} className='w-85' alt={CaseRiskAndThreatManagementArray.title} />
                  </div>
                </div>
            </div> : 
            <div>
                <div className='my-4 text-center'>
                  <h2>{CaseRiskAndThreatManagementArray.title}</h2>
                </div>
                <div className=" d-flex flex-row justify-content-center align-items-center">
                <div className='w-50 d-flex flex-row align-items-center justify-content-center'>
                    <img src={CaseRiskAndThreatManagementArray.img} className='w-85' alt={CaseRiskAndThreatManagementArray.title} />
                </div>
                <div className='w-50 px-3'>
                    <p className="column_2">{CaseRiskAndThreatManagementArray.desc}</p>
                </div>
                </div>
            </div>}
            {/* {(index === 0 || index === 2) && <ParalaxCase img={BannerImg} />} */}
          </div>

        ))}
        </div>
    </>
  )
}

export default CaseRiskAndThreatManagement
