import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import LogoWhite from '../../assets/img/logoWhite.png'
import { FaXTwitter, FaYoutube, FaLinkedin } from "react-icons/fa6";

const footer = () => {
  return (
    <footer className='p-5 position-relative h-auto '>
       <div className="d-flex flex-column align-items-center justify-content-center gap-4">
            <div className="footer_logo d-flex align-items-center justify-content-center" >
                <img src={LogoWhite} className='w-100' alt="" />
            </div>
            <div className="footer_siteMap d-flex flex-column flex-lg-row flex-sm-column gap-5 justify-content-around text-white w-75" style={{listStyle: 'none'}}>
                <div className="d-flex flex-column gap-2 justify-content-center">
                    <Link className='text-white' style={{textDecoration: 'none'}}>
                        <h5 className='text-white text-center'>Solutions</h5>
                    </Link>
                        <ul className='px-2 d-flex flex-column gap-2 footer_links' style={{listStyle: 'none'}}>
                            <li><Link to= 'https://indatacrowd.ai/index' target='_blank'> VCIS</Link></li>
                            <li><Link to= '/Valoores103A/Solutions/VFDS' target='_blank'> VFDS</Link></li>
                            <li><Link to= 'https://valoores.com/slider.php' target='_blank'> VRSC</Link></li>
                        </ul>
                </div>
                <div className="d-flex flex-column align-items-start  gap-2 justify-content-center">
                    <Link className='text-white' style={{textDecoration: 'none'}}>
                        <h5 className='text-white text-center'>Industries</h5>
                    </Link>
                        <ul className='px-2 d-flex flex-column gap-2 footer_links' style={{listStyle: 'none'}}>
                            <li><Link to = 'https://valoores.com/slider.php' target='_blank'> Retail & Supply Chain</Link></li>
                            <li><Link to = 'https://indatacrowd.ai/index' target='_blank'> Government & Public Sector</Link></li>
                            <li><Link to = '/Valoores103A/Solutions/VFDS' target='_blank'> Banking, Finance & Insurance</Link></li>
                        </ul>
                </div>
                
                <div className="d-flex flex-column align-items-start  gap-2 justify-content-center">
                    <Link className='text-white' style={{textDecoration: 'none'}}>
                        <h5 className='text-white text-center'>Technology</h5>
                    </Link>
                    <ul className='px-2 d-flex flex-column gap-2 footer_links' style={{listStyle: 'none'}}>
                        <li><Link to = '/ai'>AI / ML</Link></li>
                        <li><Link to = '/analytics'>Analytics</Link></li>
                        <li><Link to = '/ai'>Big Data</Link></li>
                    </ul>
                </div>
                
                <Link className='text-white' style={{textDecoration: 'none'}}>
                    <h5 className='text-white text-center'>Consultancy</h5>
                </Link>
                <div className="d-flex flex-column align-items-start gap-2 justify-content-center">
                    <Link className='text-white' style={{textDecoration: 'none'}}>
                        <h5 className='text-white text-center'>About Us</h5>
                    </Link>
                        <ul className='px-2 d-flex flex-column gap-2 footer_links' style={{listStyle: 'none'}}>
                            <li><Link to = '/hr/career' target='_blank'> Career</Link></li>
                            <li><Link to = '/Valoores103A/AboutUs/Overview/' target='_blank'> Overview</Link></li>
                            <li><Link to = '/aboutUs?variable=Contact' target='_blank'> Contact Us</Link></li>
                        </ul>
                </div>
                
            </div>
            <div className="footer_social d-flex flex-row align-items-center justify-content-center gap-3 text-white my-5">
                <Link>
                    <FaXTwitter size={25}/>
                </Link>
                <Link>
                    <FaYoutube size={25}/>
                </Link>
                <Link>
                    <FaLinkedin size={25}/>
                </Link>
            </div>
       </div>
       <div className="footer_copyright position-absolute position-absolute" style={{zIndex: '2'}}>
            <h5>Copyright © VALOORES 2024. All rights reserved.</h5>
       </div>
    </footer>
  )
}

export default footer