import React from 'react'
import {BsBuildingUp} from 'react-icons/bs'
import {VscSettingsGear} from 'react-icons/vsc'
import {HiOutlineLightBulb} from 'react-icons/hi'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const intro_Cards = [
    {
        icon: <BsBuildingUp />,
        title: "The Cornerstone of Valoores' Software Solutions",
        desc: "Valoores leverages AI as a fundamental building block, enabling efficient solutions across sectors and meeting high expectations.",
    },
    {
        icon: <VscSettingsGear />,
        title: "Seamless AI Integration: Powering Valoores' Diverse Solutions",
        desc: "Valoores seamlessly integrates AI and machine learning, driving growth in retail and finance, boosting revenues, and fostering economic expansion.",
    },
    {
        icon: <HiOutlineLightBulb />,
        title: "Innovating with AI: Valoores' Out-of-the-Box Solutions",
        desc: "Valoores delivers innovative AI solutions, meeting diverse customer needs and staying ahead in the rapidly evolving landscape.",
    },
]
const Intro = () => {
  return (
    <>
    <div className="container my-5">
        <div className="row">
            <Fade left>
                <div className='col-md-6'>
                    <h1>Valoores: Empowering Growth with AI-Driven Solutions across Diverse Sectors</h1>
                </div>
            </Fade>
            <Fade right>
                <div className='col-md-6'>
                    <h5 className='fw-normal'>Valoores integrates AI seamlessly into their software solutions, serving diverse sectors. AI's efficiency tackles challenges, meets consumer expectations, and drives growth. Global trends show widespread AI adoption in retail and finance, boosting revenues and economic growth. Valoores delivers innovative AI-driven solutions to meet the rising demand for AI-powered operations.</h5>
                </div>
            </Fade>
        </div>
        <div className="row mt-5">
        {intro_Cards.map((intro, index) => (
            
            <div className="col-md-4 text-center" key ={index}>
                <div className="card ai_box p-4" style={{height: "18rem"}}>
                    <Zoom>
                    <div className='mb-3' style={{fontSize: "2.2rem"}}>
                        {intro.icon}
                    </div>
                    </Zoom>
                    <Fade>
                        <h5 className='mb-3'>{intro.title}</h5>
                        <p>{intro.desc}</p>
                    </Fade>
                </div>
            </div>
        ))}
        </div>
    </div>
    </>
  )
}

export default Intro