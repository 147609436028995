const operation = 
[
    {
        title: "Overview",
        desc: 
        (
            <>
                The growing importance of operational risk in the banking sector has promoted a step for change in the way it is managed. The backward looking approach has been replaced by a forward-moving culture in which operational risk is managed in a proactive and strategic way on an organization wide basis.
                <br />
                The key drivers for increased focus on operational risk include the following:
            </>
        ),  
        list: [],
    },
    {
        title: 'Penalties',
        desc: "One of the most powerful arguments for strengthening operational risk management is the size of modern day regulatory fines. Penalties for operational failure have reached a level where they are having a material impact on firms' profitability which promotes a strong operational risk process as an integral aspect of governance across the organization. In addition to the growing complexity of modern banking operations and ever increasing number of IT systems, it is clear that banks now require a full picture of operational risk exposure across all fragmented operations.",
        list: [],
    },
    {
        title: 'Rebuilding Reputation',
        desc: 
        (
            <>
                The main motivator for an increased focus on operational risk is banks' desire to rebuild reputations. Post financial crisis, regulators and politicians continue to increase their grip on the industry which results in a strong incentive for firms to evaluate their current operational risk management approach. They would be well advised to put in place an organization-wide framework that will not only prevent failures from occurring but also provide evidence to regulators that appropriate controls are in place.
                <br/>
                Operational risk management is an integral function of any financial service organization. It is aimed at fostering a risk aware culture into people, processes and systems in the organization. As an essential ally and aide to the senior management, operational risk management creates a balance between risk and rewards in the organization therefore delivering maximum value for stakeholders. VALOORES in'Operational Risk helps enable senior management promotes this new culture while complying with strict regulatory requirements.
            </>
        ),
        list: [],
    },
    {
        title: "Key Benefits",
        desc: '',
        list:
        [
            "Enables banks to establish an enterprise-wide framework, and a uniform risk taxonomy for managing material risks on an ongoing and consistent basis",
            "Support banks in demonstrating to regulatory bodies, credit agencies and accountants their commitment to implementing sustainable, effective, and reliable risk management programs",
            "Provides detailed financial risk analysis, with a clear outlook on the interrelationship between market, credit, liquidity and operational risk.",
            "Enables banks to manage risks across a wide range of processes and functions such as IT, investments and mortgage assets, liquidity and balance sheet, community investments, and non-credit services",
            "Helps banks adopt a standardized and work-flow driven approach to risk and compliance management, which can be successfully applied to newer risks and potential regulatory requirements",
        ]
    },
    {
        title: "Key Features",
        desc: '',
        list:
        [
            "Enterprise-level solution that provides banks with computational power to not only run sophisticated risk analytics, but also deliver results that ensure more profitable, risk-informed decisions.",
            "Provides risk calculators and risk heat maps for risk analysis and monitoring, helps bring about a more interactive, risk-aware decision making.",
            "Delivers intelligent and content driven features such as access to training content from an expert community, and integration of business processes with regulatory notifications and alerts.",
            "Provides an array of advanced capabilities and functionalities combined with a best practice and scalable approach for making a coordinated effort to comply with Basel III requirements",
        ]
    },
]
export default operation