const enterprise = [
    {
        title: "",
        desc: 
        (
            <>
                As the reliance of business operations on Information Technology (IT) increases, IT environments will continue to become more complex, exposing organizations to a wide array of risks, threats, and vulnerabilities that have a direct impact on the performance of the enterprise. Moreover, several government regulations that focus on information privacy and security have emerged in recent years to safeguard consumer information and ensure corporate accountability. Compliance with these laws and mandates requires effective planning, designing, and ongoing administration of IT systems.
                <br/>
                In addition to regulatory risk and compliance challenges, IT organizations have to establish the necessary governance frameworks to manage access and identity management threats and segregation of duties. Lately, clean green initiatives in data centers have also emerged as requirement for IT governance and compliance processes.
                <br/>
                To manage these various demands successfully, the top priority for CIOs and IT executives today is to implement an integrated and enterprise-wide IT GRC program. IT GRC processes span a wide range of activities including IT governance and policy management, IT asset tracking, IT risk assessment and response, IT control implementation, IT regulatory compliance and reporting, IT incident and threat management, IT vendor risk and performance management, business continuity planning, and ongoing IT auditing.
                <br/>
                VALOORES offers an advanced and comprehensive IT GRC software solution for streamlining IT GRC processes, effectively managing IT risk, and meeting IT regulatory requirements. The MetricStream solution enables companies to implement a formal framework to rigorously measure, mitigate, and monitor IT risks. It also simplifies and reduces the cost of compliance with many regulations governing data retention, privacy, confidential information, financial accountability, and recovery from disasters.
            </>
        ),
        list: [],
    },
    {
        title: "Key Benefits",
        desc: '',
        list:
        [
            "Integrates IT governance, policy management, risk management, compliance management, audit management, and incident management",
            "create, measure, monitor, and manage IT governance programs based on leading control frameworks such as COBIT, ISO 27002, NIST, and ITIL",
            "Enables an automated and workflow driven approach to managing, communicating, and implementing IT policies and procedures across the enterprise",
            "Facilitates a systematic mechanism for managing IT surveys, certifications, self-assessments, and audits in a consistent, reliable, and predictable manner",
            "Establishes identity governance framework to manage entitlement management and Segregation of Duties (SoD) threats and incidents",
            "Extends the IT compliance program to support clean green data center energy management initiatives",
            "Integrates the IT GRC processes with enterprise GRC programs to eliminate silos, standardize processes, and improve collaboration",
        ]
    },
    {
        title: "Key Features",
        desc: '',
        list: [
            "Provides an integrated and flexible framework for documenting and analyzing IT risks, developing mitigation plans, defining controls, and managing ongoing risk assessments",
            "Contains a centralized repository of all IT compliance requirements with associated controls and automated information flows for audits, assessments, and testing",
            "Provides a versatile issue management system for capturing and tracking IT issues, incidents, deficiencies, and threats as well as for implementing corrective action and remediation plans",
            "Contains embedded content based on standard frameworks and regulations such as COBIT, ISO 27002, SOX, FFIEC, PCI, GLBA, HIPAA, CMS, and NERC",
            "Provides technology connectors to support the automated measurement and reporting of IT controls via integration with third- party products",
            "Provides clear visibility into key risk indicators, assessment results, and compliance initiatives with integrated reporting of self-assessments, manual assessments, and automated controls",
            "Provides access to harmonized controls that cover various compliance requirements including SOX, FFIEC, PCI, FISMA, GLBA, HIPAA, CMS, NERC, NIST, and other federal and state mandates through integration with the Unified Compliance Framework (UCF) database",
        ]
    },
]

export default enterprise