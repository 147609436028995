import React from 'react'
import BannerImg from '../../img/management/analytics_banner.jpg';
import AnalyticsArray from './AnalyticsArray';
import ParalaxCase from './ParalaxCase'; 
import Banner from '../Banner'; 



const CaseAnalytics = () => {
  return (
    <>
        <Banner
        img={BannerImg}
        title={
          <div className='text-center'>
           Extract more valuable insights <br /> with our VCIS Analytics and Machine Learning
          </div>
        }
      />
      
      <div className="d-flex flex-row align-items-center flex-wrap justify-content-between gap-3">
        {AnalyticsArray.map((caseAnalytics, index) => (
          <div key={index} className="d-flex flex-column justify-content-center align-items-center mt-5">
            {index % 2 === 0 ?
              <div className='px-5 '>
                <div className='mb-3 text-center'>
                  <h2>{caseAnalytics.title}</h2>
                </div>
                <div className=" d-flex flex-row justify-content-between gap-3 align-items-center">
                  <div className='w-50 px-3'>
                    <p className='column_2'>{caseAnalytics.desc}</p>
                  </div>
                  <div className='w-50 d-flex flex-row align-items-center justify-content-center'>
                    <img src={caseAnalytics.img} className='w-85' alt={caseAnalytics.title} />
                  </div>
                </div>
            </div> : 
            <div className='px-5 '>
                <div className='my-4 text-center'>
                  <h2>{caseAnalytics.title}</h2>
                </div>
                <div className=" d-flex flex-row justify-content-center align-items-center">
                <div className='w-50 d-flex flex-row align-items-center justify-content-center'>
                    <img src={caseAnalytics.img} className='w-85' alt={caseAnalytics.title} />
                </div>
                <div className='w-50 px-3'>
                    <p className='column_2'>{caseAnalytics.desc}</p>
                </div>
                </div>
            </div>}
            {index === 2 && <ParalaxCase img={BannerImg} />} 
          </div>

        ))}
      </div>
    </>
  )
}

export default CaseAnalytics
