import React from 'react'
import RecruitmentImg from '../.././img/multimedia/recruitment.png'
import { Link } from 'react-router-dom'

const Recruitment = () => {
  return (
    <div className='headerBg py-5'>
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <img src={RecruitmentImg} className='w-100' alt="" />
                </div>
                <div className="col-md-8">
                    <h5 className='mb-3 headerColor fw-bold'>Be part of VALOORES Multimedia. <Link to='/Hr/Career'>Apply for a job here!</Link></h5>
                    <p>
                        VALOORES Multimedia Communication is implementing a Human Resources policy based on performance and professional development for managers and their teams, offering everyone the opportunity to express their talent. You commit to customer service, for performance and solutions excellence; we are committed to your development and your career. At VALOORES Multimedia Communication, all employees have access to training and accountability and are recognized for their performance and commitment. VALOORES Multimedia Communication teams worldwide share simple yet strong values and are committed to a policy of sustained growth without losing sight of quality and intelligence of our Solutions. Our entire organization is centered on satisfying our clients.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Recruitment