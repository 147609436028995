

const InLoan = [
    {
      title: " ",
      desc:
      (
        <>
       valoores financial services' in' Loan Loss Forecasting and Provisioning performs major tasks required by IFRS 9 based on variety of parameters (Macroeconomic, KYC,Credit Scoring...) processed by automated forecasting and rules engines.
        </>
      ),
      list: [''],
    },
    {
      title: "Overview",
      desc:
      (
        <>
       Global financial services institutions need to accurately forecast credit losses and create provisions for such losses in accordance with rules specified under IAS/IFRS. Basel III & require institutions to forecast credit losses under baseline and multiple adverse scenarios as part of the capital management process. valoores in' Loan Loss Forecasting & Provisioning provides pre-configured & extensive computations that enable institutions to effectively forecast credit losses under multiple scenarios and compute required provisions.
        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: '',
        list: [
          'Comply with new and emerging regulations such as BCBS 239, Dodd-Frank and IFRS 9',
          'Enable strategic capital decisions using a single consistent suite of applications',
          'Adopt counter-cyclical methods for loan loss provisioning',
          'Assess risk for a portfolio across multiple parameters',
          'Enable multi jurisdiction reporting',
          'Improve capital management and strategic planning',
          'Provide key metrics to stakeholders',
          'Granular level metrics for detailed analysis',
        ],
      },
    {
        title: "Key Features",
        desc: '',
        list: [
            'Credit loss forecast under baseline and stress scenarios',
            'Pre-configured counter-cyclical methods for provision calculation',
            'Transition matrix estimation using advanced statistical methods',
            'Built-in cash flow engine',
            'Exhaustive coverage',
            'Estimation of Amortized Cost',
            'Comprehensive stress testing capabilities',
            'User-defined parameters',
            'Preconfigured reports and dashboards',
        ],
      },
  ];
  
  export default InLoan;
  