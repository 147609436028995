import Risk from '../../img/banking/icons/icon1.png';
import Profit from '../../img/banking/icons/icon2.png';
import Data from '../../img/banking/icons/icon3.png';
import Marketing from '../../img/banking/icons/icon4.png';
import Analytics from '../../img/banking/icons/icon5.png';
import Risk_white from '../../img/banking/icons/icon1_white.png';
import Profit_white from '../../img/banking/icons/icon2_white.png';
import Data_white from '../../img/banking/icons/icon3_white.png';
import Marketing_white from '../../img/banking/icons/icon4_white.png';
import Analytics_white from '../../img/banking/icons/icon5_white.png';
import Img_Risk from '../../img/banking/imgs/image1.jpg';
import Img_Profit from '../../img/banking/imgs/image2.jpg';
import Img_Data from '../../img/banking/imgs/image3.jpg';
import Img_Marketing from '../../img/banking/imgs/image4.jpg';
import Img_Analytics from '../../img/banking/imgs/image5.jpg';

const bankingSlides = [
    {
      image: Img_Risk,
      title: "The Power of VALOORES in'Analytics",
      description: "The Journey from Hindsight to Insight to Foresight!",
      header: "VALOORES in'Analytics",
      link: '/banking/feature?page=risk-and-compliance',
      icon: Risk,
      icon_white: Risk_white,
      button: 'yes',
    },
    {
      image: Img_Profit,
      title: "Descriptive Analytics",
      description: (
        <>
          Analyze: "What has happened?"<br />
          Identify and examine past successes and failures
        </>
      ),
      header: "Descriptive Analytics",
      link: '/banking/feature?page=inprofit',
      icon: Profit,
      icon_white: Profit_white,
      button: 'no',
    },
    {
      image: Img_Data,
      title: "Diagnostic Analytics",
      description: (
        <>
          Explain: “Why did it happen or is it happening?” <br />
          Drill-down to the root cause(s) of the problem
        </>
      ),
      header: "Diagnostic Analytics",
      link: '/banking/feature?page=indatagovernance',
      icon: Data,
      icon_white: Data_white,
      button: 'no',
    },
    {
      image: Img_Marketing,
      title: "Predictive Analytics",
      description: 
      (
        <>
          Predict: “What is likely to happen?”<br />
          Analyze past trends to estimate future outcomes
        </>
      ),
      header: "Predictive Analytics",
      link: '/banking/feature?page=marketing-optimization',
      icon: Marketing,
      icon_white: Marketing_white,
      button: 'no',
    },
    {
      image: Img_Analytics,
      title: "Prescriptive Analytics",
      description: 
      (
        <>
          Advise on: “What is the best course of action?”<br />
          Simulate multiple futures and assess their outcomes
        </>
      ),
      header: "Prescriptive Analytics",
      link: '/banking/feature?page=analytics',
      icon: Analytics,
      icon_white: Analytics_white,
      button: 'no',
    },
  ];

  export default bankingSlides;