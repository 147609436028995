const InBalanceSheet = [
    {
      title: "Overview",
      desc: 
      (
        <>
         VALOORES Financial services’ in’Balance Sheet exposes a detailed form of all activities related to subordinates, branches, head office and affiliates, allowing professional decisions relying on viable true presentation of your position. In’Balance Sheet allows a business to save time and money. This solution makes your balance sheet easily accessible and available giving you the instant information needed to facilitate business decisions without spending useful time or money. In’Balance Sheet provides the business with full customization capabilities in almost every aspect of the balance sheet, showing the information you need for day-to-day operations. Furthermore, balance sheets are updated in real time, giving you up-to-date information.
        </>
      ),
      list: ['']
    },
    {
      title: 'Key Benefits',
      desc: '',
      list: [
        'See your real-time balance sheet at any time',
        'Fully automate your balance sheet’s updating and reporting',
        'Balance sheet is updated in real-time using the latest data from your accounts.'
      ]
    },
    {
      title: 'features',
      desc:'',
      list:[
        'See your assets, liabilities and equity held at any time',
        'Understand your business’ performance and position',
        'Customize your balance sheet to your liking',
        'Automate balance sheet reporting'
      ]

    }
  ];
  
  export default InBalanceSheet;
  