import React from 'react'
import './Solution.css'
import SolutionBg from '../../../assets/img/solutionBg.png'
import { FaChevronRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Intro from '../../VFDS/Intro/Intro';
import { Fade } from 'react-awesome-reveal'

const Solution = () => {
  return (
    <>
      <Fade direction='up' delay={200}>
        <div className='container solution_container d-flex justify-content-center align-items-center'>
            <h4 className='fw-regular text-center' style={{width: '80%'}}>Unlock the potential of your business with tailored solutions designed to enhance efficiency, accelerate growth, and ensure lasting success. Our innovative services empower you to achieve more, faster, and smarter</h4>
        </div>
        </Fade>
        <div className='solution_container-content my-5 d-flex flex-column align-items-center text-center position-relative' style={{backgroundImage: `url(${SolutionBg})`, height: '600px', backgroundAttachment: 'fixed', backgroundSize: 'cover', backgroundPosition: 'center center'}}>
          <div className="overlay"></div>
          <div className='position-relative d-flex align-items-center flex-column w-100' style={{zIndex: '3'}}> 
            {/* <h2 className='pt-5 text-white font--poppins w-40 pb-3'>WHAT WE OFFER </h2> */}
             <Intro title='WHAT WE OFFER' fillClass="landingTitle" />
            <div className='d-flex flex-column text-start align-items-lg-start align-items-sm-center w-100 container pt-5 solution_container justify-content-center
            '>
              <Fade direction='left' cascade>
                <h1 className='text-capitalize text-white font--poppins content_title mb-5 w-50'>Tailored Solutions for every challenge</h1>
                <Link className='linkHeader text-white w-75' style={{textDecoration: 'none'}} to='/Valoores103A/Solutions' target='_blank'>
                  <h1 className='text-center text-sm-center text-lg-start'>
                    <span className='font--poppins text-white'>Explore our FDS, CIS, </span><br />
                    {/* <span className='fw-regular text-white' style={{fontSize: 'smaller'}}></span><br /> */}
                    <span className='font--poppins text-white'> Retail & Tech Ecosystem</span>
                    <FaChevronRight style={{marginLeft: '1rem'}} color='#fff' size={20} />
                  </h1>
                </Link>
              </Fade>
            </div>
          </div>
        </div>
    </>
  )
}

export default Solution