import React from 'react'
import CardArray from './CardsArray'

const Cards = () => {
  return (
    <>
        <div className='pt-5' style={{background: '#f3f3f3'}}>
            <div className="container mb-5">
                <div className='d-flex align-items-center flex-column mb-4'>
                    <h3 style={{color: '#136597'}}>VALOORES in'Gov Solutions</h3>
                    <p>A Solution For Every Need</p>
                </div>
                <div className="row gap-3">
                    {CardArray.map((card, index) => (
                        <div key={index} className="col-md-3 gov-card">
                            <img src={card.img} className='w-100 mb-4' alt="" />
                            <div className="text-content">
                                <h5 style={{color: '#136597'}}>{card.title}</h5>
                                <p>{card.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </>
  )
}

export default Cards