import React from 'react'
import './Intro.css'

const Intro = ({title, fillClass}) => {
  
  return (
    <div className={`intro_container1 ${fillClass} d-flex flex-column align-items-center justify-content-center`}>   
        <div className='d-flex flex-row align-items-center gap-3'>
            <span></span><h4 className='text-uppercase m-0'>{title}</h4><span></span>
        </div>
    </div>  
  )
}

export default Intro