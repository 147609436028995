

const InProductManager = [
    {
      title: "Overview",
      desc:
      (
        <>
      VALOORES in’ Product Information Management is designed for financial services who require a central repository to manage vital information. This advanced and innovative solution provides financial services with the ability to consolidate master information from multiple disparate applications and business lines into a single repository.
      <br/>   
    VALOORES in’ Product Information Management is an enterprise data management solution that enables customers to centralize product information from heterogeneous systems, creating a single view of product information that can be leveraged across all functional departments.
    <br/>
    VALOORES in’ Product Information Management identifies the attributes related to the lowest level of the product hierarchy retained within the bank. The same data model could be used for any types of products and services managed within the bank; where we centralize the creation of the cards, loans, insurance and other services in a centralized place differing by type.
        </>
      ),
      list: [''],
    },
   
  ];
  
  export default InProductManager;
  