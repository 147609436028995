

const InChanel = [
    {
      title: "Overview",
      desc:
      (
        <>
     VALOORES in' Channels Management empowers channel users to maximize operational performance by making better decisions faster and to increase profit margins by effectively controlling disputes/deductions , trade funds…
     <br/>
     Improper processing of channel data – including point of sale (POS) reports, inventory reports, incentive claims and more – can cause revenue leaks and drive up costs, VALOORES in' Channels Management fully automates the process of collecting, processing and analyzing multi-tier channel data.
        </>
      ),
      list: [''],
    },
  
  ];
  
  export default InChanel;
  