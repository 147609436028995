import React from 'react'
import NewsEventsSection from '../components/NewsEvents/NewsEvents'
import Banner from '../components/General/Banner/Banner'
import NewsBanner from '../assets/img/News/newsBanner.jpg'

const NewsEvents = () => {
  return (
    <div>
        <Banner title="News & Events" desc = "" img={NewsBanner} />
        <NewsEventsSection />
    </div>
  )
}

export default NewsEvents