import React, { useEffect, useState, useRef, useMemo } from "react";
import Axios from './database/axios.jsx';
import ReactHtmlParser from 'react-html-parser';

const Counter = () => {

  const fetchedCards = Axios({ path: '/counter' });
  const fetchedData = Axios({ path: '/section' });

  const duration = 2000;
  const [counts, setCounts] = useState(Array(fetchedCards.length).fill(0));
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        const startTime = Date.now();
        const interval = setInterval(() => {
          const elapsed = Date.now() - startTime;
          if (elapsed >= duration) {
            clearInterval(interval);
            setCounts(fetchedCards.map(({ value }) => value));
          } else {
            const newCounts = fetchedCards.map(({ value }) => Math.min(Math.floor(value * elapsed / duration), value));
            setCounts(newCounts);
          }
        }, 50);
        
        return () => clearInterval(interval);
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [fetchedCards]);

  return (
    <>
    {fetchedData.map((counter) => (
      <>
       {counter.type === 'counter' && (
          <div ref={ref}>
            <div className="counter_title mt-5">
              <div className="container">
                <div className={counter.text_container}>
                  {ReactHtmlParser(counter.title)}
                  <div className={counter.image_container} >
                    {fetchedCards.map((data, index) => (
                      <div className={counter.class} key={index}>
                        <img className='icon-img' src={require(`../../icons/${data.icon_path.split('/').pop()}`)} alt="" />
                        <div className={counter.link}>
                          <p className='number'>{counts[index]}</p>
                          <span>+</span>
                        </div>
                        <div className='counter-desc'>
                          <p>{data.title}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          )}
        </>
      ))}
    </>
  )
}

export default Counter;
