
const inAnalyticalApp = [
  {
    title: "Overview",
    desc:
    (
      <>
        In response to the recent global financial crisis, the Basel III regulatory requirements are considered robust and far reaching; however, banks are finding it challenging to operationally comply with these regulations. In recognition of these regulatory challenges, valoores' in' Analytical Applications Basel III solution has been architected based on a unified platform, which breaks down the traditional analytical silos within a financial institution, including risk, performance and finance. Leveraging this unified framework gives senior management a more complete and integrated view of the risks across the enterprise, thus strengthening the decision making process at all levels.


      </>
    ),
    list: [''],
  },
  {
      title: "Key Benefits",
      desc: ' ',
      list: [
        'Meet multi-jurisdictional Basel III requirements in a phased approach',
        'Reduce TCO by leveraging existing investments',
        'Manage business effectively through a common Risk & Finance Architecture',
        'Achieve capital adequacy compliance with pre-built rules and analytics',
        'Maximize performance leveraging engineered systems with the power of in-database technologies',
        'Leverage pre-defined reports and dashboards for both management and regulatory reporting',
        'Reduce cycle time and improve usefulness of management information',
      ],
    },
   
  ];
  
  export default inAnalyticalApp;
  