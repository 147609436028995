import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import mindMapArray from '../mindMap_array';
import Banner from '../Banner';
import LeaderImg from '../../img/banners/leader.jpg';
import ValooresLogo from '../../img/full_logoC.png';
import { Link as ScrollLink } from 'react-scroll';
import { FaArrowAltCircleUp, FaSearch } from "react-icons/fa";


const MindMap = () => {
    const [activeCategory, setActiveCategory] = useState(null);
    const [activeTitle, setActiveTitle] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryDiv, setSearchQueryDiv] = useState(false);
    const navigate = useNavigate();

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        if(e.target.value !== ''){
            setSearchQueryDiv(true);
        } else {
            setSearchQueryDiv(false);
        }
    }
    
    const handleSetActive = (category) => {
        setActiveCategory(category === activeCategory ? null : category);
        setActiveTitle(null);
    };

    const handleSetActiveTitle = (title) => {
        setActiveTitle(title === activeTitle ? null : title);
    };

    const uniqueCategories = new Set();
    const uniqueMindMapArray = mindMapArray.filter(mind => {
        if (!uniqueCategories.has(mind.category)) {
            uniqueCategories.add(mind.category);
            return true;
        }
        return false;
    });

    const filteredEmployees = mindMapArray.filter(employee =>
        employee.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        employee.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        employee.desc.toLowerCase().includes(searchQuery.toLowerCase())
    );
   
    let isLoggedIn= localStorage.getItem('isLoggedIn');

    useEffect(() => {
        isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn !== 'true') {
            navigate('/'); 
        }
    }, []);

    if (isLoggedIn === 'true') {
        return (
            <>
                <Banner image={LeaderImg} title='Mind Map' />

                <div className='text-center p-4 shadow mb-4' id='toTop'>
                    <img src={ValooresLogo} className='w-25' alt='' />
                </div>
                <div className='container d-flex flex-row align-items-center justify-content-center mb-4'>
                    <input
                        type="text"
                        className='form-control'
                        name=""
                        id=""
                        placeholder='Search...'
                        value={searchQuery}
                        onChange={(e) => handleSearch(e)}
                        style={{position: 'relative'}}
                    />
                    <FaSearch style={{position: 'absolute', right: '6%'}}/>
                </div>

                {searchQueryDiv && 
                    <div className="container mt-4" >
                        <h2 className="text-center">Search Results</h2>
                        <div className="row">
                            {filteredEmployees.map((employee, index) => (
                                <div className="col-md-4 mb-4" key={index}>
                                <div className="card">
                                    <div className="card-body d-flex flex-row gap-3">
                                        <div className='d-flex justify-content-center border' style={{width: '100px', height: '130px', overflow: 'hidden'}}>
                                            <img style={{width: 'auto', height: 'auto'}} src={employee.img} alt="" />
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <p className='m-0 p-0'>{employee.desc}</p>
                                            <h5>{employee.name}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                }

                {!searchQueryDiv && 
                    <div className="container">
                        <div className="d-flex flex-row flex-wrap gap-1 justify-content-between">
                            {uniqueMindMapArray.map((mind, index) => (
                                <ScrollLink
                                    to="main_scroll"
                                    spy={true}
                                    smooth={true}
                                    duration={50}
                                    key={index}
                                    className={`category_container text-center p-4 border rounded mb-4 pointer ${
                                        activeCategory === mind.category ? 'active' : ''
                                        }`}
                                    onClick={() => handleSetActive(mind.category)}
                                    style={{width: '32%'}}
                                >
                                    <h4>{mind.category}</h4>
                                </ScrollLink>
                            ))}
                        </div>
                        <hr className='mb-5' />

                    </div>
                }
                <div id='main_scroll' className='mb-5'>
                {activeCategory && (
                    <div className="container mt-4">
                        <h2 className="text-center">{activeCategory}</h2>
                        <div className="row">
                            {getUniqueTitlesForCategory(activeCategory).map((title, index) => (
                                <div className="col-md-4 mb-4 pointer" key={index}>
                                    <div
                                        className={`card ${activeTitle === title ? 'border-primary' : ''}`}
                                        onClick={() => handleSetActiveTitle(title)}
                                    >
                                        <div className="card-body">
                                            <h5 className="card-title">{title}</h5>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {activeTitle && (
                    <div className="container mt-4">
                        {/* <h3 className="text-center my-4">Description</h3> */}
                        <hr className='mb-5' />
                        <div className="row">
                            {mindMapArray
                                .filter(item => item.category === activeCategory && item.title === activeTitle)
                                .map((item, index) => (
                                    <div className="col-md-4 mb-4" key={index}>
                                        <div className="card" >
                                            <div className="card-body d-flex flex-row gap-3">
                                                <div className='d-flex justify-content-center border' style={{width: '100px', height: '130px', overflow: 'hidden'}}>
                                                    <img style={{width: 'auto', height: 'auto'}} src={item.img} alt="" />
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <p className='m-0 p-0'>{item.desc}</p>
                                                    <h5>{item.name}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
                </div>
                <ScrollLink 
                    to="toTop"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={50}
                    className='text-white pointer'
                >
                    <FaArrowAltCircleUp />
                </ScrollLink>
            </>
        )
    }
};

const getUniqueTitlesForCategory = (category) => {
    const uniqueTitles = new Set();
    mindMapArray.forEach(item => {
        if (item.category === category && !uniqueTitles.has(item.title)) {
            uniqueTitles.add(item.title);
        }
    });
    return Array.from(uniqueTitles);
};

export default MindMap;