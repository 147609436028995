import React from 'react'
import {Link} from 'react-router-dom'
import laptop from '../../img/laptop.png'
import valooresdashboard from '../../img/Digital/paralax-1.jpg'

const Paralax = (props) => {
  return (
    <>
       
            <div className=" d-flex flex-column align-items-center justify-content-center" style={{top: `${props.top}`, backgroundImage:`url(${valooresdashboard})`, height: '250px', backgroundSize: 'cover', backgroundPosition:'center center', backgroundAttachment:'fixed'}}>
                <h4 className='text-center text-white px-4 w-75'>"Powered by VALOORES Digital Transformation Competencies,
Companies are unleashing fundamentally New Business Practices, at a pace that was almost unthinkable…
Inventing New Business Models and Strategies! "</h4>
                
           
        </div>
    </>
  )
}

export default Paralax