import VCISlogo from '../../../assets/img/VCIS-logo-white.png'
import VFDSlogo from '../../../assets/img/VFDS-logo-white.png'
import VRCSlogo from '../../../assets/img/VRCS-logo-white.png'
import VcisBg from '../../../assets/img/vcisSolutions.jpg'
import VfdsBg from '../../../assets/img/banking.jpg'
import RetailBg from '../../../assets/img/retailSolutions.jpg'
// import { FaMapLocationDot } from "react-icons/fa6";
// import { FaShoppingBasket, FaMoneyCheckAlt  } from "react-icons/fa";


const sectionsArray = [
    {
        // logo: <FaMapLocationDot size={70} fill="#fff"/>,
        bgImg: VcisBg,
        img: VCISlogo,
        link:'http://indatacrowd.ai/'
    },
    {
        // logo:<FaMoneyCheckAlt size={70} fill="#fff"/>,
        bgImg: VfdsBg,
        img: VFDSlogo,
        link:'/Valoores103A/Solutions/VFDS'
    },
    {
        // logo: <FaShoppingBasket size={70} fill="#fff"/>,
        bgImg: RetailBg,
        img: VRCSlogo,
        link:'https://valoores.com/slider.php'
    },
]

export default sectionsArray;