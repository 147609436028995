import React from 'react';
import {Link} from 'react-router-dom';
import Axios from './database/axios.jsx';
import ReactHtmlParser from 'react-html-parser';

const Services = () => {
  const fetchedCards = Axios({ path: '/services' });
  const fetchedData = Axios({ path: '/section' });
  
  return (
    <>
      {fetchedData.map((services) => (
        <>
        {services.type === 'services' && (
          <div className="services_section" id='services_scroll'>
              <div className="container">
                  <div className="row">
                      <div className={services.text_container}>
                        {ReactHtmlParser(services.title)}
                      </div>
                      {fetchedCards.map((serv, index) => (
                      <Link target="_blank"  key={index} to={serv.link} className={services.image_container}>
                        <div className="">
                          <div className="img_bg" style={{ backgroundImage: `url(${require(`../../img/${serv.image_path.split('/').pop()}`)})` }}>
                            <div className={services.class}></div>
                            <div className={services.link}>
                              <h4>{serv.title}</h4>
                              <p>{serv.description}</p>
                            </div>
                          </div>
                        </div>
                      </Link>
                      ))}
                  </div>
              </div>
          </div>
        )}
      </>
    ))}
  </>
  )
}

export default Services