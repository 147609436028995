import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import ReactHtmlParser from 'react-html-parser';

// Set the app element for accessibility
Modal.setAppElement('#root');

function Application() {
  const [selectedFile, setSelectedFile] = useState(null);
//   const [selectedFile2, setSelectedFile2] = useState(null);
  const [editedFile, setEditedFile] = useState(null);
//   const [editedFile2, setEditedFile2] = useState(null);
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [input4, setInput4] = useState('');
  const [input5, setInput5] = useState('');
  const [input6, setInput6] = useState('');
  const [input7, setInput7] = useState('');
  const [input8, setInput8] = useState('');
  const [fetchedData, setFetchedData] = useState([]);
  const [editValue, setEditValue] = useState('');
  const [editValue2, setEditValue2] = useState('');
  const [editValue3, setEditValue3] = useState('');
  const [editValue4, setEditValue4] = useState('');
  const [editValue5, setEditValue5] = useState('');
  const [editValue6, setEditValue6] = useState('');
  const [editValue7, setEditValue7] = useState('');
  const [editValue8, setEditValue8] = useState('');
  const [editValue9, setEditValue9] = useState('');
  const [editValue10, setEditValue10] = useState('');
  const [editId, setEditId] = useState('');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

//   const handleFileChange2 = (event) => {
//     setSelectedFile2(event.target.files[0]);
//   };

  const handleFileEdit = (event) => {
    setEditedFile(event.target.files[0]);
  };

//   const handleFileEdit2 = (event) => {
//     setEditedFile2(event.target.files[0]);
//   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        // formData.append('file2', selectedFile2);
        const uploadResponse = await axios.post('http://localhost:8800/banking_application/upload', formData);
        // const imagePath = uploadResponse.data.imagePath;
        const iconPath = uploadResponse.data.iconPath;

        const res = await axios.post('http://localhost:8800/banking_application/database', {
          input1: input1,
          input2: input2,
          input3: input3,
          input4: input4,
          input5: input5,
          input6: input6,
          input7: input7,
          input8: input8,
          // imagePath: imagePath,
          iconPath: iconPath,
        });
        console.log(res);
      } else {
        console.log('Please upload an image and an icon.');
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:8800/banking_application/show');
        setFetchedData(response.data);
        setIsDataFetched(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleClick = (id, value, value2, value3, value4, value5, value6, value7, value8, value9, value10) => {
    setEditId(id);
    setEditValue(value);
    setEditValue2(value2);
    setEditValue3(value3);
    setEditValue4(value4);
    setEditValue5(value5);
    setEditValue6(value6);
    setEditValue7(value7);
    setEditValue8(value8);
    setEditValue9(value9);
    setEditValue10(value10);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (delete_id, delete_rel_id, icon_path) => {
    try {
      const response = await axios.delete('http://localhost:8800/banking_application/deleted', {
        data: {
          delete_ids: delete_id,
          delete_rel_ids: delete_rel_id,
          icon_path: icon_path,
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setEditValue(e.target.value);
  };
  const handleInputChange2 = (e) => {
    setEditValue2(e.target.value);
  };
  const handleInputChange3 = (e) => {
    setEditValue3(e.target.value);
  };
  const handleInputChange4 = (e) => {
    setEditValue4(e.target.value);
  };
  const handleInputChange5 = (e) => {
    setEditValue5(e.target.value);
  };
  const handleInputChange6 = (e) => {
    setEditValue6(e.target.value);
  };
  const handleInputChange7 = (e) => {
    setEditValue7(e.target.value);
  };
  const handleInputChange8 = (e) => {
    setEditValue8(e.target.value);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if(editedFile){
        
        const IconForm = new FormData();
        IconForm.append('file', editedFile);
        IconForm.append('icon_path', editValue9);
        IconForm.append('id', editId);

        // const IconForm = new FormData();
        // IconForm.append('file2', editedFile2);
        // IconForm.append('icon_path', editValue8);
        // IconForm.append('id', editId);
        
        // const uploadEditResponse = await axios.post('http://localhost:8800/banking_application/editImage', ImgForm);
        // const imagePath = uploadEditResponse.data.imagePath;
        
        const uploadsEditResponse = await axios.post('http://localhost:8800/banking_application/editIcon', IconForm);
        const iconPath = uploadsEditResponse.data.iconPath;

      }
        const res = await axios.put('http://localhost:8800/banking_application/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
          value3: editValue3,
          value4: editValue4,
          value5: editValue5,
          value6: editValue6,
          value7: editValue7,
          value8: editValue8,
          value9: editValue9,
          value10: editValue10,
        });
        console.log('Value edited:', res.data);
    } catch (error) {
    }
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };
  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false); 
  };

  return (
    <>
      <div className= "d-flex flex-row justify-content-between align-items-center container mb-4">
        <h1>Banking application</h1>
        <button className="btn btn-primary h-75" onClick={handleAddModalOpen}>Add application</button>
      </div>
      
        <div className="tableStyle">
          <table className="table">
            <thead className="sticky-header">
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Description</th>
                <th>Video</th>
                <th>More</th>
                <th>Quick</th>
                <th>Demo</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <>
                {fetchedData.map((item) => (
                  <tr key={item.id} className="application_rows">
                    <td>{item.id}</td>
                    <td className="truncate img_size">{ReactHtmlParser(item.title)}</td>
                    <td className="truncate">{item.description}</td>
                    <td>{item.video}</td>
                    <td className="truncate">{item.more}</td>
                    <td className="truncate">{item.quick}</td>
                    <td className="truncate">{item.demo}</td>
                    <td>
                      <button className="btn btn-secondary" onClick={() => handleClick(item.id, item.class, item.style, item.title, item.description, item.video, item.more, item.quick, item.demo, item.icon_path)}>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button className="btn btn-danger" type="submit" onClick={() => handleDelete(item.id, item.related_id, item.icon_path)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            </tbody>
          </table>
        </div>
      {/* EDIT application MODAL */}
      <Modal
      isOpen={isEditModalOpen}
      onRequestClose={handleEditModalClose}
      contentLabel="Edit Modal"
      >
          <div className="">
              <div className="">
                  <div className="">
                      <form className='edit_form'>
                          <div>
                              <h5 className="pb-3 px-3">Edit application</h5>
                          </div>

                          <div className=" px-4">
                              <div className="row mb-2">
                                  <div className="col-md-6">
                                      <label htmlFor="SliId" >application ID</label>
                                      <input
                                          id="SliId"
                                          type="text"
                                          disabled
                                          readOnly
                                          value={editId}
                                          placeholder="application Edit ID"
                                          className='form-control my-3'
                                      />
                                  </div>
                                  <div className="col-md-6">
                                      <label htmlFor="SliTitle">application Class</label>
                                      <input
                                          id="SliTitle"
                                          type="text"
                                          value={editValue}
                                          onChange={handleInputChange}
                                          placeholder="application Edit Title"
                                          className='form-control my-3'
                                      />
                                  </div>
                              </div>

                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SliDesc">application Style</label>
                                          <input
                                              id="SliDesc"
                                              type="text"
                                              value={editValue2}
                                              onChange={handleInputChange2}
                                              placeholder="application Edit Description"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SliOrder">application Title</label>
                                          <input
                                              id="SliOrder"
                                              type="text"
                                              value={editValue3}
                                              onChange={handleInputChange3}
                                              placeholder="application Edit Order"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SliIC">application Description</label>
                                          <input
                                              id="SliIC"
                                              type="text"
                                              value={editValue4}
                                              onChange={handleInputChange4}
                                              placeholder="application Edit link"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SliTC">application Video</label>
                                          <input
                                              id="SliTC"
                                              type="text"
                                              value={editValue5}
                                              onChange={handleInputChange5}
                                              placeholder="application Edit style"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SliClass">application More</label>
                                          <input
                                              id="SliClass"
                                              type="text"
                                              value={editValue6}
                                              onChange={handleInputChange6}
                                              placeholder="application Edit Class"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                                  
                                    <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SliClass">application Quick</label>
                                          <input
                                              id="SliClass"
                                              type="text"
                                              value={editValue7}
                                              onChange={handleInputChange7}
                                              placeholder="application Edit Class"
                                              className='form-control my-3'
                                          />
                                      </div>
                                    </div>
                              </div>
                              <div className="row">
                              <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SliClass">application Demo</label>
                                          <input
                                              id="SliClass"
                                              type="text"
                                              value={editValue8}
                                              onChange={handleInputChange8}
                                              placeholder="application Edit Class"
                                              className='form-control my-3'
                                          />
                                      </div>
                                    </div>
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SliImg">Icon File</label>
                                          <input id="SliImg" type="file" onChange={handleFileEdit} 
                                          className='form-control my-3'/>
                                      </div>
                                  </div>

                              </div>
                              
                          </div>

                          <div className="px-4 d-flex justify-content-end gap-3">
                            <button className='btn btn-secondary' onClick={handleEditSubmit}>Submit Edit</button>
                            <button className='btn btn-danger' onClick={() => setIsEditModalOpen(false)}>Cancel</button>
                          </div> 
                      </form>
                  </div>
              </div>
          </div>
      </Modal>

      {/* ADD application MODAL */}
      <Modal
        isOpen={isAddModalOpen}
        onRequestClose={handleAddModalClose}
        contentLabel="Add Modal"
      >
        
        <div className="">
          <div className="">
            <div className="">
              <form className='add_form'>
                <div>
                  <h5 className="pb-3 px-3">Add application</h5>
                </div>

                <div className=" px-4">
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label htmlFor="addAppClass">application Class</label>
                      <input
                        id="addAppClass"
                        type="text"
                        value={input1}
                        onChange={(e) => setInput1(e.target.value)}
                        placeholder="application Class"
                        className='form-control my-3'
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="addAppStyle">application Style</label>
                      <input
                        id="addAppStyle"
                        type="text"
                        value={input2}
                        onChange={(e) => setInput2(e.target.value)}
                        placeholder="application Style"
                        className='form-control my-3'
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addAppTitle">application title</label>
                        <input
                            id="addAppTitle"
                            type="text"
                            value={input3}
                            onChange={(e) => setInput3(e.target.value)}
                            placeholder="application title"
                            className='form-control my-3'
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                      <label htmlFor="addAppDesc">application Description</label>
                        <input
                            id="addAppDesc"
                            type="text"
                            value={input4}
                            onChange={(e) => setInput4(e.target.value)}
                            placeholder="application Description"
                            className='form-control my-3'
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addAppVideo">application Video</label>
                        <input
                            id="addAppVideo"
                            type="text"
                            value={input5}
                            onChange={(e) => setInput5(e.target.value)}
                            placeholder="application Video"
                            className='form-control my-3'
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addAppMore">application More</label>
                        <input
                            id="addAppMore"
                            type="text"
                            value={input6}
                            onChange={(e) => setInput6(e.target.value)}
                            placeholder="application More"
                            className='form-control my-3'
                        />
                      </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addAppQuick">application Quick</label>
                        <input
                            id="addAppQuick"
                            type="text"
                            value={input7}
                            onChange={(e) => setInput7(e.target.value)}
                            placeholder="application Quick"
                            className='form-control my-3'
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addAppDemo">application Demo</label>
                        <input
                            id="addAppDemo"
                            type="text"
                            value={input8}
                            onChange={(e) => setInput8(e.target.value)}
                            placeholder="application Demo"
                            className='form-control my-3'
                        />
                      </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addAppImg">Icon File</label>
                        <input id="addAppImg" type="file" onChange={handleFileChange} className='form-control my-3'/>
                      </div>
                    </div>
                    </div>
                  </div>
                <div className="px-4 d-flex justify-content-end gap-3">
                  <button className='btn btn-secondary' onClick={handleSubmit}>Submit</button>
                  <button className='btn btn-danger' onClick={() => setIsAddModalOpen(false)}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Application;