import React from 'react';
import Leaders from './Leaders';
import Ceo from './Ceo';
// import Mission from './Mission';
import Contact from './Contact';

const AboutContent = ({ selected }) => {
  let content;
  // let title; 

  if (selected === 'Contact') {
    content = <Contact />;
   // title = 'Contact Us';
  }
  //  else if (selected === 'Ceo') {
  //   content = <Ceo />;
  //   title = 'Word Of The CEO';
  // } else if (selected === 'Mission') {
  //   content = <Mission />;
  //   title = 'Mission & Vision';
  // } else if (selected === 'Leaders') {
  //   content = <Leaders />;
  //   title = 'Executive Leaders';
  // }
  //  else if (selected === 'Ceo') {
  //   content = <Ceo />;
  //   // title = 'Word Of The CEO';
  // // } else if (selected === 'Mission') {
  // //   content = <Mission />;
  // //   title = 'Mission & Vision';
  // } else if (selected === 'Leaders') {
  //   content = <Leaders />;
  // //   title = 'Executive Leaders';
  // }

  return (
    <>
      <div className="main-content_about w-100">
        <div className="title_bar px-3 d-flex flex-column justify-content-center pt-5">
            {/* <h1>{title}</h1> */}
            {/* <hr/> */}
        </div>
        <div className='p-4'>
          {content}
        </div>
      </div>
    </>
  );
};

export default AboutContent;
