import Tech1 from "../../../assets/img/tech1.png"
import Tech2 from "../../../assets/img/tech2.png"

const techArray = [
    {
        img: Tech1,
        title: 'Cutting Edge Technology',
        list: ['Artificial Intelligence', 'Machine Learning', 'Big Data', 'Data Analytics', 'Business Intelligence']
    },
    {
        img: Tech2,
        title: 'Looking for Insights That Propel Your Growth Forward?',
        list: ['Explore Our Consultancy Services']
    }
]

export default techArray;