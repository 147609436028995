import React from 'react';
import { Link } from 'react-router-dom';
import './AboutUs.css'; 
import AboutUsArray from './aboutUsArray';

const AboutUsSubMenu = ({isAboutUsOpen, isScrolled}) => {
  return (
  <div className={`about-us-submenu primary-bg px-5 py-3 d-flex flex-column gap-1 position-absolute ${isScrolled ? 'scrolledSub' : ''} ${isAboutUsOpen ? 'open' : ''}`} style={{top: '4.5rem', width: '40vw'}}>
    {/* <div className={`about-us-submenu primary-bg p-4 d-flex flex-column gap-1 position-absolute ${isAboutUsOpen ? 'open' : ''}`} style={{top: '4.5rem', width: 'max-content'}}> */}
    <p style={{fontSize: '0.8rem'}}>Learn about our people, values, commitment and more</p>
    {/* <div className='d-flex flex-row flex-wrap justify-content-start align-items-start gap-3'> */}
    <div className='d-flex flex-row flex-wrap justify-content-between align-items-start gap-3'>
        {AboutUsArray.map((submenu, index) => (
        <div className="submenu-section d-flex flex-column align-items-start my-2" key={index}>
            <p style={{borderBottom: '2px solid'}}>{submenu.title}</p>
            <ul className='d-flex flex-column align-items-start justify-content-center gap-3 p-0 mt-2'>
            {submenu.links.map((sublink, idx) => (
                <li className='h-auto ' key={idx}><Link className='m-0 p-0 text-blue' style={{color: '#051c2c !important;'}} to={sublink.link}>{sublink.title}</Link></li>
            ))}
            </ul>
        </div>
        ))}
    </div>
</div>
  );
};

export default AboutUsSubMenu;
