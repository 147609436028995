

const InDeals = [
    {
      title: "Overview",
      desc:
      (
        <>
          With the diversity of suppliers and the rapid growth of competition, optimizing the supplier deal negotiations has become one of the greatest opportunities for profit improvement.
          <br />
          Setting targets, identifying the best suppliers and adopting the appropriate negotiation strategies is getting more and more complex, when it comes to maximizing returns and leaving no money on the table. In’Deals offers our client an efficient solution that will increase your supplier deal negotiation revenue to its limits, by improving the negotiation at all the levels from the preparation stage to the contract closure.
          <br />
          In’Deals is the business solution that governs the entire negotiation cycle and supplier deal management. Starting from setting target and negotiating strategy, selecting potential suppliers, preparing the “what if?” scenarios based on accurate historical data, all the way through the contracts management, follow up and payments reconciliation.
          <br />
        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
          'Standardized work methods and consolidated results at all hierarchy levels, improving the communication and organization',
          'Easy accessible information with simply and detailed generated reports',
          'Automated contracts creation and invoicing',
          'Funding and reconciliation recording and follow up',
          'Negotiation tracking compared with targets and strategies',
        ],
      },
      {
        title: "Key features",
        desc:'',
        list:
        [
          'Improving negotiation performance to maximize returns',
          'Saving time while increasing productivity',
          'Reducing resources capital by implementing automated operations',
          'Continuing performance follow up',
        ],
      },
      {
        title: "Market Challenges",
        desc:'',
        list:
        [
          'Individual negotiation and calculation methods',
          'Lack of transparency and reliable information',
          'Time consumption in gathering data and correcting errors',
          'Inadequate record keeping and funding follow up',
        ],
      },
  ];
  
  export default InDeals;
  