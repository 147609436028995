
const InVia = [
    {
      
      title: "Overview",
      desc: "The in’Via is a user friendly business intelligence tool, dedicated to executives and business users to empower their everyday business decisions, via a direct access to a library of Key Performance and Risk Indicators. The in’Via includes a Dashboard Builder with Alerts and Tasks, an Application Builder, and a Library of KPRIs.",
    //   link: "https://banking.valoores.com/campaignmanagement.php",
    },
    {
     
      title: "Key Benefits",
      desc:[
        (
        <div>
                <ul className='list-disk'>
                <li>Identify Trends, Discover Opportunities & Anticipate Emerging Risks</li>
                <li> Gain Insights on Key Parameters for Better Decision Making</li>
                <li> Multi-purpose, Business intelligence dashboard.</li>
                <li> Unifying data sources</li>
                <li> Avoiding duplicate information</li>
                <li>Information Transparency for Higher Traceability </li>
                <li>Multilanguage Application Enabling Diversity </li>
                <li> Efficient workforce management</li>
                <li> Transforming Data to Knowledge</li>
                </ul>
            </div>
        )
    ],
    //   link: "https://banking.valoores.com/pricing.php",
    },
    {
     
      title: "Dashboard Builder",
      desc:[
        (
        <div>
                <ul className='list-disk'>
                <li>Create Alerts and Tasks</li>
                <li>Personalized dashboards </li>
                <li> Edit on the fly</li>
                <li> Define unique user experience</li>
                <li>Manage secured actions and roles </li>
                </ul>
            </div>
        )
    ],
    //   link: "https://banking.valoores.com/deals.php",
    },

    {
     
      title: "Application Builder",
      desc:[
        (
        <div>
                <ul className='list-disk'>
                <li>Easily Build and Access KPRIs</li>
                <li>Define KPRIs parameters </li>
                <li> Add, Edit or Delete, Search & Favorite Functions</li>
                <li> Chart Definition & Customize attributes</li>

                </ul>
            </div>
        )
    ],
    //   link: "https://banking.valoores.com/deals.php",
    },
  ]

  export default InVia