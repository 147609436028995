

const inEntPlanning = [
    {
      title: "Overview",
      desc:
      (
        <>
      Each year financial institutions are required to comply with a plethora of regulations, which becomes even more complicated for those operating in multiple jurisdictions. With the onset of additional scrutiny from regulators on liquidity under stressed conditions and the Internal Capital Adequacy Assessment Process (ICAAP), stress testing is at the forefront of the requirements banks have to comply with; and this time around the mandates are stricter, have tighter timelines, and require on-demand reporting. valoores in' Enterprise Stress Testing and Capital Planning Analytics enables financial institution to comply with the new orders and gives senior management a 360o view of data to eliminate organizational silos.
        </>
      ),
      list: [''],
    },
    {
      title: "Key Benefits",
      desc: '',
      list: [
        'Provide CXOs with a single, consistent view of stress testing results through the assessment of each stress scenario across risk and finance',
        'Empower top management to proactively make capital management decisions based on accurate, timely and streamlined information',
        'Achieve sound capital planning by identifying and addressing weaknesses in capital adequacy',
        'Ensure consistency in your capital planning process by projecting profit and loss, income, balance sheet and capital based on the same scenarios',
        'Ensure effective compliance with the Dodd-Frank Act and the guidelines of multiple regulators including BIS, FSA, APRA, and US Federal Reserve',
        'Leverage standard or customer built report templates and dashboards and become privy to valuable information within minutes',
      ],
    },
    {
      title: "Key Features",
      desc: '',
      list: [
        'Pre-configured reports covering stressed income statement, balance sheet, profit and loss, capital, liquidity and performance metrics',
        'DFAST reports included for North American financial institutions',
        'Assess variance between the baseline and stress values',
        'Multi-dimensional assessment of metrics across lines of business, risk categories, and product types',
        'Analyze metrics across the planning period and across multiple stress scenarios',
        'Metrics displayed in the form of point-in-time values, trends, and heat maps',
        'Extensive drill-through capability allowing for detailed analysis',
      ],
    },
  
  ];
  
  export default inEntPlanning;
  