import React from 'react'

const Banner = (props) => {
  return (
    <>
        <div>
            <div className="hero_banner" style={{backgroundImage : `url(${props.img})`}}>
                <div className="hero__overlay"></div>
                <h1>{props.title}</h1>
            </div>
        </div>
    </>
  )
}

export default Banner