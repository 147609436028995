import React from 'react'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import {RiSingleQuotesL, RiSingleQuotesR} from 'react-icons/ri'
import {GrTechnology, GrOptimize, GrGlobe} from 'react-icons/gr'
import {HiOutlineUsers} from 'react-icons/hi'
import retailDoc2 from '../../../docs/retail/GeospatialAnalytics.pdf'
import Banner from '../BannerRetail'
import BannerImg1 from '../../../img/retail/analytics/banner_analytics.png'
import Retail1 from '../../../img/retail/data/Retail1.jpg'
import Retail2 from '../../../img/retail/data/Retail2.jpg'
import Retail3 from '../../../img/retail/data/Retail3.jpg'
import Retail4 from '../../../img/retail/data/Retail4.jpg'
import Retail5 from '../../../img/retail/data/Retail5.jpg'
import Retail6 from '../../../img/retail/data/Retail6.jpg'
import Retail7 from '../../../img/retail/data/Retail7.jpg'
import Retail8 from '../../../img/retail/data/Retail8.jpg'
import Retail9 from '../../../img/retail/data/Retail9.jpg'


let retail = [
  {
  title: " Importance of Geospatial Analytics",
  desc: "Location and geospatial analytics are crucial for decision-making in organizations. With location-aware technology becoming more accessible, businesses can gain valuable insights, stay ahead of trends, and enhance retail operations."
},
{
  title: "Evolving Decision-making Approach",
  desc: "Traditional site selection and market analysis are giving way to a more dynamic approach. Striking the right balance between physical and digital storefronts is vital to meet customer demands and succeed in today's competitive landscape."
},
{
  title: "Benefits of Location Analysis",
  desc: "Aligning business strategies with individual markets brings multiple benefits. Retailers, economic developers, and stakeholders can deliver goods and services effectively, meeting market demand and maximizing potential for success."
},
]


const Intro = () => {
  return (
    <>
      <h2 className='my-5'><RiSingleQuotesL />Drawing from Valoores' experience and expertise, it becomes evident that the
        implementation of geospatial technology within a specific timeframe can yield remarkable results for retailers.<RiSingleQuotesR /></h2>
      <div className="d-flex intro_content gap-3">
        {retail.map((ret, index) => (
          <div key={index}>
            <Fade bottom>
              <div className="retail__box text-center p-3" style={{height: '19rem'}}>
                <h5>{ret.title}</h5>
                <p>{ret.desc}</p>
              </div>
            </Fade>
          </div>
        ))}
      </div>
    </>
  )
}

const footfall = [
  {
    title: 'Store locations:',
    desc: 'Geospatial technology helps retailers find ideal store locations by analyzing population density, competition, traffic, and customer demographics to identify high-potential areas for footfall and sales.'},
  {
    title: 'Competitive intelligence:',
    desc: "Geospatial tech helps retailers analyze competitors' locations, market share, and demographics to gain a competitive edge and devise unique strategies."
  },
  {
    title: 'Inventory planning and management:',
    desc: 'Geospatial technology helps retailers optimize inventory planning by analyzing sales data, customer demand, and geography, ensuring the right products are available at the right stores, reducing stock issues, and excess inventory.'
  },
  {
    title: 'Inventory and geospatial activity link:',
    desc: "Geospatial technology links inventory data with real-time spatial info for retailers. It improves store performance, efficiency, and customer satisfaction."
  },
  {
    title: 'Supply chain efficiency:',
    desc: "Geospatial technology boosts supply chain efficiency by providing real-time insights into inventory, transportation, and distribution. Retailers can make data-driven decisions, reduce costs, and improve customer satisfaction."
  },
  {
    title: 'Site selection/real estate acquisition:',
    desc: "Geospatial technology helps retailers choose ideal locations for new stores or franchises by considering market potential, competition, demographics, and proximity to target customers."
  },
]

const Footfall = () =>{
  return (
    <div>
      <h1 className='text-center mb-5'>The aspects in which geospatial technology impacts retail business</h1>
      <div className="row d-flex align-item-center flex-row">
      {/* <div className="section_seperator"></div> */}
        <div className="col-md-6">
            <div className="row ">
              <div className="col-md-12 img_section">
                <div className="row px-1 pb-2">
                  <Fade top>
                    <div style={{backgroundImage: `url(${Retail1})`, height: '53rem', backgroundSize : 'cover', backgroundPosition: 'center right'}}></div>
                  </Fade>
                </div>
              </div>
            </div>
        </div>
        <div className="col-md-6">
          <div className="row"></div>
            {/* <h1><RiSingleQuotesL />Location data: Guiding retailers to success through footfall insights, optimized stores, and targeted marketing.<RiSingleQuotesR /></h1> */}
          <div className="footfall_section gap-3 mt-5">
          {footfall.map((foot, index) => (
            <div className="footfall_section-sub_analytics text-center" key={index}>
              <h5>{foot.title}</h5>
              <p>{foot.desc}</p>
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const dataRole = [
  {
    title: 'Boosting competitive intelligence',
    desc: 'New businesses in a neighborhood impact existing ones. Geospatial data monitors customer journeys, new entrants, and competitor performance for market understanding.',
    img: Retail2,
  },
  {
    title: 'Why are innovative companies investing in geospatial data?',
    desc: 'Geospatial data offers businesses crucial location intelligence for better decision-making, enabling them to identify ideal customers and optimize operations based on "where" insights.',
    img: Retail3,
  },
  {
    title: "Visitors insights and customer profiles",
    desc: "Geospatial solutions empower retailers to bridge physical and digital channels, providing personalized experiences and optimizing marketing strategies.",
    img: Retail4,
  },
  {
    title: 'Market research',
    desc: 'Geospatial technology aids market research, helping retailers identify potential markets, understand customer demands, and make informed decisions on products and pricing.',
    img: Retail5,
  },
]

const Geospatial = () =>{
  return(
    <>
      <div className="dataRole_section d-flex flex-column">
      {/* <h1 className='text-center mb-5'>How does VCIS use predictive geospatial analytics? </h1> */}
        {dataRole.map((data, index) => (
          <div key={index} className={`dataRole_section-part d-flex justify-content-between ${index % 2 === 0 ? 'flex-row-reverse' : ''}`}>
            {index % 2 === 0 ? <Fade left>{<div className="p-4 w-40 dataRole_section-part_img" style={{backgroundImage : `url(${data.img})`, flex : '2'}}></div>}</Fade> : <Fade right>{<div className="p-4 w-40 dataRole_section-part_img" style={{backgroundImage : `url(${data.img})`, flex : '2'}}></div>}</Fade>}
          <div className="seperator"></div>
          <div className="p-4 w-40 my-3" style={{ flex : '2'}}>
            <h4>{data.title}</h4>
            <h6>{data.desc}</h6>
          </div>
        </div>
        ))}
      </div>
    </>
  )
}


const locationBased = [
  {
    image: Retail9,
    title: "Paths to purchase:",
    desc: "Geospatial technology analyzes customer movement patterns, both online and offline, to identify the most common paths to purchase. This information helps retailers optimize store layouts, product placements, and marketing efforts to enhance customer experiences and drive sales",
  },
  {
    image: Retail9,
    title: "Demographics:",
    desc: "Geospatial technology provides valuable demographic insights by analyzing population density, income levels, age distribution, and other relevant data. It helps retailers understand their target audience and develop marketing strategies that align with the demographic profile of a specific area.",
    
  },
  {
    image: Retail9,
    title: "New store/franchise analysis:",
    desc: "Geospatial technology assists retailers in analyzing the feasibility of new store or franchise locations. It considers factors like market saturation, customer demographics, competition, and brand affinity, helping retailers identify areas with high growth potential.", 
  },
]

const LocAdv = () => {
  return (
    <>
    {/* <div className="section_seperator"></div> */}
      {/* <h1 className='text-center mb-5'>Location-based Advertising and Marketing</h1> */}
      <div className="d-flex flex-row gap-3">
        {locationBased.map((loc, index) => (
          <div key={index} style={{width: '33%'}}>
            <div className="loc_section card" style={{ height: '30rem'}}>
              <div className="card-header">
                <Fade top>
                  <div className="loc_section-img" style={{backgroundImage : `url(${loc.image})`}}></div>
                </Fade>
              </div>
              <Fade top>
                <div className="card-body text-center">
                  <h4 className='mt-3 mb-4'>{loc.title}</h4>
                  <h6>{loc.desc}</h6>
                </div>
              </Fade>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}


const ecommerce = [
  {
    icon: <GrTechnology />,
    title: "Work Locations:",
    desc: "Geospatial tech identifies workplace concentrations, aiding retailers in targeted marketing during working hours.",
  },
  {
    icon: <HiOutlineUsers />,
    title: "Logistics Planning:",
    desc: "Geospatial tech optimizes logistics with efficient routes and reduced costs based on traffic analysis, store locations.",
  },
  {
    icon: <GrGlobe />,
    title: "Home Locations:",
    desc: "Geospatial tech analyzes residential areas for targeted marketing and personalized offerings based on  home locations.",
  },
  {
    icon: <GrOptimize />,
    title: "Overtime Visuals:",
    desc: "Geospatial technology helps retailers track footfall and sales trends to plan promotions and staffing based on historical data.",
  },
]

const Ecommerce = () => {
  return (
    <>
        <div className="">
          <div>
            <div className="row ecommerce_row ecommerce_row-sec">
              {ecommerce.map((ecom, index) => (
                <div className="w-23 ecommerce_section d-flex flex-column justify-content-center align-items-center text-center gap-3" key={index}>
                  <h1>{ecom.icon}</h1>
                  <h5>{ecom.title}</h5>
                  <p>{ecom.desc}</p>
                </div>
              ))}
            </div>
          </div>
      </div>
    </>
  )
}

const supplyChain = [
  {
    title: 'Brand/location affinity',
    desc: 'Geospatial tech helps retailers understand brand popularity in different areas, aiding decisions on placements and expansions.',
    img: Retail9,
  },
  {
    title: 'Visits by the day of the week',
    desc: 'Geospatial technology analyzes footfall data to optimize staffing, promotions, and inventory based on customer visitation patterns on different days.',
    img: Retail9,
  },
  {
    title: 'Visits by time of the day',
    desc: 'Geospatial technology helps retailers analyze footfall patterns at different times of the day, optimizing staffing and marketing during peak hours.',
    img: Retail9,
  },
  {
    title: 'Dwell time',
    desc: 'Geospatial technology helps retailers analyze customer dwell time, improving store layouts and customer experiences.',
    img: Retail9,
  },
]

const Chain = () =>{
  return(
    <>
      <div className="dataRole_section d-flex flex-column">
      {/* <h1 className='text-center mb-5'>Supply-chain optimization</h1> */}
        {supplyChain.map((supply, index) => (
          <div key={index} className={`dataRole_section-part d-flex justify-content-between ${index % 2 === 0 ? 'flex-row-reverse' : ''}`}>
            {index % 2 === 0 ? <Fade left>{<div className="p-4 w-40 dataRole_section-part_img" style={{backgroundImage : `url(${supply.img})`, flex : '2'}}></div>}</Fade> : <Fade right>{<div className="p-4 w-40 dataRole_section-part_img" style={{backgroundImage : `url(${supply.img})`, flex : '2'}}></div>}</Fade>}
          <div className="seperator"></div>
          <div className="p-4 w-40 my-3" style={{ flex : '2'}}>
            <h4>{supply.title}</h4>
            <h6>{supply.desc}</h6>
          </div>
        </div>
        ))}
      </div>
    </>
  )
}

const TitleBg = () => {
  return (
    <>
      Driving Omnichannel Retail Success
    </>
  )
}


const analytics = [
  {
    img: Retail6,
    title: "Trade area analysis:",
    desc: "Geospatial technology analyzes trade areas, helping retailers assess market potential, competition, and optimize store locations.",
  },
  {
    img: Retail7,
    title: "Cross-shopping visitations:",
    desc: "Geospatial technology analyzes trade areas, helping retailers assess market potential, competition, and optimize store locations.",
  },
  {
    img: Retail8,
    title: "Visitation heatmap:",
    desc: "Geospatial technology and heatmaps help retailers make informed decisions on marketing, store expansions, and resource allocation based on customer visitation patterns.",
  },
  {
    img: Retail6,
    title: "Growing footfall:",
    desc: "Geospatial tech helps retailers track footfall trends, optimize marketing, and enhance customer experiences.",
  },
  {
    img: Retail7,
    title: "Staffing improvement:",
    desc: "Geospatial technology optimizes retail staffing by analyzing footfall and demand data, enhancing efficiency and customer service.",
  },
  {
    img: Retail8,
    title: "Geospatial Tech Empowers Retailers:",
    desc: "Geospatial technology empowers retailers with data-driven insights, enhancing customer experiences and gaining a competitive edge in the industry.",
  },
]

const AnalyticsT = () => {
  return (
    <>
        <div className="">
          <div>
            <div className="row ecommerce_row" >
              {analytics.map((analyt, index) => (
                <div className="ecommerce_section p-0 d-flex flex-column align-items-center text-center gap-3" style={{height: '28rem', width: '31%', borderRadius: '15px 15px 0 0', borderTop: 'none'}} key={index}>
                  <Zoom top>
                    <div style={{overflow: 'hidden', height: '11rem', borderRadius: '15px 15px 0 0'}}>
                      <img src={analyt.img} className='w-100' alt=""/>
                    </div>
                  </Zoom>
                  <Fade bottom>
                    <div className='p-3'>
                      <h5>{analyt.title}</h5>
                      <p>{analyt.desc}</p>
                    </div>
                  </Fade>
                </div>
              ))}
            </div>
          </div>
      </div>
    </>
  )
}


const RetailAnalytics = () => { 


  return (
    <>
    <Banner
      pos="30%"
      title= {<TitleBg />}
      desc=""
      img = {BannerImg1}
      link = {retailDoc2}
    />

    {/* Introduction */}
      <div className="retail__content-all container mt-5">
        <Intro />
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row">
          <Geospatial />
        </div>
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row">
          <Footfall />
        </div>
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row mb-5">
          <LocAdv />
        </div>
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row mb-5">
          <Ecommerce />
        </div>
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row mb-5">
          <Chain />
        </div>
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row mb-5">
          <AnalyticsT />
        </div>
      </div>
    </>
  )
}

export default RetailAnalytics