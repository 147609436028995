const enterprise = [
    {
        title: "Overview",
        desc: "In'Incident management is a solution used to facilitate the activities of an organization in undertaking identification, analysis, and correction of hazards in addition to prevention of a re-occurrence. It is an integrated and comprehensive reporting system with the capabilities of recording, reviewing, auditing and monitor incidents effectively. In'Enterprise Incident Management can help secure your systems and resolving incidents of all kinds quickly and efficiently as well as limiting your exposure to threats and effectively responding to disturbances.",
        list: [],
    },
    {
        title: "Key Benefits",
        desc: '',
        list:
        [
            "Analyze information in context of ongoing investigation",
            "Build a consolidated repository of all investigations",
            "Improve investigation service level agreements (SLA)",
            "Address financial crimes and ensure compliance",
            "Reduce regulatory risk by achieving cross-channel coverage",
        ]
    },
    {
        title: "",
        desc: '',
        list: []
    },
]

export default enterprise