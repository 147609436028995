import React from 'react'
import servicesArray from './servicesArray'
import './Services.css'
import { Link } from 'react-router-dom'
import Intro from '../Intro/Intro'

const Services = () => {
  return (      
    <>
        <Intro title='Services'/>
        <div className='d-flex flex-column justify-content-start gap-3 w-100'>
            {servicesArray.map((service, index) => (
                <div key={index} className={`w-100 d-flex  flex-column ${index %2 === 0 ? 'flex-lg-row' : 'flex-lg-row-reverse'}`}>
                    <div className='service_img col-lg-6 col-md-12' style={{backgroundImage: `url(${service.img})`}}></div>
                    <div className={`service-text_content col-lg-6 col-md-12 d-flex flex-column justify-content-center gap-3 p-5 w-40`}>
                        <h1>{service.title}</h1>
                        <h5>{service.desc}</h5>
                    <Link className='btn btn-primary'>Read More</Link>
                    </div>
                </div>
            ))}
        </div>
    </>
  )
}

export default Services