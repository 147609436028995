
import modern2 from '../../img/management/modern3.jpg'
import modern3 from '../../img/management/modern4.jpg'
import modern4 from '../../img/management/modern5.jpg'
import modern5 from '../../img/management/modern6.jpg'

const RiskAndThreatManagement=[
    
    {
        title:'What is VCIS ? In’DataCrowd Platform',
        desc:'Government enforcement agencies suffer from losing billions of dollars due to a wasteful process that evaluates information and abuse cases. This is due to uncertain data collected from diverse sources, investigations that end in dead ends and public or private data sets that mislead, as well as the lack of threat prevention indicators. To address this problem, government agencies tasked with criminal fighting and law enforcement protection are increasingly turning to geospatial information systems platforms like. DataCrowd to integrate relevant information into a logical package and produce a conclusion, estimate, or forecast about a criminal phenomenon by using a scientific approach to problem-solving. DataCrowd accurately identifies location patterns, explores locations, matches problem areas, and improves organizational efficiency with crowd files and cases, predicting smart patterns, behavioral models, and even monitoring potential suspects. In its purest sense, DataCrowd is the Crowd Location platform.',
        img: modern2 ,
    },
    {
        title:'',
        desc:'DataCrowd is a synergistic location analysis-based product designed to provide meaningful and trustworthy actionable knowledge to law enforcement agencies, drug enforcement units, the national army, marine corps, navy ops, air force, airport security, local and international police departments, border security, and other decision-makers about complex criminality, drug trafficking, criminal groups, terrorist organizations and terrorists, kidnappers, fugitives, criminal enterprises, and criminal extremists.Government agencies have demonstrated success in using geospatial technologies to reveal blind spots and better fulfill their missions. Maps and data analytics tools like DataCrowd enable these organizations to aggregate location information from both inside and outside their organizations, domestically and internationally, visualize the information in a meaningful way, model predictions, and stay one step ahead of criminals, incidents, frauds, and threats.',
        img: modern3 ,
    },
    {
        title:'',
        desc:'One of the most exciting features of DataCrowd technology is its flexibility, dynamicity, and performance when working with BIG DATA. It can process over 20 billion records and locations on a daily basis from over 5000 data sources and providers. Investigators, auditors, and analysts can view location-based data historically or in real-time. DataCrowd tools, services, and features can be deployed in the cloud, on-premises, or out in the field while keeping data safe. The system handles highly sensitive data sets and ensures their safety and high authentication accessibility. Individuals throughout a criminal-fighting government organization can access highly advanced biometric security measures through DataCrowd. It can also be used in combination with agencies current technologies to enhance existing workflows and inform decision-making at all levels within an agency.',
        img: modern4 ,
    },
    {
        title:'',
        desc:'DataCrowd is particularly innovative because it improves communication between analysts and individuals who may not be data experts. This technology makes information iterative and understandable, creating data stories that detect the unseen and reveal the invisible to government agencies, investigators, and threat prevention analysts.DataCrowd is designed to be user-friendly and intuitive, helping investigators quickly access the information they need to serve the cases they are working on. The tool is very efficient and provides instant results, making it accessible to everyone.',
        img: modern5 ,
    },
]

export default RiskAndThreatManagement