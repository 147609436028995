

const InCampaignManagement = [
    {
      title: "Overview",
      desc:
      (
        <>
            The ongoing regulatory changes that occurred to organizations all over the world due to the economic crisis, have caused them to stop being sufficiently effective regarding competition.
            <br />
            In order to be able to survive in such a challenging environemnt, companies will have to comply with these regulations whilst staying cost effective and provide the market with competitive products.
            <br />
            This is where our application plays a significant role, in organizing and optimizing each and every detail of the marketing campaign, from the financial aspect to resource allocation and objective alignment.
            <br />
            in’Campaign Management helps your company achieve several goals that are vital for the success of any marketing plan set in motion.
            <br />
        </>
      ),
      list: [''],
    },
    {
      title: "Goals",
      desc: '',
      list: [
      'Setting the time frame of the marketing campaigns',
      'Selecting the key actors and elements of the campaign such as products and target customers',
      'Setting objectives from both financial and volume side to have a clear idea on what to expect out of this campaign and how to reach the goal',
      'Close monitoring of the campaign’s progress with the option to edit and alter any element in order to face a certain recessive activity.',
      'Allocating tasks to individuals or departments with the ability to track the progress of each task, ensuring all deadlines are respected',
      'Creating promotional offers where several commercial combinations can be created thus improving the campaign results through attracting customers to specific products.',
      'Comparing actual plans to past plans to ensure the best results.',
      ],
    },
    {
      title: "Obstacles",
      desc: '',
      list: [
        'High promotional costs',
        'Lack of a consolidated view of all planned events',
        'Difficult communication process across all platforms',
        'Lack of compliance with retro planning deadline and need to re-work',
        'Not taking full advantage of vendor collaboration',
        'Arising price conflicts',
      ],
    },
    {
        title: "Key Benefits",
        desc:
        <>
            <ul>
                <li>
                Maximize overall promotional effectiveness
                    <ul>
                        <li>Develop and maintain top down and bottom up plans for all promotional events</li>
                        <li>Plan and control the execution of all promotional types</li>
                        <li>Maintain a comprehensive database of causal factors and transaction history for use in planning, analysis, simulation modeling and reporting</li>
                        <li>Provide visibility on product promotional frequency</li>
                        <li>Integrate with ordering, supply chain, ad production, in-scope communication to create a unified end-to-end business processes</li>
                        <li>Track event profitability against plan throughout the year</li>
                        <li>Support continuous learning in event planning, product selection and promotional pricing</li>
                    </ul>
                </li>
                <li>
                Maximize event sales and profit impact
                    <ul>
                        <li>Simulate via in’Analytics event performance in real-time to support cross-functional planning</li>
                        <li>Base event plans and budgets on up-sell and cross-sell opportunities.</li>
                        <li>Simulate the impact of causal factors on event profitability, such as mix of products, range, price, media, display, branch placement, price elasticity.</li>
                        <li>Simulate event and performance based on sales lift factors and other key risk and performance indicators (KPIs, KRIs, Ratios …)</li>
                    </ul>
                </li>
                <li>
                Reduce promotion operating costs and cycle time up to 50%
                    <ul>
                        <li>Provide a single version of the truth for promotion and media planning, analysis, production, execution and reporting</li>
                        <li>Provide a consolidated view of all promo events planned</li>
                        <li>Facilitate communication across bank’s departments and with market partners</li>
                        <li>Improve compliance with planning deadlines and reduce re-work</li>
                        <li>Improve productivity of support staff required to execute promotions</li>
                        <li>Automate supplier collaboration in planning and executing promotions</li>
                        <li>Manage price conflicts that arise at the scope level.</li>
                    </ul>
                </li>
            </ul>
        </>,
        list: [''],
      },
      {
        title: "Key Features",
        desc:
        <>
            <ul>
                <li>
                in’Campaign Management- Promo Planner
                    <ul>
                        <li>Manage the annual calendar for national or scope based promotions</li>
                        <li>Define various targets and media budget for each event</li>
                        <li>Manage exceptions by branch, department, region …</li>
                        <li>Define tasks and reverse planning</li>
                    </ul>
                </li>
                <li>
                in’Campaign Management- Event Builder
                    <ul>
                        <li>Manage event scope and promotional attributes</li>
                        <li>Plan and define unit needs for events, including deals, price, forecast volume, media and promotional attributes</li>
                        <li>Calculate promotional and category impact of products within promotions, including what-if simulations</li>
                        <li>Provide reports for review and approval</li>
                    </ul>
                </li>
                <li>
                in’Campaign Management- Competitor Management
                    <ul>
                        <li>Manage and track competitors’ promotions</li>
                        <li>Integrate with external services providing competitors’ flyer data</li>
                    </ul>
                </li>
                <li>
                in’Campaign Management- Media Planner
                    <ul>
                        <li>Manage print media templates</li>
                        <li>Slot products and offers into each media and create ad versions based on exceptions</li>
                        <li>Proof media and edit online, track changes and validation cycles</li>
                        <li>Send Quark Express XML file for desktop publishing</li>
                        <li>Integrate changes from DTP to update the promotional database</li>
                    </ul>
                </li>
                <li>
                in’Campaign Management- Reporting
                    <ul>
                        <li>Monitor promotions across their lifecycle</li>
                        <li>Event tracking at product/scope/lifetime level of causal factors</li>
                        <li>Produce reports and promotional analysis</li>
                    </ul>
                </li>
            </ul>
        </>,
        list: [''],
      },
    
  ];
  
  export default InCampaignManagement;
  