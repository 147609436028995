import React from 'react'
import Carousel from './Carousel'
import Intro from './Introduction'
import Sections from './Sections'
import Cards from './Cards.jsx'
import Paralax from './Paralax.jsx'
import Application from './Application.jsx'
import dashboardImg from '../../img/dashboardParalax.jpg'
import paralax2 from '../../img/paralax2.jpg'



const Healthcare = () => {
  return (
    <>
        <Carousel />
        <Intro />
        <Sections />
        <Cards />
        <Paralax img={dashboardImg} title="Powerful Dashboard Visualizations" link='https://banking.valoores.com/portalbi.php' button='Learn More' laptop= 'true' height='50rem' top = '0%'/>
        <Application />
        <Paralax img={paralax2} title="You have the VALUE, we bring you the ADDED VALUE. it's VALOORES!" link='https://banking.valoores.com/portalbi.php' button='Contact Us' laptop= 'false' height='30rem' top='30%'/>
    </>
  )
}

export default Healthcare