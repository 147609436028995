import React from 'react';
import Solution from '../../../../img/solution.png'
import AmlIntro from './amlIntro'
import AmlCase from './amlCaseList'
import ParalaxImg from '../../../../img/transImg.jpg'


  const Aml = [
      {
        title: "in'AML",
        overview: (
          <>
            <p>
              Financial institutions are working hard to fight financial crime and bank fraud driven by demands to protect their assets, as well as by regulatory compliance. One area of specific focus is that of Anti-Money Laundering (AML). For many institutions, there are several challenges to creating a sustainable AML organization - one that can respond to regulatory reporting mandates and provide information to support “business as usual” demands - while also finding, developing and retaining the talent needed to accomplish these critical activities.
            </p>
            <p>
              While standardization, centralization, and optimization may be ultimate objectives, individual opportunities should be identified, converted, and used as a foundation on which to build. Activities such as compliance case management or analytics reporting around risk are often a prime target to begin the journey towards standardization and/or centralization. Some banks and financial institutions may want to look within a specific line of business, while others may want to consider a broader range of activities; the key is to start with a specific focus and develop a methodology that works and that can be leveraged.
            </p>
            <p>
              To accomplish this, firms need to get three things right:
            </p>
            <ol>
            {AmlIntro.map((item, index) => (
              <>
              <div key={index}>
                <li className='mb-2'><strong>{item.title}</strong></li>
                <ol>
                  {item.subTitle[0].title.map((subItem, subIndex) => (
                    <li key={subIndex} className='mb-3' style={{listStyle: 'lower-latin'}}>
                      {typeof subItem === 'string' ? (
                        <span>{subItem}</span>
                      ) : (
                        <div>
                          {subItem.title && <>{subItem.title}</>}
                          {Array.isArray(subItem.subTitle) && (
                            <ol>
                              {subItem.subTitle.map((subSubItem, subSubIndex) => (
                                <li key={subSubIndex} style={{listStyle: 'lower-roman'}}>{subSubItem}</li>
                              ))}
                            </ol>
                          )}
                        </div>
                      )}
                    </li>
                  ))}
                </ol>
              </div>
              <div className='mt-4'>
                {item.conclusion}
              </div>
              </>
            ))}
            </ol>
          </>
        ),
        transaction: [
          {
            title: 'Use Case 1 - Evolution in Transaction Monitoring',
            headline: 'The Problem',
            desc: "Current transaction monitoring technology relies on the development of declarative models/scenarios which can be applied to a sparse transaction data. Institutions typically maintain over 200 scenarios which accumulatively generate 99.9% false positive alerts which drive up the cost of compliance due to the requirement to investigate each alert. The problem with AML transaction monitoring is not that model development teams lack the skill or expertise to develop sophisticated models; but rather, the problem lies within the technical limitations levied against these teams due to rigid and outdated transaction monitoring tools.",
            paralax: 'no',
            img: 'ParalaxImg',
          },
          {
            title: '',
            headline: 'The Big Data Solution',
            desc: 
            (
              <>
                The Big Data Solution: With Big Data technologies, model development teams will have much more sophisticated capabilities available to them to construct AML transaction monitoring scenarios. Using big data technology for transaction monitoring provides the following advantages over traditional methods:
                <br /><br />
                Ability to create more sophisticated models without limitations of legacy technology- complete freedom to develop complex detection models without having to worry about your transaction monitoring tool's ability to handle the code.
                <br /><br />
                Ability to leverage additional transaction data and meta data- No longer will you restricted to using only a subset of fields from your transaction source data. Big Data technologies will allow you to leverage structured, semi-structured, and unstructured data in the development of your models. This becomes particularly helpful when processing wire transfer data which may follow inconsistent formats and contain semi-structured data.
                <br /><br />
                Apply Machine Learning techniques to self-tune models over time- Loop investigation disposition data back into the models to provide supervised training and self-tuning of detection models. Leverage unsupervised machine learning techniques which will help discover new attributes which may be strongly correlated to AML risk.
                <br /><br />
                Apply behavioral modeling analytics to support anomaly based alert generation- Create models which alert based on anomalous behavior. Create natural clusters of peer groups and detect activity which deviates from expected behavior.
                <br /><br />
                Ability to test and iterate on new models leveraging the power of distributed computing- Run and test models across the data with much reduced processing time.
              </>
              ),
              paralax: 'yes',
              img: ParalaxImg,
          },
        ],
        reporting: [
          {
            title: 'Use Case 2 - Enhanced Reporting Capabilities',
            headline: 'The Problem',
            desc: "At this point, most institutions have implemented large scale enterprise data warehouses which they use as a central archive for bank data. These data warehouses have come with multi-million dollar investments to implement and are also expensive to maintain. Additionally, these environments rely on rigid data structures which becomes a blocker in being able to be nimble and responsive to analytics needs. Finally, these environments make it more expensive to bring in new data sources and integrate them with existing data.",
          },
          {
            title: '',
            headline: 'The Big Data Solution',
            desc: 
            (
              <>
                The Big Data approach is fundamentally different than the data warehouse approach. Rather than spending millions of dollars in extracting, transforming, and loading data into a predetermined schema, the best practice is to extract data in its native format and load the data into the “Data Lake” which resides in the Big Data environment. This approach substantially reduces the cost and time that it takes to bring in and combine large data sets into a common environment. With this paradigm, organizations are no longer penalized (by high price tags and long lead times) for bringing in “all of the data” and therefore do not need be overly careful about which data they bring over and which data they leave behind. Additionally, storing data in the Big Data environment is relatively less expensive than traditional data stores as Big Data typically runs on more commodity hardware.
                <br /><br />
                Once the data resides in the Data Lake, data scientists can execute any transformations that they require at the time that they go to query the data. This “query on read” approach provides much more flexibility to run reports, analytics, and gain insights from your AML data. Whereas with the traditional data storage and “query on write” approach assumes that you know all of the questions that you will ever want to ask of your data, the “query on read” / Data Lake approach assumes that you will always have new questions and provides a platform on which you can quickly create question focused datasets.

                <br /><br />
                Let's consider a practical example: Regulators usually ask some standard questions which can be answered by traditional reports that you have generated. Sometimes however they ask you a very specific question which is not satisfied by one of your current reports. Let's analyze how this scenario would play out in with the traditional approach to data management vs. the Big Data / Data Lake approach.
                <br /><br />
                Traditional Approach: As you analyze the data that you have available to you in your standardized data table, you realize that you are missing a key field that you had not previously thought you would have needed from the source system. In this case you put in a request to your technology team which then has to go back to the source systems, identify the corresponding fields, write new extraction scripts to bring the new data into your data warehouse, write transform scripts to get it into the proper format, and then write new queries to support the regulator's question. While your institution may have streamlined processes and procedures of handling these scenarios, often they result in long delays, increased costs, and a lot of unnecessary paperwork.
                <br /><br />
                Big Data Approach: In this approach, you would determine the data fields that will be required to answer the regulator's question and then go to your data lake to locate the corresponding fields. Once you identify these fields, you will write a query which retrieves the data and transforms it into the format that you need in order to answer the regulator's question. As you can see from this example, the value in the Data Lake approach is that you always have access to the data that you might need without having to return to the source.
              </>
              ),
          },
        ],
        exploration: [
          {
            title: 'Use Case 3 - Platform for Data Exploration and Intelligence Analysis',
            headline: 'The Problem',
            desc: "Combating financial crime oftentimes involves searching for patterns, trends, or anomalies across millions of customers and/or billions of transactions. The questions that you are seeking answers for across this data set change frequently. This week you may be looking for signals of human trafficking among your customers and next week you may be looking to find customers who are truly money service businesses. Not only do these questions change frequently, but oftentimes the questions and answers are complex and if limited to traditional data exploration tools, relational databases, and structured data, these answers may be impossible to find.",
          },
          {
            title: '',
            headline: 'The Big Data Solution',
            desc: 
            (
              <>
                The goal of implementing a Big Data platform is to provide flexibility and robust tools whereupon AML teams can openly explore the data to find the answers that they are looking for. Big Data not only allows AML programs to aggregate large volumes of data from a large variety of data sources, but also provides a large ecosystem of tools which can be leveraged based on the question that you are trying to answer. Things that are very difficult (if not impossible) to do using traditional relational databases and tools such as natural language processing, similarity clustering across massive data sets, and machine learning techniques provide your AML analytics team with a new toolbox for solving these problems.
                <br /><br />
                A few examples of the type of data exploration that you may be enabled to do using Big Data include:
                <br /><br />
                <ul>
                  {Array.isArray(AmlCase) && AmlCase.map((explo, exploIndex) => (
                    <div key={exploIndex}>
                      {Array.isArray(explo.exploration) && explo.exploration.map((amlExplo, amlExploIndex) => (
                        <li key={amlExploIndex}>{amlExplo}</li>
                      ))}
                    </div>
                  ))}
                </ul>
              </>
              ),
          },
        ],
        processing: [
          {
            title: 'Use Case 4 - Large Scale Processing of New & Unstructured Data Sources',
            headline: 'The Problem',
            desc: "Much of Financial Institutions are looking for when it comes to AML risks cannot be found in conventional If/Then statements running against transaction data. Many times what we want to know is whether or not one of our customers is a subject to some kind of negative news. Other times we may be wanting to understand a trend in the narratives that our AML analyst teams are writing as an output of their investigations; perhaps there is a trend of MSBs disguising themselves as retail stores or marijuana dispensaries disguising themselves as pharmacies. These questions cannot be answered strictly by looking at the structured data that we have in our transaction monitoring tools and therefore we ask humans to collect the data and analyze for these trends.",
          },
          {
            title: '',
            headline: 'The Big Data Solution',
            desc: 
            (
              <>
                One of the largest benefits of using Big Data technology is the capability to process unstructured data. With Big Data tools, the machine can store, process, and extract intelligence from large blocks of text. This is ideal for AML as we can use these tools to:
                <br /><br />
                <ul>
                  {Array.isArray(AmlCase) && AmlCase.map((aml, processIndex) => (
                    <div key={processIndex}>
                      {Array.isArray(aml.processing) && aml.processing.map((amlProcess, amlProcessIndex) => (
                        <li key={amlProcessIndex}>{amlProcess}</li>
                      ))}
                    </div>
                  ))}
                </ul>
              </>
              ),
          },
        ],
        management: [
          {
            title: 'Use Case 5 - Next Generation Case Management',
            headline: 'The Problem',
            desc: "Case Management tools are at the center of every AML program and serve as a system of record for all of the AML investigations including transaction monitoring, high risk customer, and watchlist management investigations. Unfortunately, most case management systems fail to empower AML programs with added efficiency but rather become cumbersome systems that analysts have to work around in order to do their job. Most analysts find ways of minimizing the time that they spend in their case management system. They open a case assigned to them in the system, quickly toggle out of the application to conduct their investigation, and then return to the system at the very end to upload the output of their investigation and mark the investigation complete. As institutions continue to load their systems with more and more records and data, the systems slow down and become even more unusable.",
          },
          {
            title: '',
            headline: 'The Big Data Solution',
            desc: 
            (
              <>
                The next generation of case management solutions, based on big data technologies, will unlock automation and efficiency in AML investigations. These next-generation systems will take advantage of Big Data and other contemporary technologies to:
                <br /><br />
                <ul>
                  {Array.isArray(AmlCase) && AmlCase.map((aml, mgmtIndex) => (
                    <div key={mgmtIndex}>
                      {Array.isArray(aml.management) && aml.management.map((amlmanagement, amlmanagementIndex) => (
                        <li key={amlmanagementIndex}>{amlmanagement}</li>
                      ))}
                    </div>
                  ))}
                </ul>
              </>
              ),
          },
        ],
        risk: [
          {
            title: 'VALOORES Compliance & Financial Crime Mitigation - Risk-Based Approach',
            headline: '',
            desc: 
            (
              <>
                VALOORES believes that managing Risk & Compliance is the supreme discipline in banking. Assessing the Financial Crime risk is another dimension, as it is a “consequential” risk. Financial Crime risks may only become evident when a customer begins transacting through the account.
                <br /><br />
                Monitoring of customer transactions is a fundamental component of the Risk-Based Approach (RBA).
                <br /><br />
                Financial Crime risks reflect a Financial Institution's external and internal environments. Financial Crime Risk Assessment best practices include quantitative and qualitative methodologies. FATF, EU AML directives, US BSA/AML and many other national legislations put the RBA at the center of Anti Money Laundering and Combating the Financing of Terrorism regimes.
                <br /><br />
                Money Laundering risks are generally understood to include terrorism financing (TF), bribery & corruption, alongside other serious offences and types of Financial Crime.
                <br /><br />
                VALOORES pulls in data, such as the Customer's Profile to calibrate the risk scoring.
                <br /><br />
                The VALOORES Dynamic RBA is in line with the regulatory guidelines on sound risk assessment and RBA, alongside best practices accumulated from its proliferating implementations around the globe.
                <br /><br />
                VALOORES RBA is seamlessly integrated within its KYC, CDD, & EDD, all the way through its in'FC-AML's embedded watch list & PEP screening, Profiling, Monitoring, and Suspicious Activity Prevention life cycle. Risk categories though, are mapped to a Risk Matrix, based on a Dynamic and Flexible definition engine, pulling together a myriad of risk attributes (factors). The VALOORES RBA is equipped with a progressive Configuration Panel, an Exception Management framework, a Bulk Upload capacity to sustain the bank's best practices, alongside an Analytics based Customer Risk Calculation Engine.
                <br /><br />
                Below is an example around the risk categories and risk factors to score customer and transaction risk.
              </>
            ),
            body: 
            [
              {
                title: 'A - RBA Matrix for “Individual” Accounts (as the highest score applies for joint accounts)',
                headline:
                [
                  {
                    title: 'Country Risk',
                    list: [
                      'Country of Birth',
                      'Main Nationality - Other Nationality',
                      'Primary Resident Country - Secondary Resident Country',
                      'Country of Work',
                    ],
                  },
                  {
                    title: 'Business Risk',
                    list: [
                      'Screening / Name Matching',
                      'Economic Sector',
                      'Activity',
                    ],
                  },
                  {
                    title: 'Account Turnover',
                    list: [
                      'Annual income',
                      'Residency / Occupation in a different Geographical area than the Branch Location',
                      'E-banking activities',
                      'Proxy, Guardian on account',
                      'BRO different than account holder',
                      'SOF not from account holder',
                      'Expected Account T.O.',
                      'Total Activity as a percentage of declared activity',
                      'Total Cash as a percentage of total activity',
                    ],
                  },
                  {
                    title: 'Products and Services',
                    list: [
                      'Products falling within multiple categories',
                    ],
                  },
                ]
              },
              {
                title: 'B - RBA Matrix for “Entities” Accounts',
                headline:
                [
                  {
                    title: 'Country Risk',
                    list: [
                      'Country of Registration',
                      'Country of Operations',
                      'Country of Major Suppliers',
                      'Country of Major Customers',
                    ],
                  },
                  {
                    title: 'Business Risk',
                    list: [
                      'Screening / Name Matching',
                      'Entity Type',
                      'Activity Sector',
                    ],
                  },
                  {
                    title: 'Account Turnover',
                    list: [
                      'Annual income',
                      'Residency / Occupation in a different Geographical area than branch location',
                      'E-banking activities',
                      'Proxy on Account',
                      'BRO different than account holder',
                      'SOF not from account holder',
                      'Expected Account T.O.',
                      'Total Activity as a percentage of declared activity',
                      'Total Cash as a percentage of total activity',
                    ],
                  },
                  {
                    title: 'Products and Services',
                    list: [
                      'Products falling within multiple categories',
                    ],
                  },
                ]
              }
            ],
          },
        ],
        features: 
        [
          'Efficiently detect, investigate, and report suspected money laundering',
          'Provide regulators with a comprehensive view of financial activity',
          'Reduce compliance costs through streamlined investigations',
          'Address multi-national regulations, guidelines, and best practices',
          'Aggregate investigation data',
          'Link/Network Analysis',
          'Entity Resolution / Single View of the Customer',
        ],
        benefits: 
        [
          'Enterprise-wide risk based monitoring, investigations, and reporting for suspicious activities',
          'Industry-leading, comprehensive transparent behavior detection library',
          'Robust case management streamlines analysis, resolution and regulatory reporting',
          'Constant investment in innovation to address regulatory changes and IT challenges',
          'Evolution in Transaction Monitoring',
          'Enhanced Reporting Capabilities',
          'Platform for Data Exploration and Intelligence',
          'Processing of New & Unstructured Data Sources',
          'Next Generation Case Management Solutions',
        ],
        
        solution: Solution
      }
    ];
    
    export default Aml;
    