import React from 'react'
import './Intro.css'
import { Fade } from 'react-awesome-reveal'

const Intro = () => {
  return (
    <div className='mt-5 container intro_container1'>
      <Fade direction='up'>
        <h1 className='font--poppins' style={{color: '#001f3d'}}>Solutions That Drive Efficiency, Growth, and Success</h1>
      </Fade>
    </div>
  )
}

export default Intro