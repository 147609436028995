import React from 'react'
import './Sections.css'
import SectionsArray from './sectionsArray'
import valuesArray from './valuesArray'
import Overview3 from '../../../assets/img/overview3.jpg'

const Sections = () => {
  return (
    <>
        <div style={{backgroundColor: '#091942'}}>
            {SectionsArray.map((sections, index) => (
                <div className={`d-flex ${index%2 === 0 ? 'flex-row' : 'flex-row-reverse'}`} key={index}>
                    <div className="overview_text d-flex flex-column align-items-start justify-content-center p-5 w-50">
                        <h1 className='text-white my-5'>{sections.title}</h1>
                        <h4 className='text-white'>{sections.desc}</h4>
                    </div>
                    <div className="overview_img w-50">
                        <div style={{backgroundImage: `url(${sections.img})`, backgroundPosition: 'center center', backgroundSize: 'cover', height: '30rem'}} className='w-100' ></div>
                    </div>
                </div>
            ))}
        </div>
        <div className='mt-5'>
            <h1 className='text-uppercase text-center mb-5'>Our Values</h1>
            <div className='d-flex flex-row flex-wrap px-5'>
                {valuesArray.map((value, index) => (
                     <div key={index} className='col-lg-3 col-md-3 d-flex flex-row align-items-start justify-content-md-start justify-content-center gap-3 mb-3 '>
                        <h1 className='border-overview-seperator font--alatsi' style={{ color: '#7B86A3' }}>{index + 1}</h1>
                        <div className="p-2">
                            <h4 className='w-75 text-start mb-3' style={{color: '#0D1450'}}>{value.title}</h4>
                            <p>{value.desc}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div className=''>
            <div className='d-flex flex-row align-items-start justify-content-start ceo_section' style={{backgroundImage: `url(${Overview3})`, height: '600px', backgroundPosition: 'center center', backgroundSize: 'cover',  backgroundRepeat: 'no-repeat'}}>
                <div className="overlay-overview"></div>
                <div className='overview_text-content' style={{padding: '5rem'}}>
                    <h1>Word from the CEO & Founder:</h1>
                </div>
            </div>
        </div>
        <div className='row py-5' style={{padding: '0 5rem', background: '#CAF9FF70', lineHeight: '1.8rem', color: '#0F1653'}}>
            <div className="col-md-6 part1_ceo">
                <p className='font--poppins text-justify' style={{textAlign: 'justify'}}>
                    <span className='fw-bold' style={{fontSize: '2rem'}}>“VALOORES</span>  is a vanguard in Geospatial artificial intelligence systems, specializing in advanced big data analytics for retail, finance, government, and law enforcement sectors. With over 35 years of expertise, we leverage cutting-edge AI to transform complex data into actionable intelligence.
                </p>
                <p className='font--poppins text-justify'>
                    Our core mission is to enhance client productivity through optimized, AI-driven software solutions. Our elite team of engineers develops proprietary systems that provide unpre
                    cedented analytical capabilities, empowering organizations to maintain a competitive edge in an increasingly data-driven landscape.
                </p>
            </div>
            <div className="col-md-6 d-flex flex-column align-items-center justify-content-center part2_ceo">
                <p className='font--poppins' style={{textAlign: 'justify'}} > While proud of our legacy, Valoores remains focused on the future. We are committed to pushing the boundaries of geospatial intelligence, continuously evolving our technologies to meet emerging market demands and drive innovation across industries. 
                </p>
                <p className='font--poppins' style={{textAlign: 'justify'}}>
                    By seamlessly integrating big data analytics with AI, Valoores enables clients to unlock insights previously thought unattainable, fostering informed decision-making and operational excellence in an ever-changing global environment."
                </p>
            </div>
        </div>
    </>
  )
}

export default Sections