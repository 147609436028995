const liquidity = [
    {
        title: "Overview",
        list: [
            'Pre-configured regulatory scenarios and runs',
            'Extensive set of business assumptions for behavior modeling',
            'Enterprise-wide, consistent stress testing',
            'Parameterized user interface with workflows and versioning',
            'Comprehensive reporting with extensive drill-through',
        ],
    },
    {
        title: "Key Benefits",
        list:
        [
            "Quick product implementation to meet regulatory guidelines",
            "Ready-to-use regulatory scenarios and pre-packaged methods",
            "Defines and applies counterbalancing strategies",
            "Helps achieve quick turnaround times for regulatory reporting compliance",
            "Improves risk reporting and addresses demands of multiple stakeholders",
        ]
    },
]

export default liquidity