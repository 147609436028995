import React from 'react'
import testimonialImg from '../.././img/multimedia/testimonialImg.png'
import testimonialArray from './TestimonialsArray'

const Testimonials = () => {
  return (
    <div className='mt-5'>
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <h4 className='headerColor'>Testimonials</h4>
                    <div className='d-flex flex-column gap-3'>
                        {testimonialArray.map((testimonial, index) => (
                            <div key={index}>
                                <p className='m-0'>{testimonial.paragraph}</p>
                                <h6 className='fw-bold'>{testimonial.title}</h6>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center">
                    <img src={testimonialImg} className='w-75' alt="" />
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Testimonials