import Financial from '../img/carousel/financial.jpg';
import Retail from '../img/carousel/retail.jpg';
import Law from '../img/carousel/law.jpg';
import Healthcare from '../img/carousel/healthcare.jpg';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { MdOutlineHealthAndSafety, MdLocalPolice } from 'react-icons/md';
import { BiMoneyWithdraw } from 'react-icons/bi';

const geoSlides = [
    {
      image: Retail,
      title: 'Retail',
      description: 'Geospatial data helps retailers to uncover the relationship between stores, products, demands, preferences, and customer types across various metrics that can impact the overall sales performance.',
      position: '40%',
      link: '/slide1',
      icon: <AiOutlineShoppingCart />,
    },
    // {
    //   image: Healthcare,
    //   title: 'Healthcare',
    //   description: 'Geospatial data is instrumental in tracking the illness, its spread, and its widespread impact. The technology is used to create visualizations to further decisions and actions.',
    //   position: '40%',
    //   link: '/slide2',
    //   icon: <MdOutlineHealthAndSafety />,
    // },
    // {
    //   image: Financial,
    //   title: 'Financial',
    //   description: 'Geospatial data is an asset to the financial industry, offering financial institutions a wealth of advantages allowing them to track their competition, estimate customer engagement and gain strategic benefits.',
    //   position: '30%',
    //   link: '/slide3',
    //   icon: <BiMoneyWithdraw />,
    // },
    // {
    //   image: Law,
    //   title: 'Government',
    //   description: 'Geospatial can help law enforcement officials visualize crime data, make more informed decisions, and accurately assess existing strategies.',
    //   position: '37%',
    //   link: '/slide3',
    //   icon: <MdLocalPolice />,
    // },
    // {
    //   image: Law,
    //   title: 'Geospatial Law Enforcement',
    //   description: 'Geospatial can help law enforcement officials visualize crime data, make more informed decisions, and accurately assess existing strategies.',
    //   position: '37%',
    //   link: '/slide3',
    //   icon: <MdLocalPolice />,
    // },
  ];

  export default geoSlides;