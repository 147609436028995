

const inAsset = [
  
    
  {
    title: "Overview",
    desc:
    (
      <>
      in'Capital Market offers solutions in middle-office and back-office functions enabling key capital market players like asset managers, custodians, broker/dealers, fund administrators to retain competitive advantage in a dynamic market environment and adapt to challenges posed by a constantly evolving industry.

      </>
    ),
    list: [''],
  },
  {
      title: "Key Benefits",
      desc: '',
      list: [
        '24/7 operations support',
        'Reduced TCO',
        'Real-time oversight of processes through Process Dashboards',
        'Emphasis on partnership approach',
        'Strong governance model in place',
      ],
    },
    {
      title: "Key features",
      desc:'',
      list:
      [
        'Exclusive focus on Financial Services Industry',
        'Complex process management capability',
        'Flexible engagement model allows outsourcing of some or all components of a process',
        'Capability to operate onshore near shore and offshore centers',
        'Platform-agnostic execution capabilities',
        'Best practices sharing across verticals',
      ],
    },
  
];

export default inAsset;
