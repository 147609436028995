import React from 'react'
import Fade from 'react-reveal/Fade';
import {RiSingleQuotesL, RiSingleQuotesR} from 'react-icons/ri'
import Banner from '../BannerRetail'
// import BannerImg from '../../../img/retail/data/Retail.jpg'
import BannerImg from '../../../img/retail/data/Retail.jpg'
import Retail1 from '../../../img/retail/data/Retail1.jpg'
import Retail2 from '../../../img/retail/data/Retail2.jpg'
import Retail3 from '../../../img/retail/data/Retail3.jpg'
import Retail4 from '../../../img/retail/data/Retail4.jpg'
import Retail5 from '../../../img/retail/data/Retail5.jpg'
import Retail6 from '../../../img/retail/data/Retail6.jpg'
import Retail7 from '../../../img/retail/data/Retail7.jpg'
import Retail8 from '../../../img/retail/data/Retail8.jpg'
import Retail9 from '../../../img/retail/data/Retail9.jpg'
import retailDoc1 from '../../../docs/retail/Vcis.pdf'




let retail = [
  {
  title: "Retail Sector's Struggle and Store Closures",
  desc: "The retail sector faces challenges, with over 7,000 stores closing in 2017 due to e-commerce, direct-to-consumer brands, and surplus retail space. Declining customer traffic and margins further impact brick-and-mortar store viability."
},
{
  title: "Data Limitations and Strategic Options",
  desc: "Some retailers lack data visibility and analytics capabilities. They must identify minimum data needs and consider partnering with external providers or acquiring analytics capabilities."
},
{
  title: "Misguided Store Closure Decisions from Outdated Metrics",
  desc: "Retailers make mistakes in store closures and miss growth opportunities due to outdated metrics. Relying solely on 'four-wall economics' neglects cross-channel shopping behavior, hindering accurate store performance evaluation."
},
]


const Intro = () => {
  return (
    <>
      <h2 className='my-5'><RiSingleQuotesL />Geolocation: Bridging realities, empowering decisions.<RiSingleQuotesR /></h2>
      <div className="d-flex intro_content gap-3">
        {retail.map((ret, index) => (
          <div key={index}>
            <Fade bottom>
              <div className="retail__box text-center p-3" style={{height: '19rem'}}>
                <h5>{ret.title}</h5>
                <p>{ret.desc}</p>
              </div>
            </Fade>
          </div>
        ))}
      </div>
    </>
  )
}

const footfall = [
  {
    title: 'Transformation of Physical Stores',
    desc: 'Physical stores now serve diverse roles, moving beyond transactions to become experiential showrooms, fulfillment centers, social hubs, and sources of inspiration.'
  },
  {
    title: 'Leveraging Data for Retail Success',
    desc: 'Retailers leverage varied datasets like point-of-sale and demographics, while new sources such as e-receipt programs and mobile location data offer valuable insights for data-driven decisions.'
  },
  {
    title: 'Geospatial Analytics as a Secret Weapon',
    desc: 'Geospatial analytics unveils store value and hidden opportunities, guiding strategic decisions with consumer behavior, demographics, and competition insights.'
  },
  {
    title: 'Data-Driven Insights & Geospatial Analytics in Retail',
    desc: "Data-driven insights and geospatial analytics are crucial for retail success, helping retailers thrive amidst changing customer demands and market dynamics."
  },
]

const Footfall = () =>{
  return (
    <>
    {/* <div className="section_seperator"></div> */}
      <div className="col-md-6">
          <div className="row ">
            <div className="col-md-12 img_section">
              <div className="row px-1 pb-2">
                <Fade top>
                  <div style={{backgroundImage: `url(${Retail1})`, height: '53rem', backgroundSize : 'cover', backgroundPosition: 'center right'}}></div>
                </Fade>
              </div>
            </div>
          </div>
      </div>
      <div className="col-md-6">
        <div className="row"></div>
          <h1><RiSingleQuotesL />Location data: Guiding retailers to success through footfall insights, optimized stores, and targeted marketing.<RiSingleQuotesR /></h1>
        <div className="footfall_section gap-3 mt-5">
        {footfall.map((foot, index) => (
          <div className="footfall_section-sub text-center" key={index}>
            <h5>{foot.title}</h5>
            <p>{foot.desc}</p>
          </div>
        ))}
        </div>
      </div>
    </>
  )
}

const dataRole = [
  {
    title: 'Geospatial Analytics for Retail Growth',
    desc: 'Retailers can identify opportunities and performance gaps using geospatial analytics. Successful pilot initiatives in specific markets can drive global expansion, capturing the omnichannel customer.',
    img: Retail2,
  },
  {
    title: 'Empowering Retailers with Data-Driven Decisions',
    desc: 'Advanced geospatial techniques and machine learning enable informed choices in store networks, action plans, and investments. Retailers leverage data to drive business growth and optimize sales strategies.',
    img: Retail3,
  },
  {
    title: "Customer Mobility Data and VCIS's Patterns",
    desc: "VCIS's Patterns data provides aggregated insights into customer mobility, including residential areas and visitor origins. Data cleansing and aggregation ensure accuracy.",
    img: Retail4,
  },
  {
    title: 'Enhanced Store Insights with Geospatial Tools',
    desc: 'Visualizing visitor block groups on a map offers precise trade area depictions, surpassing traditional methods. Valuable insights into customer characteristics and variances aid strategic decision-making.',
    img: Retail5,
  },
]

const Geospatial = () =>{
  return(
    <>
      <div className="dataRole_section d-flex flex-column">
      <h1 className='text-center mb-5'>How does VCIS use predictive geospatial analytics? </h1>
        {dataRole.map((data, index) => (
          <div key={index} className={`dataRole_section-part d-flex justify-content-between ${index % 2 === 0 ? 'flex-row-reverse' : ''}`}>
            {index % 2 === 0 ? <Fade left>{<div className="p-4 w-40 dataRole_section-part_img" style={{backgroundImage : `url(${data.img})`, flex : '2'}}></div>}</Fade> : <Fade right>{<div className="p-4 w-40 dataRole_section-part_img" style={{backgroundImage : `url(${data.img})`, flex : '2'}}></div>}</Fade>}
          <div className="seperator"></div>
          <div className="p-4 w-40 my-3" style={{ flex : '2'}}>
            <h4>{data.title}</h4>
            <h6>{data.desc}</h6>
          </div>
        </div>
        ))}
      </div>
    </>
  )
}

const customerSeg = [
  {
    title: '1. Site Analysis and Planning',
    desc: 'Digital transformation drives proactive brick-and-mortar expansion in retail. Geospatial solutions, leveraging mobility data, enhance market analysis and decision-making.'
  },
  {
    title: '2.Last Mile Delivery',
    desc: 'Geospatial technology improves supply chain profitability with efficient delivery routes and ideal operational locations.'
  },
  {
    title: '3.Market Segmentation',
    desc: 'Geospatial technology provides vital customer insights in a competitive market, helping businesses tailor effective marketing strategies.'
  },
  {
    title: '4.Service Area Qualification',
    desc: "Profitability for quick-service restaurants relies on understanding local customers' income, demographics, and travel preferences. Geospatial technology aids in consumer targeting and optimizing delivery services to reduce cart abandonment rates."
  },
  {
    title: '5.Customer Insights',
    desc: "Holistic customer view and geospatial solutions integrate physical and digital channels for an 'endless aisle' experience, enhancing merchandising and pricing strategies."
  },
  {
    title: '6.Tax Jurisdiction Assignment',
    desc: "Geospatial technology helps businesses tackle tax complexity by accessing tax district boundary files for accurate tax collections and reporting based on asset locations."
  },
]

const CustomerSeg = () =>{
  return (
    <>
    <div className="col-md-6 my-3">
        <div className="row"></div>
          <h2><RiSingleQuotesL />How Retailers Use Geospatial Data and Location Analytics <RiSingleQuotesR /></h2>
        <div className="footfall_section gap-3 mt-5">
        {customerSeg.map((seg, index) => (
          <div className="footfall_section-sub text-center" key={index}>
            <h5>{seg.title}</h5>
            <p>{seg.desc}</p>
          </div>
        ))}
        </div>
      </div>
      <div className="col-md-6">
          <div className="row">
            <div className="col-md-6">
              <div className="row img_section px-1 pb-2">
                <Fade top>
                  <div style={{backgroundImage: `url(${Retail6})`, height: '45rem', backgroundSize : 'cover', backgroundPosition: 'center center'}}></div>
                </Fade>
              </div>
              <div className="row img_section px-1 pb-2">
                <Fade bottom>
                    <div style={{backgroundImage: `url(${Retail7})`, height: '20rem', backgroundSize : 'cover', backgroundPosition: 'center center'}}></div>
                  </Fade>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row img_section px-1 pb-2">
                <Fade left>
                  <div style={{backgroundImage: `url(${Retail8})`, height: '20rem', backgroundSize : 'cover', backgroundPosition: 'center center'}}></div>
                </Fade>
              </div>
              <div className="row img_section px-1 pb-2">
                <Fade left>
                  <div style={{backgroundImage: `url(${Retail9})`, height: '45rem', backgroundSize : 'cover', backgroundPosition: 'center center'}}></div>
                </Fade>
              </div>
            </div>
          </div>
      </div>
      
    </>
  )
}

const TitleBg = () => {
  return (
    <>
      A New Era in Data<br /> and Analytics.
    </>
  )
}

const RetailData = () => { 


  return (
    <>
    <Banner
      pos="30%"
      title= {<TitleBg />}
      desc=""
      img = {BannerImg}
      link = {retailDoc1}
    />

    {/* Introduction */}
      <div className="retail__content-all container mt-5">
        <Intro />
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row">
          <Footfall />
        </div>
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row">
          <Geospatial />
        </div>
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className="row mb-5">
          <CustomerSeg />
        </div>
      </div>
    </>
  )
}

export default RetailData