import Insight1 from '../../../assets/img/insights1.jpg'
import Insight2 from '../../../assets/img/insights2.jpg'
import Insight3 from '../../../assets/img/insights3.jpg'

const insightsArray = [
    {
        img: Insight1,
        date: '16/06/2020 12:00 AM',
        title: 'Beyond Digital Innovation & Transformation: Modernizing Core Strategy for the AI-Powered Regulatory Compliance of the Future.',
        desc: "VALOORES has aligned its approach with Regulators and Executives to focus on more detailed and granular data, rather than static reports. Data management, Governance and reporting, are now the focal point of regulatory initiatives, hence securing deeper insights, enhancing Risk management and Decision-Making.",
        link: '',
    },
    {
        img: Insight2,
        date: '15/04/2020 12:00 AM',
        title: 'How Banks Excel in Regulatory Compliance, Financial Crime, and AML/CFT?',
        desc: "The biggest daunting challenge in today's industry rotates around securing regulatory compliance whilst retaining the fast-paced regulatory change, maintaining compliance with metamorphosing regulations, and adhering to respective regulatory deadlines.",
        link: '',
    },
    {
        img: Insight3,
        date: '04/03/2020 12:00 AM',
        title: 'Financial Services KYC',
        desc: "Valoores Know Your Customer allows financial institutions to meet regulatory requirements, improves customer relationships, and provides cost effective measures to identify and prevent financial crimes.",
        link: '',
    },
]

export default insightsArray;