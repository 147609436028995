import React from 'react'
import './Intro.css';

const Intro = ({title, desc, addLine,}) => {
  return (
    <div className="intro_component d-flex flex-column align-items-center justify-content-center gap-3 my-5">
        <h1 className='font--poppins' style={{color: '#001f3d!important'}}> {title}</h1>
        {addLine === 'true' && <hr style={{width: '5%', border: '2px solid #051c2c', opacity: '1'}}/>}
        <h4 className="text-center" style={{color: '#001f3d!important', fontSize: '1rem'}}>{desc}</h4>
    </div>
  )
}

export default Intro