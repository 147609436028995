
const inInstitutional  = [
  
    {
      
      title: "Overview",
      desc: "Today’s banking customer is different than yesterday’s banking customer. The market is saturated. Products are rarely, if at all, differentiated. To add to this, information about competitive products is easily available to consumers for comparison and decision making. Due to these factors, Financial Institutions are finding it more difficult to attract new customers or retain existing ones. valoores in' Institutional Performance Analytics provides deep insights about institutional customer engagements and enhances the ability to stay abreast of the details and analytics surrounding a firm’s customers and their performance with the bank. This information helps a financial institution stay competitive and profitable in the marketplace by enabling growth driven by rich customer insights.",
      list: [''],
    },
   
    {
      title: "Key Benefits",
      desc: '',
      list: [
        'Achieve 360° view of the customer relationship',
        'Allow senior management to stay current in their understanding of the customers’ financial needs',
        'Reduce dependence on internal resources to quickly deliver value through out-of-box reports',
        'Ability to drill-down through aggregated reports and identify profitability across dimensions',
        'Measure risk-adjusted performance of individual accounts and customers',
      ],
    },
   
    {
      title: "Key Features",
      desc: '',
      list: [
        'Utilize scenario analysis to enhance visibility of foreseen events on profitability',
        'Proactively target customer segments through monitoring of behavioral and engagement trends',
        'Track and manage partner developments and relationship management',
        'Enterprise-wide reporting through conformed dimensions and consistent definitions and calculations',
      ],
    },
  ]

  export default inInstitutional 