import React from 'react'
import Sidebar from '../Banking/BankingPages/SideMenu'
import Banner from './Banner'
import Content from './Content'
import { Helmet } from 'react-helmet';
import {useLocation} from 'react-router-dom';
import inAnalytics1 from '../../img/analytics/analytics1.jpg';
import inAnalytics2 from '../../img/analytics/analytics2.jpg';
import inAnalytics3 from  '../../img/analytics/analytics3.jpg'
import inAnalytics4 from  '../../img/analytics/analytics4.jpg'
import inAnalytics5 from  '../../img/analytics/analytics5.jpg'
import inAnalytics6 from  '../../img/analytics/analytics6.jpg'
import inAnalytics7 from  '../../img/analytics/analytics7.jpg'
import inAnalytics8 from  '../../img/analytics/analytics8.jpg'
import inAnalytics9 from  '../../img/analytics/analytics9.jpg'
import inAnalytics10 from  '../../img/analytics/analytics10.jpg'
import inAnalytics11 from  '../../img/analytics/analytics11.jpg'
import inAnalytics12 from  '../../img/analytics/analytics12.jpg'
import inViaArray from './content/inVia';
import inLoanArray from './content/inLoan';
import inBusinessArray from './content/inBusiness';
import inEntrepriseArray from './content/inEnterprise';
import inRetailArray from './content/inRetail';
import inEntPlanningArray from './content/inEntPlanning';
import inInstArray from './content/inInstitutional ';
import inAssetArray from './content/inAsset';
import inAnalyticalAppArray from './content/inAnalyticalApp';
import inModelRiskManagementArray from './content/inModelRiskManagement';
import inOperationalArray from './content/inOperational ';
import inProActiveArray from './content/inProActive';



const Feature = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const receivedVariable = searchParams.get('variable');
    console.log(receivedVariable);
    let title, BannerImg, BannerDesc, RiskArray, overview, demo, pdf, fact, paper;    

    switch(receivedVariable){
        case 'inVia':
          
            title = "in'Via"
            BannerImg = inAnalytics1
            BannerDesc = "“in'Via takes the dashboard of Charts, KPIs, Reports and more to the next level with an intuitive and intelligent design to empower everyday business decisions.”"
            RiskArray = inViaArray
            overview = 'no'
            demo = 'yes'
            pdf = 'no'
            fact = 'yes'
            paper = 'no'
        break;
    
        case 'inLoan':
          
            title = "in'Loan Loss Forecasting and Provisioning"
            BannerImg = inAnalytics2
            BannerDesc = "“in'Loan Loss Forecasting & Provisioning provides pre-configured & extensive computations that enable institutions to effectively forecast credit losses under multiple scenarios and compute required provisions.”"
            RiskArray = inLoanArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inBusiness':
          
            title = "in'Business Analytics"
            BannerImg = inAnalytics3
            BannerDesc = "“in'Business Analytics empowers data and business analysts to extract knowledge, discover new insights and make predictions working directly with large data volumes.”"
            RiskArray = inBusinessArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inEntreprise':
          
            title = "in'Enterprise Financial Performance Analytics"
            BannerImg = inAnalytics4
            BannerDesc = "“in'Enterprise Financial Performance Analytics provides timely and actionable financial and management reports across organization, line of business, products and legal entities.”"
            RiskArray = inEntrepriseArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inRetail':
          
            title = "in'Retail Customer Analytics"
            BannerImg = inAnalytics5
            BannerDesc = "“in'Retail Customer Analytics helps retail banking institutions ensure that marketing dollars are invested wisely and the customers with the most potential to be profitable are targeted through the right channels.”"
            RiskArray = inRetailArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inEntPlanning':
          
            title = "in'Enterprise Stress Testing & Capital Planning Analytics"
            BannerImg = inAnalytics6
            BannerDesc = "“in'Enterprise Stress Testing and Capital Planning Analytics enables financial institution to comply with the new orders and gives senior management a 360o view of data to eliminate organizational silos.”"
            RiskArray = inEntPlanningArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inInstitutional':
          
            title = "in'Enterprise Stress Testing & Capital Planning Analytics"
            BannerImg = inAnalytics7
            BannerDesc = "“in'Enterprise Stress Testing and Capital Planning Analytics enables financial institution to comply with the new orders and gives senior management a 360o view of data to eliminate organizational silos.”"
            RiskArray = inInstArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inAsset':
          
            title = "in'Asset Liability Analytics"
            BannerImg = inAnalytics8
            BannerDesc = "“in'Asset Liability Analytics provides institutions with new levels of insight by empowering all users in the organization with access to a wide range of intelligence and analysis tailored to individual roles and personalized to specific needs”"
            RiskArray = inAssetArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inAnalyticalApp':
          
            title = "in'Analytical Applications Basel III"
            BannerImg = inAnalytics9
            BannerDesc = "“in'Analytical Applications Basel III solution has been architected based on a unified platform, which breaks down the traditional analytical silos within a financial institution, including risk, performance and finance.”"
            RiskArray = inAnalyticalAppArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inModelRiskManagement':
          
            title = "in'Model Risk Management"
            BannerImg = inAnalytics10
            BannerDesc = "“in'Model Risk Management provides institutions the ability to build a single repository of model informationacross the organization and improve governance through periodic and structured assessments of risks associated with the use of each model.”"
            RiskArray = inModelRiskManagementArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inOperational':
          
            title = "in'Operational Risk Analytics"
            BannerImg = inAnalytics11
            BannerDesc = "“in'Operational Risk Analytics enables business-user driven reporting through true enterprise-wide operational risk analysis with a comprehensive and readily deployable suite of pre-built analytics, allowing institutions to capitalize on their present investments. ”"
            RiskArray = inOperationalArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'inProActive':
          
            title = "in'Pro-Active Debt Recovery"
            BannerImg = inAnalytics12
            BannerDesc = "“in'Pro-Active Debt Recovery solution uses state of the art Machine Learning algorithms to predict the possibility of an account’s transfer to the Collections Department. ”"
            RiskArray = inProActiveArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;
    

        default:
        break;
        }

        // const formattedPage = receivedVariable.replace(/-/g, ''); // Remove dashes from the page variable
        // const formattedUrl = `/banking/riskandcompliance/${formattedPage}`;
  
        // window.history.replaceState(null, null, formattedUrl);
    
    return (
        <>
            <Helmet>
                <title>{receivedVariable}</title>
            </Helmet>
            <Banner
                img = {BannerImg}
                desc= {BannerDesc}
                demo = {demo}
                pdf = {pdf}
                fact = {fact}
                paper = {paper}
            />
            <div className='section_divider risk_container my-4'>
                <div className="container">
                    <h4>{title}</h4>
                </div>
            </div>
            <div className='d-flex flex-row container'>
                <div className="main-content overview_content" style={{flex: '4'}}>
                    
                    <Content RiskArray = {RiskArray}/>
                </div>

                <div className="sidebar_section" style={{flex: '1'}}>
                    <Sidebar /> 
                </div>
            </div>
        </>
    )
}

export default Feature