

const InSupplierManagement = [
    {
      title: "Overview",
      desc:
      (
        <>
        <br />
        Through the last ten years, the customer became more demanding than ever in terms of product quality to product delivery. Companies always need to provide the best service to keep and improve customer satisfaction. A company having great customer service has an obvious advantage over other companies.
        <br />
        To stand out amongst today’s aggressively competent marketplace, a Company or E-Commerce Company should capitalize on every opportunity that presents itself and in’ Supply Chain solution is one of them.
        <br />
        in’Supply Chain is designed to provide solutions to different lines of businesses such as finance, customer service, risk a nd healthcare. Our solution will help your business become more intelligent by taking better business decisions within a short period & at the same time it will affect the cost and the profitability.
        <br />
        The stock Responsible personnel has a full access on company operations in order to fulfill consumer needs by replenishing the company, executing price changes and promotions strategies, managing staff and ensuring a service level to provide a compelling retail experience for the customer.
        <br />
        In’Supply Chain delivers functionalities that manage for companies essential activities such as the replenishment, stock, inventory, invoice control, merchandising, labor scheduling, customer data and after sales service, monitoring departments’ activities & results in a single application. There’s also exists additional features such as a search engine, mobile computing capabilities, customized dashboard alerts and to-do lists, favorites and exception management that can facilitate a user’s daily work.
        <br />
        in’ Supply Chain helps streamline everything from day-to-day product flows to unexpected natural disasters. With the tools and techniques that in’Supply chain offers, you will have the ability to properly diagnose problems, work around disruptions and determine how to efficiently move products to those in a crisis situation.
        <br />
        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: 
        <>
        
        <b>Increase companies profitability</b>
        <br />
        <ul>
            <li>Optimize Company sales and profits</li>
            <li>Reduce operational costs</li>
            <li>Increase company productivity</li>
            <li>Control operating accounts</li>
            <li>Avoid out-of-stock or overstock issues</li>
        </ul>

        <br />

        <br />

        <b>Optimize companies organization</b>
        <br />
        <ul>
            <li>Reduce repetitive tasks</li>
            <li>Focus on added-value tasks</li>
            <li>Manage by exception (workflow alerts)</li>
            <li>Supply users with customized information to take best informed actions</li>
            <li>Optimize use of resources through better task planning</li>
            <li>Enable users to access updated information in a timely manner</li>
        </ul>

        <br />

        <br />

        <b>Automate certain tasks</b>
        <br />
        <ul>
            
            <li>Plan teams’ work time</li>
            <li>Manage stocks</li>
            <li>Propose stocks procurement levels automatically</li>
            <li>System generates order creation</li>

        </ul>

        <br />

        <br />

        <b>Gain customer loyalty</b>
        <br />
        <ul>

            <li>Improve customer relations (after-sales service)</li>
            <li>Optimize commissioned based sale</li>
            <li>Personalize services</li>

        </ul>

        <br />

        <br />

        <b>Rapid return on investment</b>
        <br />
        <ul>
            <li>Reduced deployment and maintenance thanks to centralized infrastructure</li>
            <li>Reduced training requirements with the help of Internet based navigation, customizable screens, and intuitive user interface.</li>
        </ul>

        </>,
        list: [''],
      },
      {
        title: "Key features",
        desc:
        <>
        In’ Supply chain is a flexible and configurable platform which supports the following functionalities:
        <br />
        <b>Merchandise Planning, Ordering and Replenishment</b>
        <br />
        <ul>
            <li>Planned order and replenishment management</li>
            <li>Supplier and Warehouse Item booking follow-up</li>
            <li>Purchase order preparation and issuing</li>
            <li>Monitored merchandise receiving planning</li>
        </ul>

        <br />

        <br />

        <b>Invoice Matching</b>
        <br />
        <ul>
            <li>Invoice matching and balance</li>
            <li>Credit note management</li>
        </ul>

        <br />

        <br />

        <b>Stock Management</b>
        <br />
        <ul>
            <li>Optimize and evaluate stock levels</li>
            <li>Merchandise movements follow-up and tracking</li>
            <li>Manage fiscal and physical inventories</li>
            <li>Manage stock markdowns and shrinkage</li>
            <li>Manage returns to suppliers</li>

        </ul>

        <br />

        <br />

        <b>Sales</b>
        <br />
        <ul>

            <li>Manage centralized and local assortments</li>
            <li>Manage prices, price positioning and product alignments</li>
            <li>Define, plan and monitor promotional operations</li>
            <li>Manage shelf and labeling tasks</li>

        </ul>

        <br />

        <br />

        <b>Store Profit and Loss Management</b>
        <br />
        <ul>
            <li>Store commercial targets, forecast against cost</li>
            <li>Real-time activities and results control & monitoring</li>
            <li>Store reporting and results analysis</li>
            <li>Customized reporting based on business needs</li>
        </ul>

        <br />

        <br />

        <b>Customer Service</b>
        <br />
        <ul>
            <li>After-sales service: refunds, complaints</li>
            <li>Manage loyalty program: loyalty cards, events actions</li>
        </ul>

        </>,
        list:[''],
      },
  ];
  
  export default InSupplierManagement;
  