import FDS from '../../../assets/img/fdsSolution.jpg'
import CIS from '../../../assets/img/cisSolution.jpg'
import Retail from '../../../assets/img/retailSolution.jpg'
import Tech from '../../../assets/img/techSolution.jpg'
import Business from '../../../assets/img/businessSolution.jpg'

const sectionsArray = [
    {
        img: FDS,
        title: 'FDS Solutions: Comprehensive Financial Crime Management ',
        desc: 'Our consulting services in Financial Crime Detection (FDS) deliver robust, scalable frameworks that encompass KYC, Transaction Monitoring, Fraud Risk Management, and Regulatory Reporting.',
        link: '',
    },
    {
        img: CIS,
        title: 'CIS Solutions: Optimizing Public & Private Sector Operations',
        desc: 'Our consultants work closely with organizations to transform complex data landscapes into clear, actionable strategies that drive operational excellence.',
        link: '',
    },
    {
        img: Retail,
        title: 'Retail & Supply Chain: Driving Performance Optimization',
        desc: 'Our consultants leverage over three decades of retail expertise to address key challenges within siloed operations, supplier negotiations, and competitive dynamics.',
        link: '',
    },
    {
        img: Tech,
        title: 'Advanced Technology Consulting: AI, Machine Learning & Big Data',
        desc: 'Our consultants facilitate the seamless deployment of these technologies, enhancing predictive modeling, automation, and data-driven decision-making across all sectors.',
        link: '',
    },
    {
        img: Business,
        title: 'Business Intelligence & Data Analytics: Strategic Data Transformation',
        desc: 'Our team delivers comprehensive consulting services in Business Intelligence and Data Analytics, enabling organizations to transform vast datasets into strategic assets.',
        link: '',
    },
]

export default sectionsArray;