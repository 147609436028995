const grc = [
    {
        title: "Overview",
        desc: 
        (
            <>
                The breaches to an organization's information security can damage a good reputation and lead to a loss of confidence among customers, investors and the market at large. Successful companies have learned through experience that to continuously achieve performance excellence in a very competitive market, it's imperative to adhere to strict regulatory compliances while mitigating risks by implementing a robust data governance process part and parcel of uncompromising data security processes.
                <br />
                The implementation and the automation of coherent Governance, Risk and Compliance (GRC) software is crucial to shield any organization's information and security system.
                <br />
                VALOORES' GRC solution is designed to help you reach your goals, to allow your organization to be fully prepared for unpredictable events, protect your reputation, while freeing up more of your time to dedicate to pursuing growth opportunities.
            </>
        ),
        list: [],
    },
    {
        title: "Key Benefits",
        desc: '',
        list:
        [
            "Quicker planning and more conscious decision making",
            "Lower fees for external auditors and consultants",
            "Very fast implementation",
            "Enhanced business reporting and standardization",
            "Consistent and reliable reports from different sources and alerts notification to employees to react on a timely basis",
        ]
    },
    {
        title: "Key Features",
        desc: '',
        list:
        [
            "Determine the main reasons behind risk through tracing loss events",
            "Normalize the practice of detecting and controlling risk",
            "Cover its entire compliance cycle through an end to end solution",
            "Emphasize on key risk indicators through existing dashboards, reports and scorecards, empowering the organization to mitigate risk in an easy way",
            "Reduce costs and avoid human errors through automated processes",
            "Install an automated workflow and adequate controls to ensure the proper communication and successful execution of governance",
        ]
    },
]

export default grc