import React from 'react';
import {Link} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import Axios from './database/axios.jsx';

const Compliance = () => {

    const fetchedData = Axios({ path: '/section' });

  return (
    <>
        {fetchedData.map((compliance) => (
            <>
                {compliance.type === 'compliance' && (
                    <div className="compliance_section mt-5">
                        <div className="container">
                            <div className="row">
                                <div className={compliance.text_container}>
                                    <div className={compliance.class}>
                                        {ReactHtmlParser(compliance.title)}
                                        {ReactHtmlParser(compliance.description)}
                                    </div>
                                </div>
                                <div className={compliance.text_container}>
                                    <Link to = {compliance.link} target='_blank'>
                                        <img className='w-100' src={require(`../../img/${compliance.image_path.split('/').pop()}`)} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        ))}
    </>
    )
}

export default Compliance