import Analytics1 from '../../../../img/analytics/analytics1.jpg'
import Analytics2 from '../../../../img/analytics/analytics2.jpg'
import Analytics3 from '../../../../img/analytics/analytics3.jpg'
import Analytics4 from '../../../../img/analytics/analytics4.jpg'
import Analytics5 from '../../../../img/analytics/analytics5.jpg'
import Analytics6 from '../../../../img/analytics/analytics6.jpg'
import Analytics7 from '../../../../img/analytics/analytics7.jpg'
import Analytics8 from '../../../../img/analytics/analytics8.jpg'
import Analytics9 from '../../../../img/analytics/analytics9.jpg'
import Analytics10 from '../../../../img/analytics/analytics10.jpg'
import Analytics11 from '../../../../img/analytics/analytics11.jpg'
import Analytics12 from '../../../../img/analytics/analytics12.jpg'

const InAnalytics = [
    {
      img: Analytics1,
      title: "in'Via",
      desc: "VALOORES delivers pre-built KPIs & KRIs, tied to our clients' strategies, to set goals through series of objectives defined upfront, and monitored through upstream and downstream workflow notifications...",
      link: "../banking/inAnalytics?variable=inVia",
    },
    {
      img: Analytics2,
      title: "in'Loan Loss Forecasting and Provisioning",
      desc: "VALOORES financial services' in' Loan Loss Forecasting and Provisioning performs major tasks required by IFRS 9 based on variety of parameters...",
      link: "../banking/inAnalytics?variable=inLoan",
    },
    {
      img: Analytics3,
      title: "in'Business Analytics",
      desc: "VALOORES in'Business Analytics empowers data and business analysts to extract knowledge, discover new insights and make predictions—working directly with large data volumes in the VALOORES Database...",
      link: "../banking/inAnalytics?variable=inBusiness",
    },
    {
      img: Analytics4,
      title: "in'Enterprise Financial Performance Analytics",
      desc: "In today's rapidly evolving and complex financial services environment, it is more critical than ever for banks to quickly understand profitability results adjusted for risk. VALOORES in' Enterprise...",
      link: "../banking/inAnalytics?variable=inEntreprise",
    },
    {
      img: Analytics5,
      title: "in'Retail Customer Analytics",
      desc: "Gone are the days when consumers were significantly loyal to just one bank. Nowadays, it is customary for consumers to engage with multiple banks for their mortgage, checking/savings accounts, lines of credit,...",
      link: "../banking/inAnalytics?variable=inRetail",
    },
    {
      img: Analytics6,
      title: "in'Enterprise Stress Testing & Capital Planning Analytics",
      desc: "Each year financial institutions are required to comply with a plethora of regulations, which becomes even more complicated for those operating in multiple jurisdictions...",
      link: "../banking/inAnalytics?variable=inEntPlanning",
    },
    {
      img: Analytics7,
      title: "in'Institutional Performance Analytics",
      desc: "Today's banking customer is different than yesterday's banking customer. The market is saturated. Products are rarely, if at all, differentiated. To add to this, information about competitive products...",
      link: "../banking/inAnalytics?variable=inInstitutional",
    },
    {
      img: Analytics8,
      title: "in'Asset Liability Analytics",
      desc: "The highly volatile and liquid nature of today's financial markets is forcing companies across the Financial Services industry to place renewed emphasis on the most efficient use of capital,...",
      link: "../banking/inAnalytics?variable=inAsset",
    },
    {
      img: Analytics9,
      title: "in'Analytical Applications Basel III",
      desc: "In response to the recent global financial crisis, the Basel III regulatory requirements are considered robust and far reaching; however, banks are finding it challenging...",
      link: "../banking/inAnalytics?variable=inAnalyticalApp",
    },
    {
      img: Analytics10,
      title: "in'Model Risk Management",
      desc: "Financial institutions use models in critical functions including risk quantification, fraud and money laundering detection, performance management and business development...",
      link: "../banking/inAnalytics?variable=inModelRiskManagement",
    },
    {
      img: Analytics11,
      title: "in'Operational Risk Analytics",
      desc: "VALOORES in' Operational Risk Analytics enables business-user driven reporting through true enterprise-wide operational risk analysis with a comprehensive and readily deployable suite of pre-built analytics,...",
      link: "../banking/inAnalytics?variable=inOperational",
    },
    {
      img: Analytics12,
      title: "in'Pro-Active Debt Recovery",
      desc: "VALOORES Pro-Active Debt Recovery solution uses state of the art Machine Learning algorithms to predict the possibility of an account's transfer to the Collections Department ...",
      link: "../banking/inAnalytics?variable=inProActive",
    },
  ]

  export default InAnalytics