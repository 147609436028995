import React from 'react'
import AI from '../../img/carousel/ai.jpg';
import {AiOutlineCheck} from 'react-icons/ai'
import Fade from 'react-reveal/Fade';


const Banner = () => {
  return (
    <>
    <div className='banner_img-ai text-white d-flex align-items-center justify-content-center' style={{ backgroundImage: `url(${AI})`}}>
        <div className="banner_overlay-ai"></div>
        <div className='w-100 flex-column' style={{top: '10%'}}>
            <div className="container banner_content-ai flex-row">
                <div className="left_side" style={{flex : '3', paddingRight: '6rem'}}>
                    <Fade left>
                        <h1 style={{lineHeight : '4rem'}}>AI-Driven<br/> Solutions</h1>
                        <p style={{lineHeight : '2.5rem'}}>Valoores harnesses AI to deliver innovative software solutions, surpassing human capabilities and driving global growth.</p>
                    </Fade>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Banner