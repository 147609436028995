import React from 'react'
import valoorescompany from '../img/valoorescompany.jpg'
import {AiFillClockCircle} from 'react-icons/ai'
import {BsFillTelephoneFill} from 'react-icons/bs'
import {RiMapPinFill} from 'react-icons/ri'
import { GrHostMaintenance } from "react-icons/gr";

const Contact = () => {
  return (
    <>
    <div className='contact_section p-4 text-center'>
      <div className="row contact_section-upper d-flex align-items-center">
        <div className="col-md-6">
          <div className="upper">
            <h4 className='mb-3' style={{lineHeight: '2.5rem'}}>"You Have The Value, <br />We Bring You The <span className='fw-bold'>Added Value"</span></h4>  
            <h6>We are here to meet any business need.</h6>
            <hr />
            {/* <p><span className='fw-bold'>Phone: </span>+961 1 90 11 70</p> */}
            <p><span className='fw-bold'>Headquarters: </span>Netherlands</p>
            {/* <p><span className='fw-bold'>Email: </span>Support@valoores.com</p> */}
          </div>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          <div className='contact_section-img' style={{backgroundImage: `url(${valoorescompany})`}}></div>
        </div>
      </div>
      <div className="row contact_section-lower mt-5">
        
      <div className="contact_section-lower_card">
          <h5 className='fw-bold'><RiMapPinFill /> Europe</h5>
          <div  className='p-3'>
            <span>Netherlands / UK / Cyprus</span>
          </div>
        </div>
      {/*         
        <div className="contact_section-lower_card">
          <h5 className='fw-bold'><RiMapPinFill /> Cyprus </h5>
          <div className='p-3'>
            <span>Developer Hub</span>
          </div>
        </div> */}


        <div className="contact_section-lower_card">
          <h5 className='fw-bold'><RiMapPinFill /> United States</h5>
          <div className='p-3'>
            <span>Austin - Texas</span>
          </div>
        </div>

        <div className="contact_section-lower_card">
          <h5 className='fw-bold'><RiMapPinFill /> Asia</h5>
          <div className='p-3'>
            <span>Singapore</span>
          </div>
        </div>

        {/* <div className="contact_section-lower_card">
          <h5 className='fw-bold'><RiMapPinFill /> Gulf</h5>
          <div className='p-3'>
            <span>Qatar / Abou Dabi</span>
          </div>
        </div> */}
        
        <div className="contact_section-lower_card">
          <h5 className='fw-bold'><RiMapPinFill /> Africa</h5>
          <div className='p-3'>
            <span>Kenya</span>
          </div>
        </div>

        <div className="contact_section-lower_card">
          {/* <h5 className='fw-bold'><BsFillTelephoneFill /> CALL US</h5> */}
          <h5 className='fw-bold'><RiMapPinFill /> Lebanon </h5>
          <div className='p-3'>
            <span>Maintenance Support</span>
          </div>
        </div>

      </div>
    </div>
    </>
  )
}

export default Contact