import React from 'react'
import Banner from './DareToOvercome/Banner'
import Intro from './DareToOvercome/Intro'
import Childhood from './DareToOvercome/Childhood'
import Company from './DareToOvercome/Company'
import LawEnforcement from './DareToOvercome/LawEnforcement'
import './DareToOvercome/style.scss'

const DareToOvercome = () => {
  return (
    <>
       <Banner />
       <Intro />
       <div className="container p-0">
        <hr className='mb-5 mt-3' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
       </div>
       <Childhood />
       <div className="container p-0">
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
       </div>
       <Company />
       <div className="container p-0">
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
       </div>
       <LawEnforcement />
    </>
  )
}

export default DareToOvercome