import React, { useState } from 'react';
import applicationSection from './applicationSection';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const VideoPlayer = ({ videoId }) => {
  const opts = {
    height: '320',
    width: '100%',
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      rel: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};

const Application = () => {
  const [isOpen, setIsOpen] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);  

  const openModal = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  

  const handleCloseModal = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  return (
    <>
      <div className="container app_container my-5">
        <div className="row text-center d-flex flex-column align-items-center justify-content-center">
          <h3>Our Featured Applications</h3>
          <h5>VALOORES Online & Offline Demos</h5>
        </div>
        <div className="d-flex align-items-center justify-content-around flex-row text-center">
          {applicationSection.map((items, index) => (
            <div key={index} className='px-3'>
              <img src={items.icon} alt="" style={{width: '60px'}}/>
              <h4 className="mt-4">{items.title}</h4>
              <p className="mt-4 text-start">{items.desc}</p>
              <span>
                <div className='links_app d-flex flex-column gap-3'>
                    <Link to={`https://www.youtube.com/watch?v=${items.video}`} target='_blank'> Watch Video </Link>
                    <Link to={items.more} >Read More </Link>
                    <Link to={items.quick}>Quick Demo </Link>
                    <Link to={items.demo}>Request Demo </Link>
                </div>

                <Modal
                  isOpen={isModalOpen}
                  onRequestClose={handleCloseModal}
                  contentLabel="Add Modal"
                >
                  <div className="container">
                    <h2 className='text-center mb-5 mt-2'>Choose Your Video</h2>
                    <div className="row">
                      <div className="col-md-12">
                        <h4 class='mb-2'>The Full Episode :</h4>
                        <VideoPlayer videoId={items.video}/>
                      </div>
                    </div>
                  </div>
                
                </Modal>
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Application;
