import React from 'react'
import Services2 from '../../img/services2.png'

const Choice = () => {
  return (
    <>
    <div className="container choice_content">
        <div className="row">
            <div className="col-md-7">
                <h4>Why Choosing VALOORES in'Banking?</h4>
                <p>The search for a better BI solution stops here. At VALOORES, our objective is to provide you with the only Dashboard, Business Intelligence and Analytics Solutions you'll need for your business. With VALOORES' powerful and intuitive BI and Analytics Solutions, you stay connected to your data wherever you are in the world with our technology. Our highly incorporated BI solution optimizes the usage of the large data transforming it into readable, reliable and relevant information. Engage with your peers and your customers. Share your data with just a click using our advanced Dashboard solutions. VALOORES solutions are designed with you in mind, for real people using real data making important business decisions that will positively impact growth and overall performance of organizational results. We give you the right solutions to bring you intuitive, intelligent, sharable information to keep you two steps ahead of the competition.</p>
            </div>
            <div className="col-md-5">
                <img src={Services2} alt="" style={{width: '100%'}} />
            </div>
        </div>
    </div>
    </>
  )
}

export default Choice