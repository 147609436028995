import React from 'react';
// import {Link} from 'react-router-dom'
import FullLogoC from '../../img/full_logoC.png';
import YouTube from 'react-youtube';
import { Link } from 'react-router-dom';

const VideoPlayer = ({ videoId }) => {
  const opts = {
    height: '450',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      mute: 1,
      modestbranding: 1,
      rel: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};
  
  const Introduction = () => {
  const videoId = 'nxGYkwHggd8';
  return (
    <>
      <div className="container mt-5 intro_container">
        <div className="row align-items-center">
            <div className="col-md-6">
              <div className="video_player-container">
                <VideoPlayer videoId={videoId}/>
             
              </div>
            </div>
            <div className="col-md-6">
              <div className="content__title">
                <h2>
                  <img src={FullLogoC} alt="" /><br />
                  {/* <span style={{backgroundColor: 'transparent!important', color: '#fff!important'}}>More than 30 Years in less than 30 Seconds</span> */}
                </h2>
                {/* <p>
                  Enterprise Business Solutions in Financial Services, Cyber Crimes, BI, Analytics, in'Advanced Certified Anti-Hacking and State Security Clouds Model. VALOORES is one of the leading providers of Enterprise Business Solutions and BI Vertical Decision Making. We are a World Class Company implementing products and services to Tier1 Corp. Our resources pool is spread over North America (US and Canada), Europe (Eastern & Western), Africa, and the Middle East. VALOORES, established in 1989, operates in 17 countries.
                </p> */}
                <p>
                  Pioneering Financial Integrity: <br />
                  VALOORES' Role in Strengthening Risk, <br />
                  and Compliance across the Middle East.<br /><br />

                  Sharm El-Sheikh, Egypt - VALOORES, a leading global provider of financial and compliance integrity solutions, proudly announces its participation in the Annual Forum for Risk and Compliance Officers in Egypt. The Forum held under the auspices of His Excellency, the Governor of the Central Bank of Egypt, marks a pivotal moment in the region's fight against financial crime and the Union of Arab Banks.
                  <br />
                  {/* <br />
                  With over 35 years of experience serving more than 30 countries, VALOORES is uniquely positioned to empower Central  Banks, Financial Intelligence Units, retail and commercial banks, investment banks, insurance companies, and other financial institutions in navigating risk and compliance solutions.
                  <br />
                  <br />
                  "We are committed to supporting the Middle East in its fight against financial crimes," said Mr. Taouk. "Our V-FDS solution provides the expertise needed to navigate the complexities of the financial ecosystem.” */}
                </p>
                <div className="button_div d-flex justify-content-start align-items-start w-100">
                    <Link className="btn btn-show-sec " to='/news/pressRelease' target= '_blank'>Read More</Link>
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Introduction