

const inMultilanguage = [
    {
      title: "Overview",
      desc:
      (
        <>
          In our global economy, companies are expanding every day, and it’s crucial that your company extends its reach; as a result each company needs to implement their own language.
          <br />
          in’Multilanguage: a fully configurable solution that works simultaneously with any language to translate content depending on user preference in the VALOORES suite.
          <br />
          “Multilingual talent is an untapped resource for business expansion”
          <br />
          If you are looking for one core solution to translate every application and modify keys and menus tailored to every user’s need to minimize the margin of errors, maximize productivity and save time; then VALOORES in’Multilanguage is the solution for you.
          <br />
        </>
      ),
      list: [''],
    },
    {
      title: "Key Benefits",
      desc:'',
      list: [

        'Translate any VALOORES Suite application to any language.',
        'Group similar keys together and translate them once.',
        'Export/ Import .xml and .csv files to exchange translation terms between different systems.',
        'Create different search macros for your translations.',
        'Translate all system tables and system information like menus and drop-down information.',
        'A global and advanced search engine to find the correct key needed for translation.',

      ],
    },
    {
      title: "Key Features",
      desc:
      (
      <>
        in’Multilanguage uses two main tools: Translator and Settings.
        <br />
        <ul>
          <li>
          Translator is the tool we use to translate keys and menus to other languages or to similar languages. Translator offers multiple services under its arsenal:
            <ul>
              <li>The Search service allows the user to find the elements he needs to translate by type or location. The Advanced Search feature is for more specific filtering.</li>
              <li>The Group service allows the user to group several keys together, so when he translates one key from this group, the whole group is translated. Quick Entry is another grouping service that allows the user to translate multiple keys and menus that are not linked to a group together beforehand; this saves time and effort.</li>
              <li>The Business Model service is a way of gathering data under the business model structure (Data Store, Hierarchy, and Taxonomy)</li>
              <li>Screen is a way of translating every element available screen by screen on every application.</li>
              <li>The Report service allows the user to translate issued reports on every level of the VALOORES environment.</li>
            </ul>
          </li>
          <li>Settings is the second tool of the VALOORES in’Multilanguage Solution. It is a section that allows us to share the translation, create the keys and allow the duplication of the translation between two similar languages. Import and Export of the translations can be done in .xml and .csv to fit every operating system.</li>
        </ul>
      </>
      ),
      list: [''],
    },
   
  ];
  
  export default inMultilanguage;
  