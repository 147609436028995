import React from 'react';
import {Link} from 'react-router-dom';
import Axios from './database/axios.jsx';
import ReactHtmlParser from 'react-html-parser';

const Clients = () => {

    const fetchedCards = Axios({ path: '/clients' });
    const fetchedData = Axios({ path: '/section' });

  return (
    <>
      {fetchedData.map((clients) => (
        <>
            {clients.type === 'clients' && (
                <div className="clients__container" id='partners_scroll'>
                    <div className="container">
                        <div className="row">
                            <div className={clients.text_container}>
                                {ReactHtmlParser(clients.title)}
                            </div>
                            <div className={clients.image_container}>
                                {fetchedCards.map((client, index) => (
                                    <div className="" key={index}>
                                        <Link target="_blank"  to={client.link}>
                                            <img src={require(`../../icons/${client.icon_path.split('/').pop()}`)} alt="" />
                                        </Link>
                                    </div>
                                ))}
                            </div>
                            <div className={clients.class}>
                                <button className={clients.description}>See More </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
      ))}
    </>
  )
}

export default Clients