import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import ReactHtmlParser from 'react-html-parser';

// Set the app element for accessibility
Modal.setAppElement('#root');

function Sections() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [editedFile, setEditedFile] = useState(null);
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [input4, setInput4] = useState('');
  const [input5, setInput5] = useState('');
  const [input6, setInput6] = useState('');
  const [input7, setInput7] = useState('');
  const [fetchedData, setFetchedData] = useState([]);
  const [editValue, setEditValue] = useState('');
  const [editValue2, setEditValue2] = useState('');
  const [editValue3, setEditValue3] = useState('');
  const [editValue4, setEditValue4] = useState('');
  const [editValue5, setEditValue5] = useState('');
  const [editValue6, setEditValue6] = useState('');
  const [editValue7, setEditValue7] = useState('');
  const [editValue8, setEditValue8] = useState('');
  const [editValue9, setEditValue9] = useState('');
  const [editId, setEditId] = useState('');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileEdit = (event) => {
    setEditedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const uploadResponse = await axios.post('http://localhost:8800/banking_sections/upload', formData);
        const imagePath = uploadResponse.data.imagePath;

        const res = await axios.post('http://localhost:8800/banking_sections/database', {
          input1: input1,
          input2: input2,
          input3: input3,
          input4: input4,
          input5: input5,
          input6: input6,
          input7: input7,
          imagePath: imagePath,
        });
        console.log(res);
      } else {
        console.log('Please select a file.');
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:8800/banking_sections/show');
        setFetchedData(response.data);
        setIsDataFetched(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleClick = (value, value2, value3, value4, value5, value6, value7, value8, value9, id) => {
    setEditId(id);
    setEditValue(value);
    setEditValue2(value2);
    setEditValue3(value3);
    setEditValue4(value4);
    setEditValue5(value5);
    setEditValue6(value6);
    setEditValue7(value7);
    setEditValue8(value8);
    setEditValue9(value9);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (delete_id, delete_rel_id, delete_path, delete_orders) => {
    try {
      const response = await axios.delete('http://localhost:8800/banking_sections/deleted', {
        data: {
          delete_ids: delete_id,
          delete_rel_ids: delete_rel_id,
          delete_paths: delete_path,
          delete_orders: delete_orders,
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setEditValue(e.target.value);
  };
  const handleInputChange2 = (e) => {
    setEditValue2(e.target.value);
  };
  const handleInputChange3 = (e) => {
    setEditValue3(e.target.value);
  };
  const handleInputChange4 = (e) => {
    setEditValue4(e.target.value);
  };
  const handleInputChange5 = (e) => {
    setEditValue5(e.target.value);
  };
  const handleInputChange6 = (e) => {
    setEditValue6(e.target.value);
  };
  const handleInputChange7 = (e) => {
    setEditValue7(e.target.value);
  };
  const handleInputChange8 = (e) => {
    setEditValue8(e.target.value);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editedFile) {
        const formData = new FormData();
        formData.append('file', editedFile);
        formData.append('original_path', editValue9);
        formData.append('id', editId);

        const uploadEditResponse = await axios.post('http://localhost:8800/banking_sections/editImage', formData);
        const imagePath = uploadEditResponse.data.imagePath;

        const res = await axios.put('http://localhost:8800/banking_sections/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
          value3: editValue3,
          value4: editValue4,
          value5: editValue5,
          value6: editValue6,
          value7: editValue7,
          value8: editValue8,
          imagePath: imagePath,
        });
        console.log('Value edited:', res.data);
      } else {
        const res = await axios.put('http://localhost:8800/banking_sections/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
          value3: editValue3,
          value4: editValue4,
          value5: editValue5,
          value6: editValue6,
          value7: editValue7,
          value8: editValue8,
        });
        console.log('Value edited:', res.data);
      }
    } catch (error) {
    }
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };
  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false); 
  };

  return (
    <>
      <div className= "d-flex flex-row justify-content-between align-items-center container mb-4">
        <h1>Banking Sections</h1>
        <button className="btn btn-primary h-75" onClick={handleAddModalOpen}>Add Section</button>
      </div>
      {isDataFetched && (
        <div className="tableStyle">
          <table className="table">
            <thead className="sticky-header">
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Description</th>
                <th>Order</th>
                <th>Class</th>
                <th>Type</th>
                <th>Link</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {fetchedData.map((item) => (
                <tr key={item.id} className="section_rows">
                  <td>{item.id}</td>
                  <td className="truncate img_size">{ReactHtmlParser(item.title)}</td>
                  <td className="truncate">{item.description}</td>
                  <td>{item.orders}</td>
                  <td className="truncate">{item.class}</td>
                  <td>{item.type}</td>
                  <td className="truncate">{item.link}</td>
                  <td>
                    <button className="btn btn-secondary" onClick={() => handleClick(item.title, item.description, item.orders, item.img_container, item.text_container, item.class, item.type, item.link, item.image_path, item.id)}>
                      Edit
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-danger" type="submit" onClick={() => handleDelete(item.id, item.related_id, item.image_path, item.orders)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* EDIT SECTION MODAL */}
      <Modal
      isOpen={isEditModalOpen}
      onRequestClose={handleEditModalClose}
      contentLabel="Edit Modal"
      >
          <div className="">
              <div className="">
                  <div className="">
                      <form className='edit_form'>
                          <div>
                              <h5 className="pb-3 px-3">Edit Section</h5>
                          </div>

                          <div className=" px-4">
                              <div className="row mb-2">
                                  <div className="col-md-6">
                                      <label htmlFor="SecId" >Section ID</label>
                                      <input
                                          id="SecId"
                                          type="text"
                                          disabled
                                          readOnly
                                          value={editId}
                                          placeholder="Section Edit ID"
                                          className='form-control my-3'
                                      />
                                  </div>
                                  <div className="col-md-6">
                                      <label htmlFor="SecTitle">Section Title</label>
                                      <input
                                          id="SecTitle"
                                          type="text"
                                          value={editValue}
                                          onChange={handleInputChange}
                                          placeholder="Section Edit Title"
                                          className='form-control my-3'
                                      />
                                  </div>
                              </div>

                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SecDesc">Section Description</label>
                                          <input
                                              id="SecDesc"
                                              type="text"
                                              value={editValue2}
                                              onChange={handleInputChange2}
                                              placeholder="Section Edit Description"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SecOrder">Section Order</label>
                                          <input
                                              id="SecOrder"
                                              type="text"
                                              value={editValue3}
                                              onChange={handleInputChange3}
                                              placeholder="Section Edit Order"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SecIC">Section Image_container</label>
                                          <input
                                              id="SecIC"
                                              type="text"
                                              value={editValue4}
                                              onChange={handleInputChange4}
                                              placeholder="Section Edit Image_container"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SecTC">Section Text_container</label>
                                          <input
                                              id="SecTC"
                                              type="text"
                                              value={editValue5}
                                              onChange={handleInputChange5}
                                              placeholder="Section Edit Text_container"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SecClass">Section Class</label>
                                          <input
                                              id="SecClass"
                                              type="text"
                                              value={editValue6}
                                              onChange={handleInputChange6}
                                              placeholder="Section Edit Class"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SecType">Section Type</label>
                                          <input
                                              id="SecType"
                                              type="text"
                                              value={editValue7}
                                              onChange={handleInputChange7}
                                              placeholder="Section Edit Type"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SecLink">Section Link</label>
                                          <input
                                              id="SecLink"
                                              type="text"
                                              value={editValue8}
                                              onChange={handleInputChange8}
                                              placeholder="Section Edit Link"
                                              className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="SecImg">Image File</label>
                                          <input id="SecImg" type="file" onChange={handleFileEdit} 
                                          className='form-control my-3'/>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="px-4 d-flex justify-content-end gap-3">
                            <button className='btn btn-secondary' onClick={handleEditSubmit}>Submit Edit</button>
                            <button className='btn btn-danger' onClick={() => setIsEditModalOpen(false)}>Cancel</button>
                          </div> 
                      </form>
                  </div>
              </div>
          </div>
      </Modal>

      {/* ADD SECTION MODAL */}
      <Modal
        isOpen={isAddModalOpen}
        onRequestClose={handleAddModalClose}
        contentLabel="Add Modal"
      >
        
        <div className="">
          <div className="">
            <div className="">
              <form className='add_form'>
                <div>
                  <h5 className="pb-3 px-3">Add Section</h5>
                </div>

                <div className=" px-4">
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label htmlFor="addSecTitle">Section Title</label>
                      <input
                        id="addSecTitle"
                        type="text"
                        value={input1}
                        onChange={(e) => setInput1(e.target.value)}
                        placeholder="Section Title"
                        className='form-control my-3'
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="addSecDesc">Section Description</label>
                      <input
                        id="addSecDesc"
                        type="text"
                        value={input2}
                        onChange={(e) => setInput2(e.target.value)}
                        placeholder="Section Description"
                        className='form-control my-3'
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addSecSIC">Section Image_Container</label>
                        <input
                            id="addSecSIC"
                            type="text"
                            value={input3}
                            onChange={(e) => setInput3(e.target.value)}
                            placeholder="Section Image_container"
                            className='form-control my-3'
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                      <label htmlFor="addSecSTC">Section Text_Container</label>
                        <input
                            id="addSecSTC"
                            type="text"
                            value={input4}
                            onChange={(e) => setInput4(e.target.value)}
                            placeholder="Section Text_container"
                            className='form-control my-3'
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addSecClass">Section Class</label>
                        <input
                            id="addSecClass"
                            type="text"
                            value={input5}
                            onChange={(e) => setInput5(e.target.value)}
                            placeholder="Section Class"
                            className='form-control my-3'
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addSecType">Section Type</label>
                        <input
                            id="addSecType"
                            type="text"
                            value={input6}
                            onChange={(e) => setInput6(e.target.value)}
                            placeholder="Section Type"
                            className='form-control my-3'
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addSecLink">Section Link</label>
                        <input
                            id="addSecLink"
                            type="text"
                            value={input7}
                            onChange={(e) => setInput7(e.target.value)}
                            placeholder="Section Link"
                            className='form-control my-3'
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addSecImg">Image File</label>
                        <input id="addSecImg" type="file" onChange={handleFileChange} className='form-control my-3'/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 d-flex justify-content-end gap-3">
                  <button className='btn btn-secondary' onClick={handleSubmit}>Submit</button>
                  <button className='btn btn-danger' onClick={() => setIsAddModalOpen(false)}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Sections;
