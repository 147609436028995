import React, { useState } from 'react';
import { AiOutlineUser, AiOutlineLineChart } from 'react-icons/ai';
import { BsInboxes, BsGrid1X2, BsCollectionPlay } from 'react-icons/bs';
import { BiCarousel } from 'react-icons/bi';
import { HiOutlineDocumentDuplicate, HiOutlineUsers } from 'react-icons/hi';
import { TbSortAscendingNumbers , TbCarouselHorizontal} from 'react-icons/tb';
import { MdOutlineBusinessCenter, MdOutlinePermMedia, MdOutlineDynamicFeed} from 'react-icons/md';
import { TiBusinessCard } from 'react-icons/ti';
import { PiBank } from 'react-icons/pi';
import { FiSettings } from 'react-icons/fi';
import { VscGitPullRequestGoToChanges } from 'react-icons/vsc';
import Logo from '../img/full_logoC.png';
import Fade from 'react-reveal/Fade'

// Separate component for the Media dropdown
const MediaDropdown = ({ handleItemClick }) => {
  return (
    
      <ul>
        <li key="videos" onClick={() => handleItemClick('videos')}>
          <BsCollectionPlay /> Videos
        </li>
        <li key="documents" onClick={() => handleItemClick('documents')}>
          <HiOutlineDocumentDuplicate /> Documents
        </li>
      </ul>
    
  );
};
const BankingDropdown = ({ handleItemClick }) => {
  return (
    
      <ul>
        <li key="banking_carousel" onClick={() => handleItemClick('banking_carousel')}>
          <BiCarousel /> Carousel
        </li>
        <li key="banking_sections" onClick={() => handleItemClick('banking_sections')}>
          <BsGrid1X2 /> Sections
        </li>
        <li key="banking_slider" onClick={() => handleItemClick('banking_slider')}>
          <TbCarouselHorizontal /> Slider
        </li>
        <li key="banking_application" onClick={() => handleItemClick('banking_application')}>
          <FiSettings /> Application
        </li>
      </ul>
    
  );
};
const IndexDropdown = ({ handleItemClick }) => {
  return (
    
      <ul>
        <li
          key="sections"
          onClick={() => handleItemClick('sections')}
        >
          <BsGrid1X2 /> Sections
        </li>
        <li
          key="carousel"
          onClick={() => handleItemClick('carousel')}
        >
          <BiCarousel /> Carousel
        </li>
        <li
          key="clients"
          onClick={() => handleItemClick('clients')}
        >
          <HiOutlineUsers /> Clients
        </li>
        <li
          key="counter"
          onClick={() => handleItemClick('counter')}
        >
          <TbSortAscendingNumbers /> Counter
        </li>
        <li
          key="solutions"
          onClick={() => handleItemClick('solutions')}
        >
          <MdOutlineBusinessCenter /> Solutions
        </li>
        <li
          key="services"
          onClick={() => handleItemClick('services')}
        >
          <TiBusinessCard /> Services
        </li>
      </ul>
    
  );
};

const Sidebar = ({ handleClick }) => {
  const [activePage, setActivePage] = useState('sections');
  const [showMediaDropdown, setShowMediaDropdown] = useState(false);
  const [showIndexDropdown, setShowIndexDropdown] = useState(false);
  const [showBankingDropdown, setShowBankingDropdown] = useState(false);

  const handleItemClick = (page) => {
    setActivePage(page);
    handleClick(page);
  };

  const toggleMediaDropdown = () => {
    setShowMediaDropdown(!showMediaDropdown);
  };

  const toggleIndexDropdown = () => {
    setShowIndexDropdown(!showIndexDropdown);
  };
  const toggleBankingDropdown = () => {
    setShowBankingDropdown(!showBankingDropdown);
  };

  return (
    <div className="sidebar">
      <div className="sidebar_logo p-3 d-flex">
        <img src={Logo} alt="" className="w-75" />
      </div>
      <ul>
        <li
          key="dashboard"
          className={activePage === 'dashboard' ? 'active_page' : ''}
          onClick={() => handleItemClick('dashboard')}
        >
          <AiOutlineLineChart /> Dashboard
        </li>
        <li
          key="users"
          className={activePage === 'users' ? 'active_page' : ''}
          onClick={() => handleItemClick('users')}
        >
          <VscGitPullRequestGoToChanges /> Requests
        </li>
        <li
          key="index"
          className={showIndexDropdown ? 'active_page active_Index' : ''}
          onClick={toggleIndexDropdown}
        >
          <div className='d-flex gap-2 align-items-center'>
            <MdOutlineDynamicFeed /> Index
          </div>
          {showIndexDropdown && (
            <Fade><IndexDropdown handleItemClick={handleItemClick} /></Fade>
          )}
        </li>
        <li
          key="banking"
          className={showBankingDropdown ? 'active_page active_banking' : ''}
          onClick={toggleBankingDropdown}
        >
          <div className='d-flex gap-2 align-items-center'>
            <PiBank /> Banking
          </div>
          {showBankingDropdown && (
            <Fade><BankingDropdown handleItemClick={handleItemClick} /></Fade>
          )}
        </li>
        <li
          key="media"
          className={showMediaDropdown ? 'active_page active_media' : ''}
          onClick={toggleMediaDropdown}
        >
          <div className='d-flex gap-2 align-items-center'>
            <MdOutlinePermMedia /> Media
          </div>
          {showMediaDropdown && (
            <Fade><MediaDropdown handleItemClick={handleItemClick} /></Fade>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
