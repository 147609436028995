import React from 'react'
import DTO from '../../img/DTO/DTO1.jpg';
import Fade from 'react-reveal/Fade';


const Banner = () => {
  return (
    <>
    <div className='banner_img-dto text-white d-flex align-items-center justify-content-center' style={{ backgroundImage: `url(${DTO})`}}>
        <div className="banner_overlay-dto"></div>
        <div className='w-100 flex-column' style={{position: 'absolute', top: '13%'}}>
            <div className="container banner_content-dto flex-row">
                <div className="row">
                    <div className="col-md-6 left_side" style={{flex : '3', paddingRight: '6rem'}}>
                        <Fade left>
                            {/* <h1 style={{lineHeight : '4rem'}}> Solutions</h1> */}
                            <p style={{lineHeight : '2.5rem', width: '60%'}}>The 2023 Award winner Nehmé Y. Taouk (CEO & Founder, VALOORES, Lebanon) <br /> <br /> Advocacy and public policy engagement. Fostering social cohesion and inter-group dialogue and relationship-building in the workplace, marketplace and local community.</p>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Banner