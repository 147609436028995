import React from 'react'
import Banner from './Banner'
import GeospatialA from '../../img/homePage/lawImg.jpg'
import './Geospatial.css'
import Intro from './Intro'
import Nutshell from './Nutshell'
import Paralax from './Paralax'
import Services from './Services'
import Counter from './Counter'


const GeospatialI = () => {
  return (
    <>
        {/* <Navbar /> */}
        <Banner 
        img={GeospatialA}
        title='VCIS Architecture'
        description='Valoores app provides fast, secure, and offline analytics to turn data into actionable insights.'
        /> 
        <Intro />
        <Nutshell />
        <Paralax />
        <Services />
        <Counter />
        {/* <Footer /> */}
    </>
  )
}

export default GeospatialI
