import React from 'react'
import './NewsEvents.css'
import NewsEventsArary from './newsEventsArray'
import { Link } from 'react-router-dom'

const NewsEvents = () => {
    const limitWords = (text, wordLimit) => {
        const words = text.split(' ');
        return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
      };

  return (
    <div className='px-4 my-5'>
        {NewsEventsArary.map((newsEvents, index) => (
            // <div className={`d-flex ${index %2 === 0 ? 'flex-row' : 'flex-row-reverse'} align-items-center mb-5`}>
            <div className={`d-flex flex-row align-items-center mb-5`}>
                <div className="col-md-3 newsHeightImg" style={{backgroundImage: `url(${newsEvents.img})`}}></div>
                <div className="col-md-9 px-3">
                    <h5 className='m-0' style={{color: '#04406A'}}>{newsEvents.title}</h5>
                    <span style={{fontSize: '0.8rem'}}>{newsEvents.date}</span>
                    <p className='mt-2'>{limitWords(newsEvents.desc, 50)}</p>
                    {newsEvents.link !== '' && 
                        <Link to={newsEvents.link} className='btn btn-primary'>Read More</Link>
                    }
                </div>
            </div>
        ))}
    </div>
  )
}

export default NewsEvents