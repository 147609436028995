

const inProActive = [
    {
      title: "Overview",
      desc:
      (
        <>
     The conventional process of loan issuing goes through a series of steps before the financial institutions can start seeing some profits. Financial service providers currently face a pain caused by defaulting clients that decide to stop paying back their loans. Banks are required to establish a Collections Department tailor made to recover the unpaid portion of the defaulted loans.
      <br/>
      The degradation of an account from normally paying installments to defaulting is a step that should be avoided at all costs. Being able to tell ahead of time whether or not an account will cease the payments of his installment is a major privilege that financial institutions should aim to achieve, and use to their advantage.
      <br/>
      When financial institutions adopt an early default detection method with their clients, they can pro-actively resort to solutions to avoid the default. Solutions such as term rescheduling, adjusting the loan’s period, adjusting the interest rate, merging loans and many more significantly decrease the losses that occur because of default.
      <br/>
      VALOORES Pro-Active Debt Recovery solution uses state of the art Machine Learning algorithms to predict the possibility of an account’s transfer to the Collections Department.

        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
          'Allows the understanding of loan accounts’ behavior',
          'Illustrates what affects the transition of loan accounts into the Collections Department',
          'Prevents the transition of accounts to the Collections Department',
          'Allows taking action towards suspicious accounts that will transition into the Collection Department with a high probability',
          'Helps improve the payment behavior and therefore ameliorate the credit score of risky accounts',
          'Reduces the additional cost due to accounts transitioning into the Collections Department',
          
        ],
      },
      {
        title: "Key features",
        desc:'',
        list:
        [
          'Clean and pre-process the data selected, based on the origination date of the loan, the segment to which the loan belongs to and/or the product etc…',
          'Adjust the model’s hyper parameters according to the analysis of the user',
          'Feature engineer the input that the model will take into consideration',
          'Train, test, and validate the models on the appropriate data',
          'Diagnose systematic errors and underlying biases of the predictive models’ results, using SHAP explanation methods that include feature dependency analysis.',
          'Take action towards risky loans that show a high probability of going into the Collections Department',
            
        ],
      },
    
  ];
  
  export default inProActive;
  