
const InRevenue = [
    {
      title: "Overview",
      desc:
      (
        <>
            In a dynamic environment, in which consumer expectations and behavior are changing rapidly, communications, cloud and media service providers are faced with many business challenges. Yet, this environment provides more opportunity for innovation than ever before. To build strong brands and improve profitability, service providers globally rely on VALOORES In' Revenue Management and Billing to radically improve time to market for new services, and lower operational costs by managing, monetizing, and maximizing revenue streams for any customer type, service offering, partner relationship, payment method, business model, or geography.

       
            </>
      ),
      list: [''],
    },
    {
        title: "benefits",
        desc: ' ',
        list: [
        "Predict and target revenue opportunities",
        "Optimize product packaging and price",
        "Improve customer service through seamless and secure application access",
        "Maximize customer lifetime value",
        "Lower total cost of ownership",
          
        ],
      },
      {
        title: "features",
        desc:'',
        list:
        ([
            "Full support for end to end revenue management lifecycle from pricing to billing through collections",
            "On-demand billing, multi-parameter-based pricing, transaction feed management and data ingestion",
            "Highly scalable, available and extensible",
            "Sophisticated pricing, rating, product bundling, discounting, promotions and collections capability",
            "Single source of truth across all geographies and LOBs",
            
        ]),
    }
  ];
  
  export default InRevenue;
  