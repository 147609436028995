import React, { useState} from 'react';
import Fade from 'react-reveal/Fade';
import { Link,  useLocation  } from 'react-router-dom';
import { CiShare1 } from 'react-icons/ci';
import { BiChevronsRight } from 'react-icons/bi';

const Content = (props) => {
  let [activeItem, setActiveItem] = useState('Key Benefits');
  let [activeItemA, setActiveItemA] = useState('Transaction');
  let [activeItemI, setActiveItemI] = useState('Transaction Monitoring');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const receivedVariable = searchParams.get('variable');
  let content, menuVariable, activeMenu, topMenu, overviewSec;

  const handleItemClick = (index) => {
    setActiveItem(index);
    setActiveItemA(index);
    setActiveItemI(index);
  };


  const Ifrs9Menu = ['Key Benefits', 'Key Features', 'Case Study', 'Solution Preview'];
  const AmlMenu = ['Transaction', 'Reporting', 'Exploration', 'Processing', 'Management', 'Risk Based Approach'];
  const FraudMenu = ['Transaction Monitoring', 'Real-time scoring', 'Case Management', 'Single Fraud Platform', 'Case Studies', 'Customer Experience'];

  switch(receivedVariable) {
    case 'IFRS9':
    menuVariable = Ifrs9Menu
    activeMenu = activeItem
    topMenu = 'yes'
    overviewSec = 'yes'
    switch (activeItem) {
      case 'Key Benefits':
        content = (
          <>
              {props.RiskArray.map((item, index) => (
                <Fade >
                    <span key={index}>
                      <ul className='m-0 mb-4 px-3'>
                        {item.benefits.map((benefit, innerIndex) => (
                          <li key={innerIndex}>{benefit}</li>
                        ))}
                      </ul>
                      <div className='mb-5'>{item.conclusion}</div>
                    </span>
                </Fade>
              ))}
          </>
        );
      break;
      case 'Key Features':
          content = (
          <>
            {props.RiskArray.map((item, index) => (
              <Fade >
                <div key={index} className='m-0 mb-4 px-3'>
                    <ul className='m-0 p-0'>
                    {item.features.map((feature, innerIndex) => (
                        <li key={innerIndex}>
                        <h5>{feature.title}</h5>
                        <ul>
                            {feature.list.map((subItem, subIndex) => (
                            <li key={subIndex}>
                                {subItem.subTitle !== '' ? (
                                <>
                                    <h6>{subItem.subTitle}</h6>
                                    <ul>
                                    {subItem.subList.map((subsubItem, subsubIndex) => (
                                        <li key={subsubIndex}>{subsubItem}</li>
                                    ))}
                                    </ul>
                                </>
                                ) : (
                                <span>
                                    {subItem.subList.map((subsubItem, subsubIndex) => (
                                      <li key={subsubIndex}>{subsubItem}</li>
                                    ))}
                                </span>
                                )}
                            </li>
                            ))}
                        </ul>
                        </li>
                    ))}
                    </ul>
                </div>
              </Fade>
            ))}
          </>
          );
      break;
        
      case 'Case Study':
          content = (
          <>
              {props.RiskArray.map((item, index) => (
                <Fade >
                  <div key={index} className='m-0 mb-4 px-3'>
                  {item.caseStudy.map((caseS, innerIndex) => (
                      <div key={innerIndex}>
                      <p>{caseS.introduction}</p>
                      {caseS.title && <p className='fw-bold'>{caseS.title}</p>}
                      
                          <>
                          {caseS.heading.title && <p>{caseS.heading.title}</p>}
                          {Array.isArray(caseS.heading.titleList) && (
                              <ul>
                              {caseS.heading.titleList.map((title, titleIndex) => (
                                  <span key={titleIndex}>
                                      {Array.isArray(title.links) && (
                                          <span>
                                              {title.mainTitle.map((subMain, subtitleIndex) => (
                                                  <li key={subtitleIndex}>{subMain}</li>
                                              ))}
                                          </span>
                                      )}
                                      <ul>

                                      
                                  {typeof title === 'object' && title.name && (
                                      <li>{title.name}</li>
                                  )}
                                  {Array.isArray(title.links) && (
                                      <ul>
                                      {title.links.map((subtitle, subtitleIndex) => (
                                          <span key={subtitleIndex}>
                                          <li>{subtitle}</li>
                                          </span>
                                      ))}
                                      </ul>
                                  )}
                                  </ul>
                                  </span>
                              ))}
                              </ul>
                          )}
                          </>
                      {caseS.conclusion && <p>{caseS.conclusion}</p>}
                      </div>
                  ))}
                  </div>
                </Fade>
              ))}
          </>
      );
      break;

        

      case 'Solution Preview':
          content = ( 
              <>
              {props.RiskArray.map((item, index) => (
                  <Fade >
                    <img src={item.solution} className='mb-5 w-100' alt=""/>
                  </Fade>
              ))}
              </>
          )
        break;
      default:
        content=(
          <h1>Default</h1>
        )
        break;
    }
    break;

    case 'infraud':
    menuVariable = FraudMenu
    activeMenu = activeItemI
    topMenu = 'yes'
    overviewSec = 'yes'
    switch (activeItemI) {
      case 'Transaction Monitoring':
        content = (
          <>
            {props.RiskArray.map((item, index) => (
              <div key={index}>
                {item.transaction && (
                  <>
                    <span className='fw-bold' style={{color: '#007ac1'}}>{item.transaction[0].title}</span>
                    <div className='mt-3'>{item.transaction[0].desc}</div>
                    <ul>
                      {item.transaction[0].list.map((transListItem, transListIndex) => (
                        <li className='my-3' key={transListIndex}>{transListItem}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            ))}
          </>
        );
      break;

      case 'Real-time scoring':
        content = (
          <>
            <div className='mb-5'>
              {props.RiskArray.map((item, index) => (
                <div key={index}>
                  {item.real && (
                    <>
                      <span className='fw-bold' style={{color: '#007ac1'}}>{item.real[0].title}</span>
                      <div className='mt-3'>{item.real[0].desc}</div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </>
        );
      break;

      case 'Case Management':
        content = (
          <>
            <div className='mb-5'>
              {props.RiskArray.map((item, index) => (
                <div key={index}>
                  {item.management && (
                    <>
                      <span className='fw-bold' style={{color: '#007ac1'}}>{item.management[0].title}</span>
                      <div className='mt-3'>{item.management[0].desc}</div>
                      <ul>
                        {item.management[0].list.map((managListItem, managListIndex) => (
                          <li className='my-3' key={managListIndex}>{managListItem}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              ))}
            </div>
          </>
        );
      break;

      case 'Single Fraud Platform':
        content = (
          <>
            <div className='mb-5'>
              {props.RiskArray.map((item, index) => (
                <div key={index}>
                  {item.single && (
                    <>
                      <span className='fw-bold' style={{color: '#007ac1'}}>{item.single[0].title}</span>
                      <div className='mt-3'>{item.single[0].desc}</div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </>
        );
      break;

      case 'Case Studies':
        content = (
          <>
            <div className='mb-5'>
              {props.RiskArray.map((item, index) => (
                <div key={index}>
                  {item.case && (
                    <>
                      <span className='fw-bold' style={{color: '#007ac1'}}>{item.case[0].title}</span>
                      <div className='mt-3'>{item.case[0].desc}</div>
                      <ul>
                        {item.case[0].list.map((caseListItem, caseListIndex) => (
                          <li className='my-3' key={caseListIndex}>{caseListItem}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              ))}
            </div>
          </>
        );
      break;

      case 'Customer Experience':
        content = (
          <>
            <div className='mb-5'>
              {props.RiskArray.map((item, index) => (
                <div key={index}>
                  {item.experience && (
                    <>
                      <span className='fw-bold' style={{color: '#007ac1'}}>{item.experience[0].title}</span>
                      <div className='mt-3'>{item.experience[0].desc}</div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </>
        );
      break;
        
      default:
        content=(
          <h1>Default</h1>
        )
        break;
    }
    break;

    case 'taxcompliance':
      content = (
        <>
          {props.RiskArray.map((item, index) => (
            <div key={index} >
              <span className='fw-bold' style={{color: '#007ac1'}}><h4>{item.title}</h4></span>
              {item.section.map((secItem, secIndex) => (
                <div key={secIndex} className='mt-3'>
                  <strong className='my-3'>{secItem.title}</strong>
                  <p className='mt-3'>{secItem.desc}</p>
                  <ul>
                    {secItem.list.map((listItem, secIndex) => (
                      <li key={secIndex}>{listItem}</li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
          ))}
        </>
      );
    break;

    case 'basel':
      content = (
        <>
          {props.RiskArray.map((item, index) => (
            <div key={index} >
              <span className='fw-bold' style={{color: '#007ac1'}}><h6>{item.title}</h6></span>
              <p>{item.desc}</p>
              <ul className='mb-5'>
                {item.list.map((secItem, secIndex) => (
                  <li key={secIndex}>{secItem}</li>
                ))}
              </ul>
          </div>
          ))}
        </>
      );
    break;

    case 'baselregulatory':
      content = (
        <>
          {props.RiskArray.map((item, index) => (
            <div key={index} >
              <span className='fw-bold' style={{color: '#007ac1'}}><h6>{item.title}</h6></span>
              <p>{item.desc}</p>
              <ul className='mb-5'>
                {item.list.map((secItem, secIndex) => (
                  <li key={secIndex}>{secItem}</li>
                ))}
              </ul>
          </div>
          ))}
        </>
      );
    break;

    case 'liquidityriskmanagement':
      content = (
        <>
          {props.RiskArray.map((item, index) => (
            <div key={index} >
              <span className='fw-bold' style={{color: '#007ac1'}}><h6>{item.title}</h6></span>
              <ul className='mb-5'>
                {item.list.map((secItem, secIndex) => (
                  <li key={secIndex}>{secItem}</li>
                ))}
              </ul>
          </div>
          ))}
        </>
      );
    break;

    case "riskandbusinessprocessconsulting":
      content = (
        <>
          {props.RiskArray.map((item, index) => (
            <div key={index} >
              <span className='fw-bold' style={{color: '#007ac1'}}><h6>{item.title}</h6></span>
              <p>{item.desc}</p>
              <ul className='mb-5'>
                {item.list.map((secItem, secIndex) => (
                  <li key={secIndex}>{secItem}</li>
                ))}
              </ul>
          </div>
          ))}
        </>
      );
    break;

    case "enterpriseincidentmanagement":
      content = (
        <>
          {props.RiskArray.map((item, index) => (
            <div key={index} >
              <span className='fw-bold' style={{color: '#007ac1'}}><h6>{item.title}</h6></span>
              <p>{item.desc}</p>
              <ul className='mb-5'>
                {item.list.map((secItem, secIndex) => (
                  <li key={secIndex}>{secItem}</li>
                ))}
              </ul>
          </div>
          ))}
        </>
      );
    break;

    case "itgovernance":
      content = (
        <>
          {props.RiskArray.map((item, index) => (
            <div key={index} >
              <span className='fw-bold' style={{color: '#007ac1'}}><h6>{item.title}</h6></span>
              <p>{item.desc}</p>
              <ul className='mb-5'>
                {item.list.map((secItem, secIndex) => (
                  <li key={secIndex}>{secItem}</li>
                ))}
              </ul>
          </div>
          ))}
        </>
      );
    break;

    case "operationalrisk":
      content = (
        <>
          {props.RiskArray.map((item, index) => (
            <div key={index} >
              <span className='fw-bold' style={{color: '#007ac1'}}><h6>{item.title}</h6></span>
              <p>{item.desc}</p>
              <ul className='mb-5'>
                {item.list.map((secItem, secIndex) => (
                  <li key={secIndex}>{secItem}</li>
                ))}
              </ul>
          </div>
          ))}
        </>
      );
    break;

    case 'Aml':
      menuVariable = AmlMenu
      activeMenu = activeItemA
      topMenu = 'yes'
      switch (activeItemA) {
          case 'Transaction':
            content = (
              <>
                {props.RiskArray.map((item, index) => (
                  <Fade >
                    <span key={index}>
                        {item.transaction.map((trans, transIndex) => (
                          <div className='d-flex flex-column gap-3'>
                            <span key={transIndex} className='fw-bold' style={{color: '#007ac1'}}>{trans.title}</span>
                            <span className='fw-bold'>{trans.headline}</span>
                            <p>{trans.desc}</p>
                          </div>
                        ))}
                    </span>
                  </Fade>
                ))}
              </>
            )
          break;

          case 'Reporting':
            content = (
              <>
                {props.RiskArray.map((item, index) => (
                  <Fade >
                    <span key={index}>
                        {item.reporting.map((repo, repoIndex) => (
                          <div className='d-flex flex-column gap-3'>
                            <span key={repoIndex} className='fw-bold' style={{color: '#007ac1'}}>{repo.title}</span>
                            <span className='fw-bold'>{repo.headline}</span>
                            <p>{repo.desc}</p>
                          </div>
                        ))}
                    </span>
                  </Fade>
                ))}
              </>
            )
          break;

          case 'Exploration':
            content = (
              <>
                {props.RiskArray.map((item, index) => (
                  <Fade >
                    <span key={index}>
                        {item.exploration.map((explo, exploIndex) => (
                          <div className='d-flex flex-column gap-3'>
                            <span key={exploIndex} className='fw-bold' style={{color: '#007ac1'}}>{explo.title}</span>
                            <span className='fw-bold'>{explo.headline}</span>
                            <p>{explo.desc}</p>
                          </div>
                        ))}
                    </span>
                  </Fade>
                ))}
              </>
            )
          break;

          case 'Processing':
            content = (
              <>
                {props.RiskArray.map((item, index) => (
                  <Fade >
                    <span key={index}>
                        {item.processing.map((process, processIndex) => (
                          <div className='d-flex flex-column gap-3'>
                            <span key={processIndex} className='fw-bold' style={{color: '#007ac1'}}>{process.title}</span>
                            <span className='fw-bold'>{process.headline}</span>
                            <p>{process.desc}</p>
                          </div>
                        ))}
                    </span>
                  </Fade>
                ))}
              </>
            )
          break;

          case 'Management':
            content = (
              <>
                {props.RiskArray.map((item, index) => (
                  <Fade >
                    <span key={index}>
                        {item.management.map((management, managementIndex) => (
                          <div className='d-flex flex-column gap-3'>
                            <span key={managementIndex} className='fw-bold' style={{color: '#007ac1'}}>{management.title}</span>
                            <span className='fw-bold'>{management.headline}</span>
                            <p>{management.desc}</p>
                          </div>
                        ))}
                    </span>
                  </Fade>
                ))}
              </>
            )
          break;

          case 'Risk Based Approach':
            content = (
              <>
                <div>
                  {props.RiskArray.map((item, index) => (
                    <div key={index}>
                      {item.risk.map((riskItem, riskIndex) => (
                        <>
                          <div key={riskIndex}>
                            <span className='fw-bold ' style={{color: '#007ac1'}}>{riskItem.title}</span>
                            <p className='mt-3'>{riskItem.desc}</p>
                          </div>
                          {riskItem.body.map((riskBodyItem, riskBodyIndex) => (
                            <div key={riskBodyIndex}>
                              <span className='fw-bold ' style={{color: '#007ac1'}}>
                                {riskBodyItem.title}
                              </span>
                              <div className="row mt-3">
                                {riskBodyItem.headline.map((headlineItem, riskHeadlineIndex) => (
                                  <div className="col-md-6">
                                    <div key={riskHeadlineIndex} >
                                      <span className='fw-bold'>{headlineItem.title}</span>
                                    </div>
                                    <ul >
                                    {headlineItem.list.map((listItem, riskListIndex) => (
                                      <li className='mt-3' key={riskListIndex}>{listItem}</li>
                                    ))}
                                    </ul>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </>
                      ))}
                    </div>
                  ))}
                </div>
              </>
            )
          break;

          default:
            content=(
              <h1>Default</h1>
            )
        break;
      }

    };
    
  return (
    <>
      {overviewSec === 'yes' && 
        <>
          {props.RiskArray.map((item, index) => (
            <div key={index} className='mb-3'>
              <Fade bottom>
                <div className='row'>
                  <p className='col-md-12' style={{ paddingRight: '3rem' }}>
                    {item.overview}
                  </p>
                  {receivedVariable === 'infraud' && 
                  <div>
                    <span className='fw-bold mt-3' style={{color: '#007ac1'}}>Key Benefits</span>
                    <ul className='mt-3'>  
                      {item.benefits.map((benefItem, benefIndex) => (
                        <li key={benefIndex}>{benefItem}</li>
                      ))}
                    </ul>
                    <span className='fw-bold mt-3' style={{color: '#007ac1'}}>Key Features</span>
                    <ul className='mt-3'>  
                      {item.features.map((featItem, featIndex) => (
                        <li key={featIndex}>{featItem}</li>
                      ))}
                    </ul>
                  </div>
                  }
                </div>
              </Fade>
            </div>
          ))}
        </>
      }
      {receivedVariable === 'Aml' &&
        <>
          {props.RiskArray.map((item, index) => (
            <div key={index}>
              {item.overview}
            </div>
          ))}
        </>
      }
      {topMenu === 'yes' && 
        <div className='risk_bar-btns mb-4'>
          <ul className='d-flex flex-row gap-1 p-0 m-0 risk_bar-list' style={{ listStyle: 'none' }}>
            {menuVariable.map((item, idx) => (
              <li key={idx} className={activeMenu === item ? 'active' : ''} onClick={() => handleItemClick(item)}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      }
      {content}
      
      {receivedVariable === 'IFRS9' &&
        <Link to='' target='_blank' className='d-flex flex-row gap-1 align-items-center mb-5' style={{ textDecoration: 'none', fontSize: '14px' }}>
          <CiShare1 />
          Visit Summit Webpage <BiChevronsRight />
        </Link>
      }
    </>
  );
};

export default Content;
