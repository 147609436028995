import React, { useState, useEffect } from 'react';
import LogoWhite from '../../assets/img/logoWhite.png';
import './menu.css';
import { Link, NavLink } from 'react-router-dom';
import { IoIosSearch } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa6";
import AboutUsSubMenu from './AboutUsSubMenu'

const Menu = ({currentPath}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 150) { 
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleAboutUs = () => {
    setIsAboutUsOpen(!isAboutUsOpen);
  };

  const specialPaths = [
    '/Valoores103A/Consultancy',
    '/Valoores103A/Solutions/Vfds',
    '/Valoores103A/Industries/',
  ];

console.log(currentPath);


  return (
    <nav className={`d-flex flex-row align-items-center justify-content-between p-3 px-5 shadow-lg ${isScrolled ? 'active' : ''} ${specialPaths.includes(currentPath) ? 'active' : ''}`} style={{borderBottom: '1px solid rgba(255,255,255,0.2)'}}>
        <div className="nav_logo">
          <Link to='/Valoores103A/'>
            <img src={LogoWhite} alt="" className='w-100' />
          </Link>
        </div>

        {/* Desktop Menu */}
        <menu className="nav_menu w-100 px-0 mx-5 d-none d-md-flex">
            <ul className='d-flex flex-row align-items-center py-0 m-0'>
                <li>
                    <NavLink className={`${currentPath.includes('/Valoores103A/Solutions') && 'activeLink'}`} to='/Valoores103A/Solutions' target='_blank'>Solutions</NavLink>
                </li>
                <li>
                    <NavLink className={`${currentPath.includes('/Valoores103A/Industries') && 'activeLink'}`} to='/Valoores103A/Industries' target='_blank'>Industries</NavLink>
                </li>
                <li>
                  <NavLink className={`${currentPath.includes('/Valoores103A/Technology') && 'activeLink'}`} to='/Valoores103A/Technology' target='_blank'>Technology</NavLink>
                </li>
                <li>
                    <NavLink className={`${currentPath.includes('/Valoores103A/Consultancy') && 'activeLink'}`} to='/Valoores103A/Consultancy' target='_blank'>Consultancy</NavLink>
                </li>
                <li className="about-us position-relative" onMouseEnter={toggleAboutUs} onMouseLeave={toggleAboutUs}>
                  <Link className='d-flex flex-row align-items-center gap-2 selected'>
                    About Us
                    <FaChevronDown size={15} fill ="#fff" className={`chevron ${isAboutUsOpen ? 'rotate' : ''}`} />
                  </Link>
                  {isAboutUsOpen && 
                    <AboutUsSubMenu isAboutUsOpen={isAboutUsOpen} isScrolled={isScrolled}/>
                  }
                </li>
            </ul>
        </menu>

        {/* Mobile Menu Toggle */}
        <div className="nav_hamburger d-md-none" onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? 
        (
          <IoCloseSharp size={30} color="white" />
        ) : (
          <GiHamburgerMenu size={30} color="white" />
        )
        }
        </div>

        {/* Mobile Menu */}
        <menu className={`mobile_menu1 m-0 px-0 d-lg-none ${isMobileMenuOpen ? 'open' : ''}`}>
            <ul className='d-flex flex-column'>
                <li>
                    <NavLink to='/Valoores103A/Solutions/' onClick={toggleMobileMenu}  activeClassName="active">Solutions</NavLink>
                </li>
                <li>
                    <NavLink to='/Valoores103A/Industries/' onClick={toggleMobileMenu}  activeClassName="active">Industries</NavLink>
                </li>
                <li>
                <NavLink className='selected' to='/Valoores103A/Technology/' onClick={toggleMobileMenu} activeClassName="active">Technology</NavLink>
                </li>
                <li>
                    <NavLink to='/Valoores103A/Consultancy/' onClick={toggleMobileMenu}  activeClassName="active">Consultancy</NavLink>
                </li>
                <li><Link onClick={toggleMobileMenu}>About Us</Link></li>
            </ul>
        </menu>

        <div className="nav_search">
            <IoIosSearch color='white' size={30}/>
        </div>
    </nav>
  )
}

export default Menu;
