import React from 'react'
import Banner from '../Banner';
import BannerImg from '../../img/management/biometrics_banner.jpg';
import Face1Img from '../../img/management/bio_screen-1.jpg';
import Face2Img from '../../img/management/bio_screen-2.jpg';
import BiometricsArray from './BiometricsArray';
import ParalaxCase from './ParalaxCase'; 


const CaseBiometrics = () => {
  return (
    <>
      <Banner
        img={BannerImg}
        title={
          <div className='text-center'>
            Create a Future <br /> With The Biometric World
          </div>
        }
      /> 
        <div className="my-5 d-flex flex-column px-5" >
            <div>
                <h1 className='text-center'> Login Screen & Biometrics </h1>
            </div>
            <div className="my-5 d-flex flex-row gap-3 justify-content-between align-items-center">
                <div className='w-50'>
                    <p style={{lineHeight: '2.5rem'}}>
                        Enter the username and password that were communicated to you. The password should be at least composed of 8 characters including numbers, capital letters and symbols to be more secured. Depending on the user’s role, the Advanced Access procedure will be mandatory opening the biometrics security screen. Biometric security is a security mechanism that identifies people by verifying their physical or behavioral characteristics. It is currently the strongest and most accurate physical security technique that is used for identity verification. Face recognition is capable of verifying the identity of person using facial expression.
                    </p>
                </div>
                <div className='w-50 d-flex flex-column justify-content-center align-items-center gap-4'>
                        <img src={Face1Img} className='w-50' alt="" />
                        <img src={Face2Img} className='w-50' alt="" />
                </div>
            </div>
        </div>
        <div className="d-flex flex-row align-items-center flex-wrap justify-content-between gap-3">
        {BiometricsArray.map((CaseBiometrics, index) => (
          <div key={index} className="d-flex flex-column justify-content-center align-items-center mt-5">
            {index % 2 === 0 ?
              <div className='px-5'>
                <div className='mb-3 text-center'>
                  <h2>{CaseBiometrics.title}</h2>
                </div>
                <div className=" d-flex flex-row justify-content-between gap-3 align-items-center">
                  <div className='w-50 px-3'>
                    <p className='column_2'>{CaseBiometrics.desc}</p>
                  </div>
                  <div className='w-50 d-flex flex-row align-items-center justify-content-center'>
                    <img src={CaseBiometrics.img} className='w-85' alt={CaseBiometrics.title} />
                  </div>
                </div>
            </div> : 
            <div className='px-5'>
                <div className='my-4 text-center'>
                  <h2>{CaseBiometrics.title}</h2>
                </div>
                <div className=" d-flex flex-row justify-content-center align-items-center">
                <div className='w-50 d-flex flex-row align-items-center justify-content-center'>
                    <img src={CaseBiometrics.img} className='w-85' alt={CaseBiometrics.title} />
                </div>
                <div className='w-50 px-3'>
                    <p className='column_2'>{CaseBiometrics.desc}</p>
                </div>
                </div>
            </div>}
            {(index === 0 || index === 2) && <ParalaxCase img={BannerImg} />}
          </div>

        ))}
      </div>

    </>
  )
}

export default CaseBiometrics
