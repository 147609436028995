import Client1 from "../../img/clients/abl.png"


const ClientArr =
[
    {
        img: {Client1},
        title: "AML",
        desc: "AML client"
    },
    {
        img: {Client1},
        title: "AML",
        desc: "AML client"
    },
    {
        img: {Client1},
        title: "AML",
        desc: "AML client"
    },
    {
        img: {Client1},
        title: "AML",
        desc: "AML client"
    },
    {
        img: {Client1},
        title: "AML",
        desc: "AML client"
    },
]

export default ClientArr