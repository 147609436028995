import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

// Set the app element for accessibility
Modal.setAppElement('#root');

function Solutions() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [editedFile, setEditedFile] = useState(null);
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [fetchedData, setFetchedData] = useState([]);
  const [editValue, setEditValue] = useState('');
  const [editValue2, setEditValue2] = useState('');
  const [editValue3, setEditValue3] = useState('');
  const [editValue4, setEditValue4] = useState('');
  const [editId, setEditId] = useState('');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileEdit = (event) => {
    setEditedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const uploadResponse = await axios.post('http://localhost:8800/main_cards/upload', formData);
        const imagePath = uploadResponse.data.imagePath;

        const res = await axios.post('http://localhost:8800/main_cards/database', {
          input1: input1,
          input2: input2,
          input3: input3,
          imagePath: imagePath,
        });
      } else {
        console.log('Please select a file.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('http://localhost:8800/main_cards/show');
      setFetchedData(response.data);
      setIsDataFetched(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (value, value2, value3, path, id) => {
    setEditId(id);
    setEditValue(value);
    setEditValue2(value2);
    setEditValue3(value3);
    setEditValue4(path);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (delete_id, delete_rel_id, delete_path) => {
    try {
      const response = await axios.delete('http://localhost:8800/main_cards/deleted', {
        data: {
          delete_ids: delete_id,
          delete_rel_ids: delete_rel_id,
          delete_paths: delete_path,
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setEditValue2(e.target.value);
  };

  const handleInputChange3 = (e) => {
    setEditValue3(e.target.value);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editedFile) {
        const formData = new FormData();
        formData.append('file', editedFile);
        formData.append('original_path', editValue4);
        formData.append('id', editId);

        const uploadEditResponse = await axios.post('http://localhost:8800/main_cards/editImage', formData);
        const imagePath = uploadEditResponse.data.imagePath;

        const res = await axios.put('http://localhost:8800/main_cards/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
          value3: editValue3,
        });
        console.log('Value edited:', res.data);
      } else {
        const res = await axios.put('http://localhost:8800/main_cards/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
          value3: editValue3,
        });
        console.log('Value edited:', res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };

  return (
    <div>
      {/* <div className= "d-flex flex-row justify-content-between align-items-center container mb-4">
        <h1>Solutions</h1>
        <button className="btn btn-primary" onClick={handleAddModalOpen}>
          Add Solution
        </button>
      </div> */}
      <div>
        {isDataFetched && (
          <div className="tableStyle">
          <table className="table">
            <thead className="sticky-header">
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Description</th>
                <th>Link</th>
                <th>Image Path</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {fetchedData.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.title}</td>
                  <td className="truncate">{item.description}</td>
                  <td>{item.link}</td>
                  <td className="truncate">{item.image_path}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      to="#"
                      onClick={() =>
                        handleClick(
                          item.title,
                          item.description,
                          item.link,
                          item.image_path,
                          item.id
                        )
                      }
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={() =>
                        handleDelete(
                          item.id,
                          item.related_id,
                          item.image_path
                        )
                      }
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        )}
      </div>
      {/* EDIT SOLUTION MODAL */}
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={handleEditModalClose}
        contentLabel="Edit Modal"
      >
          <div className="">
						<div className="">
							<div className="">
								<form className='edit_form'>
									<div>
										<h5 className="pb-3 px-3">Edit Solution</h5>
									</div>

									<div className=" px-4">
										<div className="row mb-2">
											<div className="col-md-6">
                        <label htmlFor="SolId" >Solution ID</label>
                        <input
                          id="SolId"
                          type="text"
                          disabled
                          readOnly
                          value={editId}
                          placeholder="Solution Edit ID"
                          className='form-control my-3'
                        />
											</div>
											<div className="col-md-6">
                        <label htmlFor="SolTitle">Solution Title</label>
                        <input
                          id="SolTitle"
                          type="text"
                          value={editValue}
                          onChange={handleInputChange}
                          placeholder="Solution Edit Title"
                          className='form-control my-3'
                        />
											</div>
										</div>
										
										<div className="row">
											<div className="col-md-6">
												<div className="mb-4">
                          <label for="SolDesc">Solution Description</label>
                          <input
                            id="SolDesc"
                            type="text"
                            value={editValue2}
                            onChange={handleInputChange2}
                            placeholder="Solution Edit Description"
                            className='form-control my-3'
                          />
												</div>
											</div>
											<div className="col-md-6">
												<div className="mb-4">
                          <label htmlFor="SolLink">Solution Link</label>
                          <input
                            id="SolLink"
                            type="text"
                            value={editValue3}
                            onChange={handleInputChange3}
                            placeholder="Solution Edit Link"
                            className='form-control my-3'
                          />
												</div>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-lg-6">
												<div>
                          <label htmlFor="SolImg">Image File</label>
                          <input id="SolImg" type="file" onChange={handleFileEdit} 
                          className='form-control my-3'/>
                          
												</div>
											</div>
										</div>
									</div>

									<div className="px-4 d-flex justify-content-end gap-3">
                      <button className='btn btn-secondary' onClick={handleEditSubmit}>Submit Edit</button>
                      <button className='btn btn-danger' onClick={() => setIsEditModalOpen(false)}>Cancel</button>
                  </div> 
								</form>
							</div>
						</div>
					</div>
      </Modal>
      {/* ADD SOLUTION MODAL */}
      <Modal
        isOpen={isAddModalOpen}
        onRequestClose={handleAddModalClose}
        contentLabel="Add Modal"
      >
        <div className="">
          <div className="">
            <div className="">
              <form className='add_form'>
                <div>
                  <h5 className="pb-3 px-3">Add Solution</h5>
                </div>

                <div className=" px-4">
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label htmlFor="addSolTitle">Solution Title</label>
                      <input
                        id="addSolTitle"
                        type="text"
                        value={input1}
                        onChange={(e) => setInput1(e.target.value)}
                        placeholder="Solution Title"
                        className='form-control my-3'
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="addSolDesc">Solution Description</label>
                      <input
                        id="addSolDesc"
                        type="text"
                        value={input2}
                        onChange={(e) => setInput2(e.target.value)}
                        placeholder="Solution Description"
                        className='form-control my-3'
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addSolLink">Solution Link</label>
                        <input
                          id="addSolLink"
                          type="text"
                          value={input3}
                          onChange={(e) => setInput3(e.target.value)}
                          placeholder="Solution Link"
                          className='form-control my-3'
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addCardImg">Image File</label>
                        <input id="addCardImg" type="file" onChange={handleFileChange} className='form-control my-3'/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 d-flex justify-content-end gap-3">
                    <button className='btn btn-secondary' onClick={handleSubmit}>Submit</button>
                    <button className='btn btn-danger' onClick={() => setIsAddModalOpen(false)}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Solutions;
