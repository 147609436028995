import InMarketing1 from '../../../../img/inmarketing/inMarketing1.jpg'
import InMarketing2 from '../../../../img/inmarketing/inMarketing2.jpg'
import InMarketing3 from '../../../../img/inmarketing/inMarketing3.jpg'


const InMarketing = [
    {
      img: InMarketing1,
      title: "in'Campaign Management",
      desc: "Get the flexibility you need to keep up with evolving customer expectations, demographics, and demand – with VALOORES in'Campaign. Boost profit margins across all channels and easily overcome challenges such as higher capital requirements,...",
      link: "../banking/inMarketing?variable=InCampaignManagement",
      button: 'yes',
    },
    {
      img: InMarketing2,
      title: "in'Pricing Management",
      desc: "One of the most important aspects of companies operations is pricing, whether it is a product or a service that you're intending to sell, choosing right price can make all the difference especially when it comes...",
      link: "../banking/inMarketing?variable=InPricingManagement",
      button: 'yes',
      
    },
    {
      img: InMarketing3,
      title: "in'Deals - Procurement & Contracts",
      desc: "With the diversity of suppliers and the rapid growth of competition, optimizing the supplier deal negotiations has become one of the greatest opportunities for profit improvement...",
      link: "../banking/inMarketing?variable=InDeals",
      button: 'yes',

    },
  ]

  export default InMarketing