import React from 'react';
import Carousel from '../Banking/Carousel';
import Introduction from './Introduction';
import Sections from './Sections';
import Choice from './Choice';
import Paralax from './Paralax';
import Application from './Application';
// import News from './News';
import Fade from 'react-reveal/Fade'
// import dashboardImg from '../../img/dashboardParalax.jpg'0
// import paralax2 from '../../img/paralax2.jpg'
import { Helmet } from 'react-helmet';


const Banking = () => {
 
  return (
    <>
      <Helmet>
        <title>Banking</title>
      </Helmet>
      <Carousel />
      <Fade bottom>
        <Introduction />
      </Fade>
        <Sections />
        <Choice />
        <Paralax type= 'paralax' laptop= 'true' height='50rem' top = '0%'/>
        <Application />
        <Paralax type= 'paralax2' laptop= 'false' height='30rem' top='30%'/>
        {/* <News /> */}
    </>
  );
};

export default Banking;
