import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import LeaderImg from '../img/banners/leader.jpg';
import Banner from './Banner';
import AboutContent from './AboutContent';
import Sidebar from './SidebarAbout';

const AboutUs = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const receivedVariable = searchParams.get('variable');
  const [selected, setSelected] = useState(receivedVariable);
  let selectedVariableTitle;
  let selectedVariableDesc;

  if (receivedVariable === 'Contact'){
    if (receivedVariable === selected){
        selectedVariableTitle = 'Contact US';
        // selectedVariableDesc = 'Contact'
    }else{
        selectedVariableTitle = selected;
        // selectedVariableDesc = selected
    }
  } else if (receivedVariable === 'Ceo' ){
    if (receivedVariable === selected){
        selectedVariableTitle = 'Ceo';
        selectedVariableDesc = 'Ceo'
    }else{
        selectedVariableTitle = selected;
        selectedVariableDesc = selected
    }
  } else if (receivedVariable === 'Mission'){
    if (receivedVariable === selected){
        selectedVariableTitle = 'Mission';
        selectedVariableDesc = 'Mission'
    }else{
        selectedVariableTitle = selected;
        selectedVariableDesc = selected
    }
  } else if (receivedVariable === 'Leaders'){
    if (receivedVariable === selected){
        selectedVariableTitle = 'Leaders';
        selectedVariableDesc = 'Leaders'
    }else{
        selectedVariableTitle = selected;
        selectedVariableDesc = selected
    }
  }

  useEffect(() => {
    setSelected(receivedVariable);
  }, [receivedVariable]);

  const handleSidebarClick = (link) => {
    setSelected(link);
  };

  return (
    <>
      <Banner image={LeaderImg} title={selectedVariableTitle} pos="10%" />
      {/* <Banner image={LeaderImg} title={selectedVariableTitle} desc={selectedVariableDesc} pos="10%" /> */}
      <div className="dashboard">
        <Sidebar className="sidebar" handleClick={handleSidebarClick} />
        <Routes>
          <Route path="/" element={<AboutContent selected={selected} />} />
        </Routes>
      </div>
    </>
  );
};

export default AboutUs;
