import Risk from '../../img/geospatial/risk.jpg'
import Biometrics from '../../img/geospatial/biometrics.jpg'
import Case from '../../img/geospatial/case.jpg'
import Geospatial from '../../img/geospatial/geospatial.jpg'
import KYC from '../../img/geospatial/kyc.jpg'
import Analytics from '../../img/geospatial/analytics.jpg'

const servicesArray = [
    {
        img: Geospatial,
        title: 'Geospatial',
        link: '/Geospatial-Analysis/Case-Geospatial',
    },
    {
        img: KYC,
        title: 'KYC',
        link: '/Geospatial-Analysis/Case-KYC',
    },
    {
        img: Analytics,
        title: 'Analytics',
        link: '/Geospatial-Analysis/Case-Analytics',
    },
    {
        img: Case,
        title: 'Case Managements',
        link: '/Geospatial-Analysis/Case-Managements',
    },
    {
        img: Biometrics,
        title: 'Biometrics',
        link: '/Geospatial-Analysis/Case-Biometrics',
    },
    {
        img: Risk,
        title: 'Risk And Threat Management',
        link: '/Geospatial-Analysis/Case-RiskAndThreatManagement',
    },
]

export default servicesArray;