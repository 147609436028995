import BankingImg from '../../../assets/img/banking.jpg';
import GovernementImg from '../../../assets/img/goverment.jpg';
import RetailImg from '../../../assets/img/retail.jpg';

const sectionsArray = [
    {
        img: BankingImg,
        title: 'Banking, Finance & Insurance',
        desc: "VALOORES' comprehensive software solutions empower banks, financial institutions, and insurers to streamline operations, manage risks, and stay on top of compliance. Our real-time business intelligence tools integrate seamlessly with existing financial products, services and systems, turning complex data into key insights for suspicious activity detection, regulatory reporting, financial analysis and more. Adapt to regulatory and market changes, optimize performance, and drive growth with our innovative, data-driven solutions designed for today's fast-paced regulatory landscape.",
    },
    {
        img: GovernementImg,
        title: 'Government & Public Sector',
        desc: "VALOORES' tailored solutions for government and public institutions streamline processes, enhance transparency, and optimize service delivery. By leveraging advanced analytics and intelligent data tools, we transform complex datasets into strategic insights, empowering public sector organizations to manage resources, ensure regulatory compliance, and improve public service outcomes efficiently.",
    },
    {
        img: RetailImg,
        title: 'Retail & Supply Chain',
        desc: "VALOORES' retail platform leverages advanced data analytics and automation to streamline supply chain operations. It integrates with existing ERP and inventory management systems, providing real-time visibility into stock levels, and automating procurement processes. By optimizing inventory forecasting, reducing lead times, and enhancing demand planning, our solution reduces operational inefficiencies, minimizes costs, and drives faster, data-driven decision-making across the retail ecosystem.",
    }
]
export default sectionsArray;