import React, { useState} from 'react';
import SectionCarousel from './SectionCarousel';

const Sections = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  // console.log(activeIndex);

  return (
    <>
    <div className="mt-5">
      <SectionCarousel setActiveIndex={setActiveIndex}/>
    </div>
    </>
  );
};

export default Sections;
