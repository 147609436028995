import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import slides from './slides';
// import './gov.scss'

const CustomCarousel = () => {
  const settings = {
    easing: 'linear',
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div className='content' style={{ position: 'relative' }} key={index}>
            <div className="img_content-gov row" style={{ backgroundImage: `url(${slide.image})`, height: '650px' }}>
              <div className="overlay_bg">
                <div style={{lineHeight: '2rem', top: `${slide.position}`}} className='pt-5 text_content col-md-6'>
                  <h1 style={{zIndex: '2'}}>{slide.title}</h1>
                  <h4>{slide.description}</h4>
                  <div className='d-flex flex-row gap-8 mt-4'>
                    {slide.button === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show'>Learn More</Link></span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  )
}

export default CustomCarousel;
