

const inSystem = [
    {
      title: "Overview",
      desc:
      (
        <>
        Managers and Administrators worldwide are always looking for ways to increase productivity and minimize the margin of error and the time effort.
        in’System is one of many tools under our VALOORES core suite, it secures and manages users by providing accesses on screen and on displayed data, while providing reports and logs, enabling users to perform contextual actions based on VALOORES Suite information. As well in’System allows the creation, management and authentication of the suite users.
          <br />
          <br />
        If you are looking for one core solution to create, modify and manage users, track their activity and configure their access rights to minimize the margin of errors, maximize productivity and save time; then VALOORES in’System is the solution you are looking for.        </>
      ),
      list: [''],
    },
    {
      title: "Key Benefits",
      desc: '',
      list: [
        'Fast and easy way to create, modify and manage users',
        'Customized access rights for every user',
        'One place to manage users for all solutions',
        'Standard and Custom user roles and access',
        'Track all activity and follow up actions',
        'Parameterize fields for all solutions',
        'Access to all information in one place',
      ],
    },
    {
      title: "Key Features",
      desc: 'We Provide:',
      list: [
        'Security Management defines profiles, roles and user management, giving each user access to the suite’s applications under profiles or global profiles. Roles are linked to each group of users, customized to fit every user’s need in application access.',
        'Logs are a tracking tool to follow-up on user action and keep track of activities on each application. Logs are manageable by application, object and user to allow the operator to monitor every element of the environment.',
        'Parameters help configure the elements of the applications, before user access to enrich user access and restrictions and maximize efficiency.',
      ],
    }, 
    
  ];
  
  export default inSystem;
  