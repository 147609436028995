import bio1 from '../../img/management/bio1.jpg'
import bio2 from '../../img/management/bio2.jpg'
import bio3 from '../../img/management/bio3.jpg'
import bio4 from '../../img/management/bio4.jpg'
import bio5 from '../../img/management/bio5.jpg'
import bio6 from '../../img/management/bio6.jpg'
import bio7 from '../../img/management/bio7.jpg'
const BiometricsArray=[
    {
        title:'Why Biometric Security Matters Today?',
        desc:'Biometric security is a security mechanism that identifies people by verifying their physical or behavioral characteristics. It is currently the strongest and most accurate physical security technique that is used for identity verification Technology is integrated into just about every aspect of modern life, and with the everincreasing digitization of our world, it has become more difficult to safeguard confidential information. Keys and passwords are no longer sufficient data security measures.Passwords, in fact, pose a huge vulnerability in a company’s security system due to their share ability and ease of cracking. When it comes to data breaches, human error and poor passwords account for the majority of lapses in security. Which is why biometric security technology is growing in popularity. The field of biometric security is still actively developing and adapting to new conditions, and at the same time some trends in the biometrics market are even accelerating.',
        img: bio1,

    },
    {
        title:'',
        desc:'With the abundance of and network security breaches and the rise of identity theft, it is clear that stronger authentication methods are necessary. One such method is Biometric security systems. The importance of biometric security in modern society is ever-growing. Physical characteristics are unique and fixed, including among siblings and even twins.An individual’s biometric identity is able to replace (or, at the very least, supplement) password systems for phones, computers, and restricted areas. After a person’s biometric data is gathered and matched, the system saves it to be matched with subsequent access attempts. Usually, the biometric data is encrypted and then stored either in the device itself or in a remote server.',
        img: bio2,

    },
    {
        title:'Biometrics Security Types',
        desc:[
            (
            <div>
             Face ID identification:<br/>
             Face recognition is a biometric software application capable of verifying the identity of person using facial expression.<br/>
             Fingerprint:<br/>
             Detection of fingerprints has become one of the most popular ways of maintaining security systems in today’s world. So, accurate identification of a person through his fingerprints is quite important.<br/>
             OTP:<br/>
             The OTP feature prevents some forms of identity theft by making sure that a captured user name/password pair cannot be used a second time. Typically, the user's login name stays the same, and the one-time password changes with each login. One-time Passwords (OTP) is a password that is valid for only one login session or transaction in a computer or a digital device. The random function was used to generate random OTPs
                </div>
            )
        ],
       
        img: bio3,

    },
    {
        title:'',
        desc:[
            (
            <div>
            Multiple user identification:<br/>
            A multiple user identification is an entity used to identify users on a website, software, system or within a generic IT environment. Multi-Factor Authentication (MFA) was used to identify for multiple user identification.<br/>
            Session time out:<br/>
            Session timeout represents the event when a user does not perform any action on a web site during an interval of time. We implemented session time out using the time-out function. Liveness check could be requested from users in order to proceed after or during the session timeout happening so that the user wouldn’t lose the session and perform the work accordingly.<br/>
            Voice recognition:<br/>
            Voice recognition technology is a software program or hardware device that has the ability to decode the human voice. Vector quantization (VQ) was used as an algorithm. It is a classical quantization technique from signal processing that allows the modeling of probability density functions by the distribution of prototype vectors.
                </div>
            )
        ],
        img: bio4,

    },
    {
        title:'Face Id Identification',
        desc:'Facial recognition is done by analyzing the ratios of an individual’s facial features: for instance, the distance between the eyes, the nose, the lips, the ears, the chin, and the eyebrows. Facial recognition is highly accurate, aOur face recognition system extracts a unified embedding for identification. It is a system that, given a picture of a face, will extract high-quality features from the face and predict a feature vector representation these features, called a face embedding. nd results only take a splitsecond. It directly learns a mapping from face images to a compact Euclidean space where distances directly correspond to a measure of face similarity. The model is a deep convolutional neural network trained via a triplet loss function that encourages vectors for the same identity to become more similar (smaller distance), whereas vectors for different identities are expected to become less similar (larger distance). The focus on training a model to create embedding directly (rather than extracting them from an intermediate layer of a model) was extremely helpful.',
        img: bio5,

    },
    {
        title:'',
        desc:'This is an Example of the face ID identification (FST) when logging into the application and having secured data such as CDR or other types: First, the user starts by typing his username and password. After the validation of the username and the password, an additional level of security appears on the user’s screen for the second layer of security. In order to proceed, the user should place his face in the circle and click on it in order to start with the face ID verification. Next, the user should wait for a few seconds for the engine to finish validating. Distances directly correspond to a measure of face similarity. The model is a deep convolutional neural network trained via a triplet loss function that encourages vectors for the same identity to become more similar (smaller distance), whereas vectors for different identities are expected to become less similar (larger distance). The focus on training a model to create embedding directly (rather than extracting them from an intermediate layer of a model) was extremely helpful.',
        img: bio6,

    },
    {
        title:'',
        desc:'In other words, the adopted method uses a deep convolutional network trained to directly optimize the embedding itself, rather than an intermediate bottleneck layer as in previous deep learning approaches. The result was a robust and effective face recognition system. After the face ID has been successfully validated, the user will have access to the dashboard. In case the face ID is not validated, a message is displayed that requests the user to revalidate.After several failed attempts the user will be redirected to the login page to reenter his credentials. For extra security, the failed attempts will be saved in our database to protect our customer’s privacy. The Failed Attempt is also followed by security measurements where each attempt saved will be logged into the audit trail and logs and reviewed in order to check the difference and threshold of the attempts and investigate more into it.',
        img: bio7,

    },
    
]
export default BiometricsArray