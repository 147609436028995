import React from 'react'
import Sidebar from '../Banking/BankingPages/SideMenu'
import Banner from './Banner'
import Content from './Content'
import { Helmet } from 'react-helmet';
import {useLocation} from 'react-router-dom';
import inData1 from '../../img/indata/inData1.jpg';
import inData2 from '../../img/indata/inData2.jpg';
import inData3 from '../../img/indata/inData3.jpg';
import inData4 from '../../img/indata/inData4.jpg';
import inData5 from '../../img/indata/inData5.jpg';
import inData6 from '../../img/indata/inData7.jpg';
import inData7 from '../../img/indata/inData8.jpg';
import inData8 from '../../img/indata/inData13.jpg';
import inData9 from '../../img/indata/inData11.jpg';
import inData10 from '../../img/indata/inData12.jpg';
import inDataGovernanceArray from './content/InDataGovernance'
import inkYEArray from './content/InKYE'
import inkYcArray from './content/InKYC'
import inSupplierManagement from './content/InSupplierManagement'
import inContactArray from './content/InContact'
import inFoundationArray from './content/InFoundation'
import inSourcingArray from './content/InSourcing'
import inProductManagerArray from './content/InProductManager'
import inOrganizationArray from './content/InOrganization'
import inProductManagementArray from './content/InProductManagement'
import inChanelArray from './content/InChanel'

const Feature = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const receivedVariable = searchParams.get('variable');
    console.log(receivedVariable);
    let title, BannerImg, BannerDesc, RiskArray, overview, demo, pdf, fact, paper;    

    switch(receivedVariable){
        case 'InDataGovernance':
          
            title = "in'Data Governance"
            BannerImg = inData1
            BannerDesc = "“in'Data Governance is designed to centralize, cleanse, govern & share the data across the organization's systems, & to synchronize it downstream the different applications & platforms.”"
            RiskArray = inDataGovernanceArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;
        case 'InKYE':
           
            title = "in'KYE"
            BannerImg = inData2
            BannerDesc = "““in'KYE allows you to assess customer risks;meet regulatory requirements and improve overallcustomer relationships while providing cost-effective to identify and prevent financial crimes.””"
            RiskArray = inkYEArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;
        case 'InKYC':
           
            title = "in'KYC"
            BannerImg = inData3
            BannerDesc = "“in'KYC allows you to assess customer risks; meet regulatory requirements and improve overall customer relationships while providing cost-effective to identify and prevent financial crimes.”"
            RiskArray = inkYcArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'InSupplierManagement':
           
            title = "in'Supplier Management"
            BannerImg = inData4
            BannerDesc = "“in’Supply chain offers you the ability to properly diagnose problems, work around disruptions and determine how to efficiently move productsto those in a crisis situation.”"
            RiskArray = inSupplierManagement
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'InContact':
           
            title = "in'Contact"
            BannerImg = inData5
            BannerDesc = "“in'Contact Center Management enables end-to-end management of the customer experience by unifying customer data, contextual knowledge, and interaction channels.”"
            RiskArray = inContactArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'InFoundation':
           
            title = "in'foundation"
            BannerImg = inData6
            BannerDesc = "“in'Data Foundation allows institutions to quickly implement specialized Finance, Risk & Marketing analytical applications in order to drive line of business control and performance.”"
            RiskArray = inFoundationArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;
        case 'InSourcing':
           
            title = "in'sourcing"
            BannerImg = inData7
            BannerDesc = "“in'Sourcing increases the sourcing bandwidth of procurement professionals so they can exploit many more savings opportunities and capture more value from each.”"
            RiskArray = inSourcingArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;
        case 'InProductManager':
           
            title = "in'Product Information Management"
            BannerImg = inData8
            BannerDesc = "“in’Product Information Management is an enterprise data management solution that enables customers to centralize product information from heterogeneous systems, creating a single view of product information that can be leveraged across all functional departments.””"
            RiskArray = inProductManagerArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;
        case 'InOrganization':
           
            title = "in'Organization Structure Management"
            BannerImg = inData8
            BannerDesc = "“A manager will be able to define tasks, determine information flow within the organization,and ensure accountability for achieving organizational goals and objectives”"
            RiskArray = inOrganizationArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;
        case 'InProductManagement':
           
            title = "in'Product Structure Management"
            BannerImg = inData9
            BannerDesc = "“in'Product Structure Management is used to manage changes,testing, and problem resolution for complex products with a large number of interdependent parts and functions.”"
            RiskArray = inProductManagementArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'InChanel':
           
            title = "in'Channels Management"
            BannerImg = inData10
            BannerDesc = "“in'Channels Management empowers channel users to maximize operationalperformance by making better decisions faster and to increase profit margins by effectively controlling disputes/deductions, trade funds…”"
            RiskArray = inChanelArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        default:
        break;
        }

        // const formattedPage = receivedVariable.replace(/-/g, ''); // Remove dashes from the page variable
        // const formattedUrl = `/banking/riskandcompliance/${formattedPage}`;
  
        // window.history.replaceState(null, null, formattedUrl);
    
    return (
        <>
            <Helmet>
                <title>{receivedVariable}</title>
            </Helmet>
            <Banner
                img = {BannerImg}
                desc= {BannerDesc}
                demo = {demo}
                pdf = {pdf}
                fact = {fact}
                paper = {paper}
            />
            <div className='section_divider risk_container my-4'>
                <div className="container">
                    <h4>{title}</h4>
                </div>
            </div>
            <div className='d-flex flex-row container'>
                <div className="main-content overview_content" style={{flex: '4'}}>
                    
                    <Content RiskArray = {RiskArray}/>
                </div>

                <div className="sidebar_section" style={{flex: '1'}}>
                    <Sidebar /> 
                </div>
            </div>
        </>
    )
}

export default Feature