import News1 from '../../../assets/img/news2.png';

const newsArray = [
    {
        img: News1,
        title: (
            <div className='d-flex flex-row gap-3'>
                <h1 className='font--poppins text-lg-start text-md-center news__title-sub d-flex flex-lg-column flex-md-row gap-md-3' style={{color: '#121848', lineHeight: '4rem'}}>
                    Conferences <span style={{ color: '#121848!important'}}>2024</span>
                </h1>
            </div>
        ),
        link: ''
    }
]

export default newsArray;