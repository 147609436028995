const InDebtCollection = [
    {
      title: "Overview",
      desc: 
      (
        <>
          Most financial service providers find that their collection efforts are inefficient relative to the experience of the global market, which indicates that efficiencies can be found across the entire collections lifecycle from pre-delinquency to write off and recoveries. If financial service providers with inefficient collections functions continue with their current collection strategy, collectable balances older than fifteen months will continue to provide minimal return. These financial service providers will also have difficulty in determining whether the cost of collection outweighs the return on these collectable balances.
          <br />
          Collections functions have not received the necessary investment required to enhance efficiency and effectiveness, and in so doing have reduced the ability to further increase profitability and performance.
          <br />
          Combining these concerns with new consumer regulation such as Treating Customers Fairly and the Consumer Protection Act and risk-based regulations such as Basel II and III has created the need for lenders to adopt a more risk-based approach to collections.
          <br />
          In an effort to assist in realigning the collection and recovery function, Valoores has developed in’Debt Collection and Recovery solution. This approach, which encompasses both a quantitative and qualitative component identifies areas of improvement within the collections and recoveries space, as well as comparing completed accounts’ (i.e. non-performing accounts that have either cured or written-off) loss figures against industry peers. in’Debt Collection and Recovery involves the processing and transforming of default data for various purposes and by using a standard loss-given default (LGD) calculation to run the data, the losses experienced on the completed accounts and trends can be analyzed over time
          <br />
          in’Debt Collection and Recovery is a ‘centralized debtors’ allocation model’ to allocate delinquent cases to the right channel. It captures the details of each delinquent case and the channel and collector/collection agency to which it needs to be allocated. This data is made available to the collection work flow system, which delivers the details of the case assigned to each individual.
        </>
      ),
      list:[''],
    },
    {
      title: 'Strengthen Your Debt Recovery Strategies',
      desc: 
      (
        <>
          <br />
          Develop a more focused debt collection strategy to help reduce costs, save time and maximize resources. Learn more about how Valoores in’ Debt Collection and Recovery, a risk-based collections strategy that can help you refine your debt management and recovery options:
          <br />
          <br />
          <h5 style={{color: '#666', fontSize: '17px', fontWeight: 'bold' }}>Single Customer Information View</h5>
          Getting an accurate view of your customers can be complicated. If you are challenged by multiple databases or large amounts of outstanding bad customer debt, linking customers’ account information can be critical to your success.          
          <br />
          Valoores has leveraged its longstanding expertise in managing consumer data to introduce a service that can help break down the silos in your organization, where you can get one complete view of your customers across multiple business units, databases or systems.
          <br />
          Aligning sales and marketing, finance, risk management, pre-delinquency, collections and recoveries functions ensures that the lessons learnt from each function and credit lifecycle stage are shared across the organization to minimize losses and maintain control.
          <br />
          <br />
          <h5 style={{color: '#666', fontSize: '17px', fontWeight: 'bold' }}>Automating the Collections activities driven from decision engines</h5>
          Increasing the automation of collections activities make collections processes significantly more efficient and effective. Organizing activities by the risk level of accounts and customers adds to the efficiency gains with low value activities automated and high value activities aligned to the most experienced collectors.
          <br />
          <br />
          <h5 style={{color: '#666', fontSize: '17px', fontWeight: 'bold' }}>Daily Monitoring on your collection accounts</h5>
          A customer suddenly stops payment and drops into delinquent status. But just as swiftly as a person can fall behind on payment, there are moments to indicate they might be worthy of your collections efforts.
          <br />
          Collection Triggers tracks your accounts, offering daily monitoring, to notify you of new contact information and changes that indicate financial improvement and ability to pay. Collection Triggers is ideal for all stages of the recovery process, monitoring the Early-stage delinquencies, the Charge-off accounts and the Uncollectible, warehoused and post-judgment accounts.
          <br />
          <br />
          <h5 style={{color: '#666', fontSize: '17px', fontWeight: 'bold' }}>Improved debt collection with BI</h5>
          Debt collection can gain immensely by BI. By powering this, Valoores in’Debt Collection and Recovery has effectively turned debt collection into a customer-relations management program.
          <br />
          It may be necessary to devise a comprehensive set of collections metrics and reports to support efficiency and effectiveness improvements in their collections operations and associated strategies. This will cover financial MI, collections MI, operational MI and customer and product MI.
          <br />
          <br />
          <h5 style={{color: '#666', fontSize: '17px', fontWeight: 'bold' }}>Regulatory Compliance</h5>
          in’Debt Collection and Recovery helps organizations embed regulatory compliance into their workflow, helping to identify infractions and violations, and protect collectors from regulatory action.
          <br />
          in’Debt Collection and Recovery helps automate compliance, basing strategic actions on consistent, transparent and fully documented data, analytics and policies. Advanced text and speech analytics monitor, evaluate and report all collection interactions for compliance, providing performance metrics to improve efficiency.
          <br />
          <br />
          <h5 style={{color: '#666', fontSize: '17px', fontWeight: 'bold' }}>Using Analytics across the spectrum in Debt Recovery and Collection</h5>
          <ul>
            <li>Payment propensity/recovery models: The recovery models were based on parameters such as a customer’s payment history, risk exposure, cross-holdings, geographic location, and others. Customer behavior will be analyzed (depending upon the product and business objective) while building these models. These models help identify (and hence give differential treatment to) customers based on their payment propensity.</li>
            <li>Pre-delinquency management: This is a critical strategy deployed for non-delinquent customers across products. It involves behavioral analysis of the potential defaulters to assess their credit orientation and worthiness. Traditional methods react to delinquency that has already occurred, whereas the pre-delinquency management gives an edge by raising an early alarm. Valoores Analytical models are developed to identify customers who have higher probability of going delinquent. Further, these customers are offered a wide range of services like soft reminders, SMS campaign, new payment schedule, and flexible credit limits.</li>
            <li>Loss forecasting: An important tool for risk management adopted by banks at a portfolio level.
                In order to fully optimize collections, it is important to incorporate behavioral analytics into the collections and recoveries function. This includes the identification of customer-level scoring drivers for pre-delinquency, recovery and litigation levels. If necessary, the lender may also wish to develop IFRS9 compliant models</li>
          </ul>
          <br />
          <b style={{color: "666"}}>in’Debt Collection and Recovery drives right party contact and recovery with powerful segmentation, communication and decision strategies, using predictive analytics, models and scores to prioritize recovery efforts.</b>

        </>
      ),
      list: [],
    },
    {
        title: 'benefits',

        desc: '',
        list: ["Collections strategies became fully aligned to organizational risk appetite.",
        "Improve customer retention efforts through better customer understanding.",
        "Identifying new opportunities for revenue streams as well as cost reductions.",
        "Improved manpower utilization and reduce turnaround time.",
        "Reduced credit loss.",
        "Maintain compliance at every stage of the credit lifecycle.",
        "Visibility into collection activity and performance with industry-specific dashboards, reports and productivity metrics."],
      },
      {
        title: 'features',
        desc:'',
        list: [
          "Risk based Collections and Recoveries strategy integrated into all risk classes",
          "An optimal control framework, including full cost vs. benefit analysis",
          "Risk quantification models used to optimize collections and recoveries performance",
          "Reporting is embedded in the business’s day-to-day activity and is integrated across risk class",
          "Effective allocation of Collection cases and use of resources efficiently",
          "Incorporate behavioral analytics into the collections and recoveries function",
          "Ensure adherence to the latest government-mandated rules and guidelines"
        ],
    },
  ];
  
  export default InDebtCollection;
  