import React from 'react'
import Service1 from '../../img/academy/services/service1.jpg'
import Service2 from '../../img/academy/services/service2.jpg'
import {BiChevronRight} from 'react-icons/bi'
// import {BiChevronRight, BiChevronsRight} from 'react-icons/bi'
// import {AiOutlineClockCircle} from 'react-icons/ai'
import {Link} from 'react-router-dom'

const Services = () => {


    const events = [
        {
            img: Service1,
            title: "Internal Academy",
            desc: "In our competitive global world, what truly differentiates one organization from another is the organization's people. At VALOORES Academy, we are firmly committed to positive and tangible results towards our clients and partners. That is why we consider as a top priority having a staff that is, at every turn, up to the challenges of our business.",
            link: 'https://academy.valoores.com/vti-internal-academy.php',
        },
        {
            img: Service2,
            title: "External Academy",
            desc: "Our External academy is specialized in providing organizations with practical standardized and tailored Learning and Development solutions to their Human Resources as well as a positive and dynamic working environment. Our training experts start with understanding the organization'   s business objectives, evaluating their current programs, and then designing a custom training solution.",
            link: 'https://academy.valoores.com/vti-external-academy.php',
        },
        
    ]

  return (
    <>
        <div className='p-2' style={{background: '#f1f1f1'}}>
            <div className='container'>
                <div className="services-heading text-center my-5">
                    <h3>VALOORES in'Academy Services</h3>
                    <p>VALOORES Academy learning solutions for your professional development</p>
                </div>
                <div className='services_container'>
                    <div className="service_content">
                        <div className="row">
                            {events.map((item, index) =>(
                                <div key={index} className="col-md-6 mb-5">
                                    <div className='service_content-container d-flex align-items-center flex-column gap-5'>
                                        <div className='service_img' style={{backgroundImage : `url(${item.img})`}}></div>
                                        <div className="service_content-text p-3 d-flex flex-column gap-3">
                                            <h4 className='text-center'>{item.title}</h4>
                                            <p>{item.desc}</p>
                                            <Link to={item.link} target='_blank'>Read More <BiChevronRight /></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Services