import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

// Set the app element for accessibility
Modal.setAppElement('#root');

function Videos() {
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [fetchedData, setFetchedData] = useState([]);
  const [editValue, setEditValue] = useState('');
  const [editValue2, setEditValue2] = useState('');
  const [editValue3, setEditValue3] = useState('');
  const [editValue4, setEditValue4] = useState('');
  const [editId, setEditId] = useState('');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('http://localhost:8800/videos/database', {
        input1: input1,
        input2: input2,
        input3: input3,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:8800/videos/show');
        setFetchedData(response.data);
        setIsDataFetched(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleClick = (value, value2, value3, value4, id) => {
    setEditId(id);
    setEditValue(value);
    setEditValue2(value2);
    setEditValue3(value3);
    setEditValue4(value4);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (delete_id, delete_VIDid) => {
    try {
      const response = await axios.delete('http://localhost:8800/videos/deleted', {
        data: {
          delete_ids: delete_id,
          delete_VIDid: delete_VIDid,
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setEditValue(e.target.value);
  };
  const handleInputChange2 = (e) => {
    setEditValue2(e.target.value);
  };
  const handleInputChange3 = (e) => {
    setEditValue3(e.target.value);
  };
  const handleInputChange4 = (e) => {
    setEditValue4(e.target.value);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put('http://localhost:8800/videos/edit', {
        id: editId,
        value: editValue,
        value2: editValue2,
        value3: editValue3,
        value4: editValue4,
      });
      console.log('Value edited:', res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };

  return (
    <>
      <div className= "d-flex flex-row justify-content-between align-items-center container mb-4">
        <h1>Videos</h1>
        <button className="btn btn-primary" onClick={handleAddModalOpen}>
          Add Video
        </button>
      </div>
      {isDataFetched && (
        <div className="tableStyle">
        <table className="table">
          <thead className="sticky-header">
            <tr>
              <th>ID</th>
              <th>Video Name</th>
              <th>Video Description</th>
              <th>Video Link</th>
              <th>Video Order</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {fetchedData.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.video_name}</td>
                <td>{item.video_desc}</td>
                <td>{item.video_path}</td>
                <td>{item.VIDid}</td>
                <td>
                  <button
                    className="btn btn-secondary"
                    to="#"
                    onClick={() =>
                      handleClick(
                        item.video_name,
                        item.video_desc,
                        item.video_path,
                        item.VIDid,
                        item.id
                      )
                    }
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => handleDelete(item.id, item.VIDid)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}
      {/* EDIT VIDEO MODAL */}
      <Modal
      isOpen={isEditModalOpen}
      onRequestClose={handleEditModalClose}
      contentLabel="Edit Modal"
      >
      <div className="">
        <div className="">
          <div className="">
            <form className='edit_form'>
              <div>
                  <h5 className="pb-3 px-3">Edit Video</h5>
              </div>

              <div className=" px-4">
                <div className="row mb-2">
                  <div className="col-md-6">
                      <label htmlFor="VidId" >Video ID</label>
                      <input
                          id="VidId"
                          type="text"
                          disabled
                          readOnly
                          value={editId}
                          placeholder="Video Edit ID"
                          className='form-control my-3'
                      />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="VidName">Video Name</label>
                    <input
                      id="VidName"
                      type="text"
                      value={editValue}
                      onChange={handleInputChange}
                      placeholder="Video Edit Name"
                      className='form-control my-3'
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label for="VidDesc">Video Description</label>
                      <input
                        id="VidDesc"
                        type="text"
                        value={editValue2}
                        onChange={handleInputChange2}
                        placeholder="Video Edit Desc"
                        className='form-control my-3'
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label htmlFor="VidLink">Video Link</label>
                      <input
                        id="VidLink"
                        type="text"
                        value={editValue3}
                        onChange={handleInputChange3}
                        placeholder="Video Edit Path"
                        className='form-control my-3'
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label htmlFor="VidOrder">Video Order</label>
                    <input
                      id="VidOrder"
                      type="text"
                      value={editValue4}
                      onChange={handleInputChange4}
                      placeholder="Video Edit Order"
                      className='form-control my-3'
                    />
                  </div>
                </div>
              </div>

              <div className="px-4 d-flex justify-content-end gap-3">
                <button className='btn btn-secondary' onClick={handleEditSubmit}>Submit Edit</button>
                <button className='btn btn-danger' onClick={() => setIsEditModalOpen(false)}>Cancel</button>
              </div> 
            </form>
          </div>
        </div>
      </div>
      </Modal>

      {/* ADD VIDEO MODAL */}
      <Modal
        isOpen={isAddModalOpen}
        onRequestClose={handleAddModalClose}
        contentLabel="Add Modal"
      >
        
        <div className="">
          <div className="">
            <div className="">
              <form className='add_form'>
                <div>
                  <h5 className="pb-3 px-3">Add Video</h5>
                </div>

                <div className=" px-4">
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label htmlFor="addVidName">Video Name</label>
                      <input
                        id="addVidName"
                        type="text"
                        value={input1}
                        onChange={(e) => setInput1(e.target.value)}
                        placeholder="Video Name"
                        className='form-control my-3'
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="addVidDesc">Video Description</label>
                      <input
                        id="addVidDesc"
                        type="text"
                        value={input2}
                        onChange={(e) => setInput2(e.target.value)}
                        placeholder="Video Description"
                        className='form-control my-3'
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label htmlFor="addVidLink">Video Link</label>
                      <input
                        id="addVidLink"
                        type="text"
                        value={input3}
                        onChange={(e) => setInput3(e.target.value)}
                        placeholder="Video Link"
                        className='form-control my-3'
                        />
                    </div>
                  </div>
                </div>
                <div className="px-4 d-flex justify-content-end gap-3">
                  <button className='btn btn-secondary' onClick={handleSubmit}>Submit</button>
                  <button className='btn btn-danger' onClick={() => setIsAddModalOpen(false)}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Videos;
