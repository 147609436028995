

const inIntegration = [
    {
      title: "Overview",
      desc:
      (
        <>
          <b style={{color:'#666', fontSize:'16px' }}>Extracting, transforming and loading the data</b>
            <br />
          Technology oriented era obliges financial institutions to use all available tools to handle the many industry challenges and ensure their own financial stability through intelligent business solutions. However, the too many different and independent sources of information available within one financial institution cause misinformation and lack of data integrity. VALOORES in’Integration allows these companies to maximize the value and integrity of their information in order to make better business decisions and to better target performance goals. The transfer of information and the variety of the sources of information causes loss/inaccuracy of 20% to 30%.
            <br />
            <br />
          This is where VALOORES in’Integration adds great value: First, VALOORES cleans the multiple sources of information in order to prevent misinformation and duplicates. Then, as the VALOORES in’Integration is fully incorporated to the multiple data sources, there is no transfer of information and the information is 100% accurate and reliable.        </>
      ),
      list: [''],
    },
    {
        title: "Market Challenge",
        desc: 
        (
          <>
            
            This is the time for Data booming, businesses are collecting more data than ever before.
            <br />
            Different businesses use various systems, tools and host their website on cloud systems. in’Integration provides ability to communicate with external systems while reading and writing data . A business tool that allows real time & bulk data management.
            <br />
            in’Integration is VALOORES’s tool for exchange of information in smooth way supporting existing client legacy applications
            <br />
            With in’Integration control data easier and make it fit your business.
            <br />
            <br />
            <b style={{color:'#666', fontSize:'16px' }}>Obstacles to eliminate:</b>
          </>
        ),
        list: [
          'Unsecure Data',
          'Automated integration process',
          'Reading data',
        ],
      },
    {
        title: "Key Benefits",
        desc: 
        (
          <>
            <ul>
              <li>
              Configuration:
                <ul>
                  <li>A solution to manage data integration from XML sources, flat files and tables</li>
                  <li>Advanced mapping capability.</li>
                </ul>
              </li>
              <li>
              Management:
                <ul>
                  <li>Ease Of use</li>
                  <li>Control of information flows</li>
                  <li>Flexibility</li>
                  <li>Various integration types ( Import , Export , Transform )</li>
                </ul>
              </li>
              <li>
              Administration:
                <ul>
                  <li>Cross-Referencing is used for different coding issues</li>
                  <li>Follow up on integration actions,Steps and Errors</li>
                </ul>
              </li>
            </ul>
          </>
        ),
        list: [''],
      },
  ];
  
  export default inIntegration;
  