import React from 'react'
import {Link} from 'react-router-dom'
import Pub1 from '../../img/publication/publication1.jpg'
import Pub2 from '../../img/publication/publication2.jpg'

const Publications = () => {
  return (
    <>
    <div className="container py-5">
        <div className='d-flex flex-column text-center mb-4'>
            <h3 className='pb-3' style={{color: '#136597'}}> VALOORES in'Analytics Publications</h3>
            <h6><span className='fw-bold'>VALOORES Analytics Team</span> is constantly working on being ahead of the latest Data Science technologies and advancements. Research and Development is one of the main pillars that the team focuses on every single day. The biggest gift a person can give is sharing knowledge and experience with the world. For that reason, the Analytics team has decided to regularly publish articles covering the latest technologies and research in the field of Data Science on one of its most important platforms: <span className='fw-bold'>Medium.</span></h6>
        </div>
        <div className="row">
            <div className="pub_container p-3 d-flex flex-row align-items-center">
                <div className="pub_img">
                    <img src={Pub1} alt="publication1" />
                </div>
                <div className="pub_content px-4">
                    <h6>Modeling Loans' Probability of Default Using Machine Learning</h6>
                    <p>The analysis of the Probability of Default is one of the main tasks to be undertaken by financial institutions, where it is important to gauge the likelihood of a borrower defaulting before giving a certain loan. The lack of a correct methodology to calculate this probability may lead to high losses, create systemic risk and affect the whole economy of the financial institution. An accurate prediction of default risk in lending has been a crucial subject for banks and other lenders, but the availability of open source data and large datasets, together with advances in computational and algorithmic data analytics techniques have renewed interest in this risk prediction task.</p>
                    <Link to='https://valooresanalyticsdept.medium.com/modeling-loans-probability-of-default-using-ml-2ca63f547a07' target='_blank'>Read More</Link>
                </div>
            </div>

            <div className="pub_container p-3 d-flex flex-row align-items-center my-4">
                <div className="pub_img">
                    <img src={Pub2} alt="publication1" />
                </div>
                <div className="pub_content px-4">
                    <h6>SHAP for Credit Risk: Interpreting Machine Learning Black Box</h6>
                    <p>As machine learning black boxes are increasingly being deployed in domains such as fintech, there is growing emphasis on building tools and techniques for explaining these black boxes in an interpretable manner. Such explanations are being leveraged by domain experts to diagnose systematic errors and underlying biases of black boxes.
                    VALOORES sees model interpretability as an important step in the data science workflow. Being able to explain how a model works serves many purposes, including building trust in the model's output, satisfying regulatory requirements and verifying model safety.</p>
                    <Link to='https://valooresanalyticsdept.medium.com/shap-for-credit-risk-interpreting-machine-learning-black-box-459a511e9e1e' target='_blank'>Read More</Link>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Publications