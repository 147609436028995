import alert1 from '../../img/management/alert1.jpg'
import alert2 from '../../img/management/alert2.jpg'
import alert3 from '../../img/management/alert3.png'
import alert4 from '../../img/management/alert5.png'
import alert5 from '../../img/management/alert6.png'
const caseArray=[
    {
        title:'Data Crowd Case Management',
        desc:'A report of an incident creates a case that needs to be resolved over a period of time achieving a satisfactory Result. Progression of the case involves a series of processes, simple to complex, multiple persons inside and outside of the created scenario, and the collation and examination of digital data, and other third-party material. Case management embraces the principles of investigation, an inquiry a systematic and detailed examination to discover detail, checkable facts, and information that fully and accurately informs decision-maker. So that those business outcomes are as satisfactory as possible. It is an ‘end to end’ holistic and dynamic process, each component part potentially impacting on another, involving the transformation of intelligence into actionable information',
        img: alert1,

    },
    {
        title:'Data Crowd Case Management Creation Sources',
        desc:[
            (
            <div>
                Data Crowd has made the case management creation an easy process where any end user has the ability to easily create a case based on diverse sources matching his needs in the investigation process. Case management initiation can be from:
                    <ul className='list-disk'>
                        <li>Manually Initiated _ Emergency</li>
                        <li>Data Simulation _ Group of incidence</li>
                        <li>Dynamic business Rule Engine _ Specific Scenarios</li>
                        <li>Map Explorer _ Specific suspicious area</li>
                        <li>Other sources _ Internal Investigation</li>
                    </ul>
                </div>
            )
        ],
        img: alert2,
        
    },
    {
        title:'Monitoring and Follow up',
        desc:'Case management could be to a standard that is outstanding, to a level that is exceptional and unexpected, it can be ‘extraordinary’ as illustrated below: That ‘engine’ is an investigation case management platform tested, flexible, proportionate, Dynamic and automated. The case management process was developed and is now used daily to support the many investigation processes undertaken by marketing sector and governmental sector as well as to support training of those with the onerous responsibility to conduct or manage investigations that have potential to alter Activities, lives and/or careers',
        img: alert3,

    },
    {
        title:'What is an Alert Management System (AMS) ?',
        desc:[
            (
            <div>
               An Alert Management System (AMS) is a real time system which maintains profiles about individuals, threats, or other entities and in real time processes events and returns alerts about profiles and their risks. Examples of AMS In In’ Data Crowd Application:
                    <ul className='list-disk'>
                    <li>Suspicious activity</li>
                    <li>High Risk Profile</li>
                    <li>Law enforcement</li>
                    <li>Sanctioned Hits</li>
                    <li>Emergency Call</li>
                    <li>Royal Residential Restricted Area</li>
                    <li>Drug Dealing</li>
                    <li>Kidnapping</li>
                    <li>Crime</li>
                    <li>High Density Area</li>
                    <li>Physical Assault</li>
                    <li>Robbery</li>
                    <li>Car Accident</li>
                    <li>Serial Killer Case</li>
                    <li>Suicide</li>
                    <li>Homicide</li>

                    </ul>
                </div>
            )
        ],
        img:alert4,
        
    },
    {
        title:'Backward and Forward Investigation',
        desc:'Trajectory data are essentially a sequence of spatial points ordered by timestamps and generally carry some descriptive information in addition to basic spatiotemporal messages. Different categories of trajectory data have been emerging and are being applied with the development of Information and communications technology. It has now become easier for law enforcement agents to find criminals, obtain evidence and store important data efficiently. In’DataCrowd in the law enforcement field is not only time-saving for police officers, but is also life savings for the citizens and officers. It is pertinent for law enforcement to use in’DataCrowd to the best of their abilities and in the best interest of the public.',
        img: alert5,
    }
]
export default caseArray