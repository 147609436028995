import React from 'react'
import LawImg from '../../img/ai/lawImg.jpg'
import Fade from 'react-reveal/Fade';


const law_list = [
    {
        item: 
        <>
            <span className='fw-bold'>Predictive policing: </span>AI analyzes data to optimize resource allocation.
        </>
    },
    {
        item: 
        <>
            <span className='fw-bold'>AI-powered video surveillance: </span>Facial recognition aids suspect identification.
        </>
    },
    {
        item: 
        <>
            <span className='fw-bold'>Unstructured data analysis: </span>AI monitors online threats through data analysis.
        </>
    },
    {
        item: 
        <>
            <span className='fw-bold'>Natural language processing: </span>AI accelerates legal document analysis for investigators.
        </>
    },
]
const LawEnforcement = () => {
  return (
    <>
        <div className="container my-5">
            <div className="row">
                <Fade left>
                    <div className="col-md-6 retail_bg" style={{backgroundImage : `url(${LawImg})`}}></div>
                </Fade>
                <Fade left>
                    <div className="col-md-6">
                        <div className='mb-3'>
                            <h1 className='mb-3'>AI in Law Enforcement: Enhancing Public Safety</h1>
                            <p>AI integration in law enforcement improves public safety through data-driven strategies. Predictive policing optimizes resource allocation, AI-powered video surveillance aids suspect identification, unstructured data analysis monitors online threats, and natural language processing accelerates legal document analysis for investigators.</p>
                        </div>
                        <ul className='p-0' style={{listStyle: "none"}}>
                            {law_list.map((list, index) => (
                                <Fade>
                                    <li className='mb-2' key={index}>{list.item}</li>
                                </Fade>
                            ))}
                        </ul>
                    </div>
                </Fade>
            </div>
        </div>
    </>
  )
}

export default LawEnforcement