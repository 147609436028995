import Risk1 from '../../../../img/riskCompliance/risk1.jpg'
import Risk2 from '../../../../img/riskCompliance/risk2.jpg'
import Risk3 from '../../../../img/riskCompliance/risk3.jpg'
import Risk4 from '../../../../img/riskCompliance/risk4.jpg'
import Risk5 from '../../../../img/riskCompliance/risk5.jpg'
import Risk6 from '../../../../img/riskCompliance/risk6.jpg'
import Risk7 from '../../../../img/riskCompliance/risk7.jpg'
import Risk8 from '../../../../img/riskCompliance/risk8.jpg'
import Risk9 from '../../../../img/riskCompliance/risk9.jpg'
import Risk10 from '../../../../img/riskCompliance/risk10.jpg'
import Risk11 from '../../../../img/riskCompliance/risk11.jpg'
import Risk12 from '../../../../img/riskCompliance/risk12.jpg'
import Risk13 from '../../../../img/riskCompliance/risk13.jpg'

const RiskCompliance = [
    {
      img: Risk1,
      title: "in'IFRS9",
      desc: "With the newly added pressures on the financial markets regarding the standards that need to be respected by the financial instruments, institutions now see it as regulatory to be introduced to financial statements...",
      link: "../banking/riskandcompliance?variable=IFRS9",
      button: 'yes',
    },
    {
      img: Risk2,
      title: "in'AML",
      desc: "VALOORES Financial Crime and Compliance suite of applications helps financial institutions tackle today's tactical risk and compliance problems while future-proofing compliance spent across regulatory mandates...",
      link: "../banking/riskandcompliance?variable=Aml",
      button: 'yes',
    },
    {
      img: Risk3,
      title: "in'Fraud",
      desc: "Rapidly detect, swiftly respond, discover widely, investigate deeply and report analytically...",
      link: "../banking/riskandcompliance?variable=infraud",
      button: 'yes',
    },
    {
      img: Risk4,
      title: "in'Regulatory",
      desc: "Ongoing rapid regulatory change and a complex market dynamic present significant challenges for financial institutions' operating models, analytics and reporting capabilities. As regulators begin to export compliance...",
      link: "../banking/riskandcompliance?variable=regulatoryreportingsolution",
      button: 'yes',
    },
    {
      img: Risk5,
      title: "in'Tax Compliance",
      desc: "The Common Reporting Standard (CRS), formally referred to as the Standard for Automatic Exchange of Financial Account Information, is an information standard for the automatic exchange of information...",
      link: "../banking/riskandcompliance?variable=taxcompliance",
      button: 'yes',
    },
    {
      img: Risk6,
      title: "in'Basel III",
      desc: "VALOORES Financial Services delivers wide-ranging coverage across all of the Basel III guidelines and regulatory requirements. Financial institutions suffering from a shortage of capital,...",
      link: "../banking/riskandcompliance?variable=basel",
      button: 'yes',
    },
    {
      img: Risk7,
      title: "in'Basel Regulatory Capital",
      desc: "VALOORES Financial Services in'Basel Regulatory Capital is an out-of-the-box, scalable solution covering Pillar I requirements across Basel II and Basel III guidelines...",
      link: "../banking/riskandcompliance?variable=baselregulatory",
      button: 'yes',
    },
    {
      img: Risk8,
      title: "in'Liquidity Risk Management",
      desc: "Pre-configured regulatory scenarios and runs; Extensive set of business assumptions for behavior modeling...",
      link: "../banking/riskandcompliance?variable=liquidityriskmanagement",
      button: 'yes',
    },
    {
      img: Risk9,
      title: "in'GRC - Risk & Business Process Consulting",
      desc: "The breaches to an organization's information security can damage a good reputation and lead to a loss of confidence among customers, investors and the market at large...",
      link: "../banking/riskandcompliance?variable=riskandbusinessprocessconsulting",
      button: 'yes',
    },
    {
      img: Risk10,
      title: "in'Enterprise Incident Management",
      desc: "Cross-channel investigation functions correlation and network analysis; Correlation and network analysis...",
      link: "../banking/riskandcompliance?variable=enterpriseincidentmanagement",
      button: 'yes',
    },
    // {
    //   img: Risk11,
    //   title: "in'Fraud Management",
    //   desc: "There is no shortage of ingenuity when it comes to financial crime and fraud. We see this daily as fraudsters continuously adapt their approach and methods in an effort to stay one step ahead of the law...",
    //   link: "../banking/riskandcompliance?variable=fraudmanagement",
    //   button: 'no',
    // },
    {
      img: Risk12,
      title: "in'Operational Risk",
      desc: "Operational risk management is an integral function of any financial service organization. It is aimed at fostering a risk aware culture into people, processes and systems in the organization. As an essential ally and...",
      link: "../banking/riskandcompliance?variable=operationalrisk",
      button: 'yes',
    },
    {
      img: Risk13,
      title: "in'IT Governance",
      desc: "Until now, regulatory mandates have been the primary catalyst for improving an organization's control environment and business processes. As a financial crisis grips the world economy and mega corporations...",
      link: "../banking/riskandcompliance?variable=itgovernance",
      button: 'yes',
    },
  ]

  export default RiskCompliance