import React from 'react'
import {Link} from 'react-router-dom'
import laptop from '../../img/laptop.png'
import valooresdashboard from '../../img/valooresdashboard.gif'

const Paralax = (props) => {
  return (
    <>
        <div className='dashboard_container mt-5' style={{background: `url(${props.img})`, height: `${props.height}`}}>
            <div className="container d-flex flex-column align-items-center justify-content-center" style={{top: `${props.top}`}}>
                <div className="row d-flex flex-column align-items-center justify-content-center text-center text-white w-100 p-5">
                   <h2> {props.title} </h2>
                    <div className='d-flex flex-row gap-3 justify-content-center'>
                        <Link to={props.link} className='mt-3 btn btn-primary btn-show' target='_blank' >
                            {props.button}
                        </Link>
                        {/* <button className='btn btn-primary btn-show'>Request Demo</button> */}
                    </div>
                </div>
                {props.laptop === 'true' ? 
                    <div className="dashboard_container-bg d-flex align-items-center justify-content-center" style={{ backgroundImage: `url(${laptop})`}}>
                        <img src={valooresdashboard} alt="dashboard" />
                    </div>
                : ''} 
            </div>
        </div>
    </>
  )
}

export default Paralax