import React from 'react';
import Carousel from './Carousel';
import Introduction from './Introduction';
import Sections from './Sections';
import Choice from './Choice';
import Paralax from './Paralax';
import Application from './Application';
// import News from './News';
import Fade from 'react-reveal/Fade'
import dashboardImg from '../../img/dashboardParalax.jpg'
import paralax2 from '../../img/paralax2.jpg'
import { Helmet } from 'react-helmet';


const Banking = () => {
 
  return (
    <>
      <Helmet>
        <title>Banking</title>
      </Helmet>
      <Carousel />
      <Fade bottom>
        <Introduction />
      </Fade>
        <Sections />
        <Choice />
        <Paralax img={dashboardImg} title="Powerful Dashboard Visualizations" link='https://banking.valoores.com/portalbi.php' button='Learn More' laptop= 'true' height='50rem' top = '0%'/>
        <Application />
        <Paralax img={paralax2} title="You have the VALUE, we bring you the ADDED VALUE. it's VALOORES!" link='https://banking.valoores.com/portalbi.php' button='Contact Us' laptop= 'false' height='30rem' top='30%'/>
        {/* <News /> */}
    </>
  );
};

export default Banking;
