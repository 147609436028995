import React from 'react'
import NutshellImg from '../../img/nutshell.png'

const Nutshell = () => {
  return (
    <>
    <div className="container my-5">
        <div className="row">
            <div className="col-md-6 d-flex flex-column align-items-center justify-content-center gap-3">
                <h1 style={{color: '#001d6e'}}><span className='fw-bold'>VCIS</span> In a Nutshell</h1>
                <div className='d-flex flex-column gap-3'>
                    <p style={{lineHeight: '2rem'}}>VCIS harnesses the power of AI and machine learning to provide invaluable location intelligence. With a strong focus on Digital Footprintings, investigation, and risk management. VCIS promotes a safer environment by assissting private enterprises, and governemental entities.</p>
                    <p style={{lineHeight: '2rem'}}>
                    The comprehensive suite of tools offered by VCIS encompasses functionalities such as knowledge graphs, map explorers, link analysises, case management, and advanced digital investigation features in addition to AI threat analysis module.
                    </p>
                </div>
            </div> 
            <div className="col-md-6 rotate_keyframe">
                <img src={NutshellImg} className='w-100' alt="" />
            </div>
        </div>
    </div>
    </>
  )
}

export default Nutshell
