import React, { useEffect, useState, useRef, useMemo } from "react";
import counter1 from '../../../assets/img/counter1.png';
import counter2 from '../../../assets/img/counter2.png';
import counter3 from '../../../assets/img/counter3.png';
import counter4 from '../../../assets/img/counter4.png';
import './Counter.css'

const Counter = () => {
  const counters = useMemo(
    () => [
      {
        image: counter1,
        number: 30,
        description: 'Leading the market'
      },
      {
        image: counter2,
        number: 500,
        description: 'Members worldwide'
      },
      {
        image: counter3,
        number: 100,
        description: 'Satisfied clients'
      },
      {
        image: counter4,
        number: 25,
        description: 'Lines of Business'
      }
    ],
    []
  );

  const duration = 2000;
  const [counts, setCounts] = useState(Array(counters.length).fill(0));
  const ref = useRef();

  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        const startTime = Date.now();
        const interval = setInterval(() => {
          const elapsed = Date.now() - startTime;
          if (elapsed >= duration) {
            clearInterval(interval);
            setCounts(counters.map(({ number }) => number));
          } else {
            const newCounts = counters.map(({ number }) => Math.min(Math.floor(number * elapsed / duration), number));
            setCounts(newCounts);
          }
        }, 50);
        
        return () => clearInterval(interval);
      }
    });

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [counters]);

  return (
    <div ref={ref}>
      <div className="counter_title">
        <div className="px-4">
          <div className="row d-flex flex-row mb-3">
            <h2 className="font--poppins" style={{fontSize: '3.5rem'}}>Why choose <span className='mb-5' style={{fontSize: '3.5rem'}}>Valoores?</span></h2>
            <div className='inner_container' >
              {counters.map((slide, index) => (
                <div className="counter_content d-flex w-100" key={index}>
                  <img className='icon-img' src={slide.image} alt="" />
                  <div className='d-flex flex-row align-items-center'>
                    <p className='number'>{counts[index]}</p>
                    <span>+</span>
                    <p className="year">{slide.year}</p>
                  </div>
                  <div className='counter-desc'>
                    <p>{slide.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Counter;
