import Carousel1 from '../../img/gov/imgs/carousel1.jpg';
import Carousel2 from '../../img/gov/imgs/carousel2.jpg';
import Carousel3 from '../../img/gov/imgs/carousel3.jpg';
import Carousel4 from '../../img/gov/imgs/carousel4.jpg';
import Carousel5 from '../../img/gov/imgs/carousel5.jpg';

const slides = [
  {
    image: Carousel1,
    title: "VALOORES in'Governance",
    description: "“An integrated, unified, and data-driven approach connecting governments, citizens, and investors”",
    position: '25%',
    link: 'https://analytics.valoores.com/',
    button: 'yes',
    document: 'no'
  },
  {
    image: Carousel2,
    title: '',
    description: (
      <>
        “There is no Gain without Pain,
          Based on a Solid Information Integrity”
      </>
    ),
    position: '25%',
    link: '/Geospatial',
    button: 'yes',
    document: 'no'
  },
  {
    image: Carousel3,
    title: "VALOORES in'Governance",
    description: "“Empowering government agencies with information technology capabilities and solutions that have the ability to transform relations with citizens, businesses, and other arms of government”",
    position: '25%',
    link: 'https://indatacrowd.valoores.com/',
    button: 'yes',
    document: 'no'
  },
  {
    image: Carousel4,
    title: '',
    description: '30 Years of Success, deja, covering four continents. We deliver on: Time, on Scope, on Budget, on , on Continuity!',
    position: '25%',
    link: 'https://retail.valoores.com/',
    button: 'yes',
    document: 'no'
  },
  {
    image: Carousel5,
    title: "VALOORES in'Governance",
    description: '“Utilizing collaborative technology whose mission is to improve government transparency & efficiency”',position: '25%',
    link: 'https://retail.valoores.com/',
    button: 'yes',
    document: 'no'
  },
  {
    image: Carousel5,
    title: "",
    description: "We can't solve problems by using the same kind of thinking we used when we created them. ~ Albert ",position: '25%',
    link: 'https://retail.valoores.com/',
    button: 'yes',
    document: 'no'
  },
];





  export default slides;