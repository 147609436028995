import Vtech1 from '../../../assets/img/Vtech1.png'
import Vtech2 from '../../../assets/img/Vtech2.png'
import Vtech3 from '../../../assets/img/Vtech3.png'

const sectionsArray = [
    {
        img: Vtech1,
        title: 'Analytics',
        desc: 'Our consulting services in Financial Crime Detection (FDS) deliver robust, scalable frameworks that encompass KYC, Transaction Monitoring, Fraud Risk Management, and Regulatory Reporting.',
        link: '',
    },
    {
        img: Vtech2,
        title: 'BIG DATA',
        desc: 'Our consultants work closely with organizations to transform complex data landscapes into clear, actionable strategies that drive operational excellence.',
        link: '',
    },
    {
        img: Vtech3,
        title: 'Machine Learning',
        desc: 'Our consultants leverage over three decades of retail expertise to address key challenges within siloed operations, supplier negotiations, and competitive dynamics.',
        link: '',
    },
    {
        img: Vtech1,
        title: 'Analytics',
        desc: 'Our consulting services in Financial Crime Detection (FDS) deliver robust, scalable frameworks that encompass KYC, Transaction Monitoring, Fraud Risk Management, and Regulatory Reporting.',
        link: '',
    },
    {
        img: Vtech2,
        title: 'BIG DATA',
        desc: 'Our consultants work closely with organizations to transform complex data landscapes into clear, actionable strategies that drive operational excellence.',
        link: '',
    },
    {
        img: Vtech3,
        title: 'Machine Learning',
        desc: 'Our consultants leverage over three decades of retail expertise to address key challenges within siloed operations, supplier negotiations, and competitive dynamics.',
        link: '',
    },
    {
        img: Vtech1,
        title: 'Analytics',
        desc: 'Our consulting services in Financial Crime Detection (FDS) deliver robust, scalable frameworks that encompass KYC, Transaction Monitoring, Fraud Risk Management, and Regulatory Reporting.',
        link: '',
    },
    {
        img: Vtech2,
        title: 'BIG DATA',
        desc: 'Our consultants work closely with organizations to transform complex data landscapes into clear, actionable strategies that drive operational excellence.',
        link: '',
    },
    {
        img: Vtech3,
        title: 'Machine Learning',
        desc: 'Our consultants leverage over three decades of retail expertise to address key challenges within siloed operations, supplier negotiations, and competitive dynamics.',
        link: '',
    },
]

export default sectionsArray;