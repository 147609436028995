import React, { useEffect, useState } from 'react';
import './Sections.css';
import SectionArray from './sectionsArray';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Sections = () => {
    const [activeSlide, setActiveSlide] = useState(2);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 200,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ],
        centerMode: false,
        initialSlide: 1,
        beforeChange: (current, next) => setActiveSlide(next + 1),
    };

    const handleSlideClick = (index) => {
        setActiveSlide(index);
    };

    return (
        <div>
            <div style={{ background: '#001f3d' }} className='py-5 industryPage'>
                <div className='container vtech_container'>
                    <Slider {...settings}>
                        {SectionArray.map((section, index) => (
                            <div
                                key={index}
                                className={`section-slide active ${
                                    windowWidth >= 1025 ? 
                                    (index === activeSlide ? 'active' : 'deactivate') : ''
                                }`}
                                onClick={() => handleSlideClick(index)}
                            >
                                <div
                                    style={{
                                        backgroundImage: `url(${section.img})`,
                                        height: '15rem',
                                        width: '100%',
                                        backgroundPosition: 'center center',
                                        backgroundSize: 'cover',
                                        borderRadius: '10px 10px 0 0'
                                    }}
                                />
                                <div className="p-3">
                                    <h5>{section.title}</h5>
                                    <h6>{section.desc}</h6>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Sections;
