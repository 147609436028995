const AmlCaseList =
[
  {
  exploration :
    [
      'SAR/STR Clustering- generate clusters based on attributes within STR/SAR filings to determine common attribute and unexpected relationships',
      'Peer Group Clustering- Cluster large volumes of businesses based on peer groups to identify anomalies to expected behavior. For example, find a business which is supposed to be a gas station which is clustering closer to a MSB.',
      'Negative News Analysis- Apply NLP and sentiment analysis to AML negative news feeds to identify common AML trends and risks as the emerge.'
    ]
  },
  {
    processing :
      [
        'Process negative news- Archive negative news feeds from common AML data vendors and process this data looking for names, business names, keywords, trends, and sentiment. Let the machine take the first pass at identifying if a negative news article is truly aligned to your customer. Look for trends in negative news; for example, see that there is an increase in wire fraud out of Eastern Asia among your customers.',
        'Process Case File Narratives- Extract intelligence and trends from the narratives that your AML analyst team is generating. Find common AML risks that your AML analysts are reporting.',
        'Extract intelligence from SARs/STRs- Extract intelligence and trends from the SARs that your team is filling out. Are there common themes among certain types of customers and specific AML risks?'
      ]
    },
    {
      management :
      [
        "Aggregate investigation data- Rather than logging into a dozen different applications outside of the case management tool hunting down the transaction, alert, and customer data that an analyst needs for an investigation, next generation systems will present all of the relevant data for a case in a single interface for the analyst. This aggregation is possible due to the Big Data environment's strength in handling Variety in data formats. Using traditional relational databases, it can be very expensive and difficult to aggregate data because it requires transforming the data into a singular canonical schema. This can be near impossible when the source data systems are drastically different such as in the case of Wire Transfer transaction data vs. checking account transactions. This problem is more easily address in the Big Data environment as you are not required to execute these transformations ahead of time.",
        "Link/Network Analysis- Another value proposition of Big Data Case Management tools is the capability of leveraging Big Data graph capabilities for Link/Network analysis. Many tools exist in the Big Data ecosystem which can enable mapping of nth degree network connections and providing an interface to the analysts which allows them to shuffle through these network graphs to find risky connections.",
        "Entity Resolution / Single View of the Customer- One of the most persistent challenges faced by AML programs is drilling down to a single view of their customers. The complexity of institutions who have retail, wealth management, capital market, and other siloed lines of business make it very difficult for AML programs to understand the comprehensive relationship of a single customer with the institution. This is of course even more difficult for institutions with a global footprint. Beyond just bringing the data together, banks must have a way of merging customers together who are probably the same individual. Can you imagine how many “John Smiths” there may be at a financial institution with tens/hundreds of millions of customers? The Big Data environment provides an ecosystem and analytic technologies which allow data scientists to construct more advanced matching algorithms. Using a larger set of matching attributes allows us to snap together to similar entities with greater confidence. By incorporating these matching algorithms into a case management tool, institutions can merge probable matches and provide an interface in which analysts can review the probably matches and affirm or deny the match."
      ]
    }
  ];
    
  export default AmlCaseList;
  