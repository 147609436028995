import React from 'react'
import ServiceArray from './servicesArray'
import { Link } from 'react-router-dom'

const Services = () => {
  return (
    <div style={{background: '#ebebeb'}}>
      <div className="container py-5 text-center">
        <h1 className='mb-5' style={{color: '#001d6e'}}>VCIS Services</h1>
        <div className="d-flex flex-row align-items-center flex-wrap justify-content-between gap-3 pointer">
            {ServiceArray.map((service, index) => (
              <Link key={index} to={service.link} className='w-30'>
                <div className='service__img d-flex flex-row align-items-center justify-content-center' style={{backgroundImage: `url("${service.img}")`}}>
                    <div className='d-flex align-items-center' >
                        <div className='overlay__bg'></div>
                        <div className='service__content d-flex align-items-center justify-content-center flex-column text-white px-5 text-center w-100'>
                            <h4 className='fw-bold '>{service.title}</h4>
                            <p className='text-white'></p>
                        </div>
                    </div>
                </div>
            </Link>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Services
