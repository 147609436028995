
const InFunds = [
    {
      title: "Overview",
      desc:
      (
        <>
            In'Funds Transfer Pricing builds on VALOORES history as the industry’s first matched maturity funds transfer pricing application, enabling financial institutions to determine the spread earned on assets and liabilities, and the spread earned as a result of interest rate exposure for each and every customer relationship. This enables accurate assessment of profitability along product, channel, and business lines, as well as the centralization of interest rate risk so it can be effectively managed.

       
            </>
      ),
      list: [''],
    },
    {
        title: "benefits",
        desc: ' ',
        list: [
          "Set transfer rates for a wide range of financial instruments",
          "Actively incorporate risk into decision making",
          "Flexibly assign transfer rates to individual customer relationships",
          "Develop a deeper understanding of option and liquidity costs",
          "Identify break events and compute economic loss",
          "Price loan commitments using forward rates and calculate rate lock option costs",
          "Easy-to-use interfaces and business logic requires minimal maintenance",
          "Publish web based daily FTP rate cards for standard products.",
          
        ],
      },
      {
        title: "features",
        desc:'',
        list:
        ([
            "Industry leading Transfer Pricing Methods support base transfer rates",
            "TP methods available for ALM Forecasting and Budgeting",
            "Option, Liquidity, and Breakage Charges",
            "Visibility into the components of net interest margin",
            "Shared data model and platform",
            "Enterprise class scalability",
            "Integration of Risk, Performance Management, Customer Insight, and Financial Compliance",
            
        ]),
    }
  ];
  
  export default InFunds;
  